import { useCallback, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector, useQuery } from '../../app/hooks'
import { PageHeader, Datatable, DataTableTotal, blockColumns } from '../../common'
import { getBlockList, resetBlockList } from './blocksSlice'
import { ButtonSubmit, InputStyled, PositionStyled, WrapRelativeStyled } from './blockListPageStyles'
import { Form } from 'antd'
import { useHistory } from 'react-router-dom'

export default function BlockListPage() {
  const dispatch = useAppDispatch()
  const query = useQuery()
  const getAuthor = query.get('author')?.trim() || ''
  const [author, setAuthor] = useState(getAuthor)
  const history = useHistory()

  const getData = useCallback(
    (params: any) => getBlockList({ ...params, author }),
    [author]
  )


  const { data, loading, total } = useAppSelector((state) => state['blocks']['list'])
  const onFinish = async (values: any) => {
    setAuthor(values.author)
    if (values.author) {
      history.push({ search: `?author=${values.author}` })
    } else {
      history.push({ search: '' })
    }
  }

  // reset state when component unmount
  useEffect(() => {
    return () => {
      dispatch(resetBlockList())
    }
  }, [])

  return (
    <div>
      <PageHeader title="Blocks" />
      <WrapRelativeStyled>
        <PositionStyled>
          <Form layout="vertical" style={{ display: "flex" }} onFinish={onFinish} autoComplete="off">
            <Form.Item
              name="author"
            >
              <InputStyled
                allowClear={true}
                placeholder="Filter Masternode Address"
              />
            </Form.Item>
            <Form.Item>
              <ButtonSubmit
                type="submit"
                value="Search"
              />
            </Form.Item>
          </Form>
        </PositionStyled>
        <Datatable
          loading={loading}
          total={total}
          columns={blockColumns}
          dataSource={data}
          header={<DataTableTotal total={total} type="block" />}
          getData={getData}
        />
      </WrapRelativeStyled>
    </div>
  )
}