import { RefObject, useEffect, useRef } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import type { RootState, AppDispatch } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useStateDetails = (getDetails: Function, param: string | undefined, currState: 'transactions' | 'blocks' | 'epochs' | 'tokens' | 'contracts') => {
  const dispatch = useAppDispatch()
  const details: any = useAppSelector((state) => state[currState]['details'])

  useEffect(() => {
    if (param) dispatch(getDetails(param))
  }, [param, dispatch])

  return details
}

export function useOnClickOutside<T extends HTMLElement>(
  node: RefObject<T | undefined>,
  handler: undefined | (() => void)
) {
  const handlerRef = useRef<undefined | (() => void)>(handler)
  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (node.current?.contains(e.target as Node) ?? false) {
        return
      }
      if (handlerRef.current) handlerRef.current()
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [node])
}

export function useQuery() {
  return new URLSearchParams(useLocation().search)
}
