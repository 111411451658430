import { Button } from 'antd';
import styled from 'styled-components'
export const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.bg2};
  padding: 25px 0 0;
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    // background-image: url(${({ theme }) => theme.bgImageFooter});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 32px 32px 0px 0px;
    z-index: -1;
  }
  @media only screen and (max-width: 767px) {
    .sp-justify {
      justify-content: center;
    }
  }
`

export const Content = styled.div`
  max-width: 1232px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
  .logo-size {
    height: 28px;
  }
  .list-social {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    img {
      width: 24px;
      filter: grayscale(0%);
      transition: all 0.3s ease;
      opacity: 1;
      &:hover {
        filter: grayscale(100%);
        opacity: 0.6;
      }
    }
    .ant-typography {
      color: ${({ theme }) => theme.textOverlay};
    }
    @media(max-width: 580px){
      .social-label{
        display: none;
      }
    }
  }
`

export const ListMenuFooter = styled.div`
  .ant-descriptions {
    @media only screen and (min-width: 768px) {
      display: flex;
      align-items: flex-start;
      .ant-descriptions-header {
        padding-right: 40px;
      }
    }
    .ant-descriptions-title {
      font-weight: bold;
      font-size: 14px;
      color: ${({ theme }) => theme.text};
      position: relative;
      padding-left: 10px;
      
    }
  }
  a {
    color: ${({ theme }) => theme.text};
    font-size: 14px;
    &:hover {
      color: ${({ theme }) => theme.textOverlay};
    }
  }
`

export const EndFooter = styled.div`
  background-color: ${({ theme }) => theme.bg3};
  padding: 5px 15px;
  text-align: center;
  font-size: 10px;
  color: #C3C3C3;
  span {

  }
`
export const ButtonStyled = styled(Button)`
    border-radius: 0;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 10px;
    border: 1px solid ${({ theme }) => theme.border};
    background-color: ${({ theme }) => theme.bg2};
    :hover,:focus{
        background-color: ${({ theme }) => theme.bg2};
        border-color: ${({ theme }) => theme.border};
    }
    @media(max-width: 767px){
      display: none;
    }
`
export const TextMain = styled.span`
    color: ${({ theme }) => theme.text};
    margin-left: 5px;
    font-size: 12px;
`