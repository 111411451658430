import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import { EpochParamsType, RewardType } from '../../constants/types'
import { Datatable, DataTableTotal, epochRewardColumns, SkeletonLoading } from '../../common'
import { getEpochDetailsReward } from './epochsSlice'
import { timeFormatDistance } from '../../utils'



interface EpochDetailsRewardProps {
  type: RewardType
}

export default function EpochDetailsReward({ type }: EpochDetailsRewardProps) {
  const { epochNumber } = useParams<EpochParamsType>()
  const { reward } = useAppSelector(state => state.epochs.details)

  const getData = useCallback(
    (params: any) => getEpochDetailsReward({ ...params, epochNumber, rewardType: type }),
    []
  )

  if (!epochNumber) return <SkeletonLoading />
  const newData = reward[type].data.map((v) => {
    const age = timeFormatDistance(v.timestamp)
    return { ...v, age }
  })
  return (
    <Datatable
      loading={reward[type].loading}
      total={reward[type].total}
      columns={epochRewardColumns}
      dataSource={newData}
      header={<DataTableTotal total={reward[type].total} type="epoch-reward" />}
      getData={getData}
    />
  )
}