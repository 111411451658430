import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getAccountList, resetAccountList } from './accountsSlice'
import { PageHeader, Datatable, accountColumns, DataTableTotal } from '../../common'

export default function EpochsList() {
  const dispatch = useAppDispatch()
  const { data, loading, total } = useAppSelector((state) => state['accounts']['list'])
  // reset state when component unmount
  useEffect(() => {
    return () => {
      dispatch(resetAccountList())
    }
  }, [])

  return (
    <div>
      <PageHeader title="All Accounts" />

      <Datatable
        loading={loading}
        total={total}
        columns={accountColumns}
        dataSource={data}
        header={<DataTableTotal total={total} type="account" />}
        getData={getAccountList}
      />
    </div>
  )
}