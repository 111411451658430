import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Menu, Dropdown } from 'antd';
import { useSelector } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';
import LogoTomoscan from '../../assets/images/tomoscan-logo-dark.svg';
import LogoTomoscanLight from '../../assets/images/tomoscan-logo-light.svg';

import {
  MenuOutlined
} from '@ant-design/icons';

import { RootState } from '../../app/store';
// import { changeMode } from '../../features/general/generalSlice';

import {
  Wrapper,
  ContentOnPC,
  NavBar,
  MenuStyled,
  ContentOnSP,
  MenuMobile,
} from './headerStyles';

const { SubMenu } = Menu;

const menu = (
  <MenuMobile
    triggerSubMenuAction="click"
  >
    <Menu.Item key="home">
      <Link to="/">Home</Link>
    </Menu.Item>
    <Menu.Item key="txs">
      <Link to="/txs">Transactions</Link>
    </Menu.Item>
    <SubMenu
      key="sub1"
      title="Blocks"
    >
      <Menu.Item key="blocks"><Link to="/blocks">Blocks</Link></Menu.Item>
      <Menu.Item key="epochs"><Link to="/epochs">Epochs</Link></Menu.Item>
    </SubMenu>
    <SubMenu
      key="sub2"
      title="Accounts"
    >
      <Menu.Item key="accounts"><Link to="/accounts">All Accounts</Link></Menu.Item>
      <Menu.Item key="masternodes"><Link to="/masternodes">All Masternodes</Link></Menu.Item>
      <Menu.Item key="contracts"><Link to="/contracts">Verified Contracts</Link></Menu.Item>
    </SubMenu>
    <SubMenu
      key="sub3"
      title="Tokens"
    >
      <Menu.Item key="vrcTokens"><Link to="/tokens">Tokens</Link></Menu.Item>
      <Menu.Item key="vrc721"><Link to="/tokens/nft">NFTs</Link></Menu.Item>
    </SubMenu>
  </MenuMobile>
);

export default function Header() {
  const [current, setCurrent] = useState('')

  const handleClick = (e: any) => {
    setCurrent(e.key);
  };

  const mode = useSelector((state: RootState) => state.general.mode)
  // const dispatch = useDispatch()

  return (
    <Wrapper>
      <ContentOnPC>
        <Row>
          <Col>
            <Link to="/">
              <img
                className="logo-size"
                src={(mode === "dark" ? LogoTomoscanLight : LogoTomoscan)} alt="LOGO VICSCAN"
              />
            </Link>
          </Col>

          <Col className="ml-auto">
            <NavBar>
              <MenuStyled
                onClick={handleClick}
                selectedKeys={[current]}
                mode="horizontal"
                subMenuOpenDelay={0}
              >
                <Menu.Item key="home">
                  <Link to="/">Home</Link>
                </Menu.Item>
                <Menu.Item key="txs">
                  <Link to="/txs">Transactions</Link>
                </Menu.Item>
                <SubMenu
                  key="sub1"
                  title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>Blocks</span>
                      <CaretDownOutlined style={{marginLeft: '5px', marginBottom: '-2px'}} />
                    </div>
                  }
                >
                  <Menu.Item key="blocks"><Link to="/blocks">Blocks</Link></Menu.Item>
                  <Menu.Item key="epochs"><Link to="/epochs">Epochs</Link></Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sub2"
                  title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>Accounts</span>
                      <CaretDownOutlined style={{marginLeft: '5px', marginBottom: '-2px'}} />
                    </div>
                  }
                >
                  <Menu.Item key="accounts"><Link to="/accounts">All Accounts</Link></Menu.Item>
                  <Menu.Item key="masternodes"><Link to="/masternodes">All Masternodes</Link></Menu.Item>
                  <Menu.Item key="contracts"><Link to="/contracts">Verified Contracts</Link></Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sub3"
                  title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>Tokens</span>
                      <CaretDownOutlined style={{marginLeft: '5px', marginBottom: '-2px'}} />
                    </div>
                  }
                >
                  <Menu.Item key="vrcTokens"><Link to="/tokens">Tokens</Link></Menu.Item>
                  <Menu.Item key="vrc721"><Link to="/tokens/nft">NFTs</Link></Menu.Item>
                </SubMenu>
              </MenuStyled>

              {/* <Button className={`btn-theme ${mode === "dark" ? "light" : "dark"}`} onClick={() => dispatch(changeMode(mode === "dark" ? "light" : "dark"))}>
                <img src={(mode === "dark" ? icLight : icDark)} alt="Light/Dark" />
              </Button> */}
            </NavBar>
          </Col>
        </Row>
      </ContentOnPC>

      <ContentOnSP>
        <div>
          <Row gutter={16} justify="space-between">
            <Col>
              <Link to="/">
                <img
                  className="logo-size"
                  src={(mode === "dark" ? LogoTomoscanLight : LogoTomoscan)} alt="LOGO VICSCAN"
                />
              </Link>
            </Col>
            <Col>
              <div className="btn-group">
                {/* <Button className={`btn-theme ${mode === "dark" ? "light" : "dark"}`} onClick={() => dispatch(changeMode(mode === "dark" ? "light" : "dark"))}>
                  <img src={(mode === "dark" ? icLight : icDark)} alt="Light/Dark" />
                </Button> */}

                <Dropdown overlay={menu}>
                  <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <MenuOutlined className="ml-1" />
                  </div>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
      </ContentOnSP>
    </Wrapper>
  )
}