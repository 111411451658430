import { useState } from 'react'
import styled from 'styled-components'
import { CopyrightOutlined } from "@ant-design/icons"
import tomoLogo from '../assets/images/token-tomo.png'

const TOKENS_REPO_URL = 'https://raw.githubusercontent.com/BuildOnViction/tokens/master/tokens'

type TokenIconType = {
  address?: string | undefined
  size?: number | undefined
}

function TokenIcon({ address, size }: TokenIconType) {
  const [error, setError] = useState(false)

  if (!address || error) return <LogoStyle><CopyrightOutlined style={{ fontSize: size || "20px", marginRight: "5px" }} /></LogoStyle>
  if (address === 'tomo') return (
    <img
      src={tomoLogo}
      width={size || '20px'}
      height={size || '20px'}
      style={{marginRight: '5px'}}
      alt=""
    />
  )
  return (
    <img
      src={`${TOKENS_REPO_URL}/${address.toLowerCase()}.png`}
      width={size || '20px'}
      height={size || '20px'}
      style={{marginRight: '5px'}}
      alt=""
      onError={() => setError(true)}
    />
  )
}

const LogoStyle = styled.span`
  color: ${({theme})=>theme.text};
`

export default TokenIcon