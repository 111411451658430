import React, { useMemo } from 'react'


import {Tooltip } from 'antd'

import isPer from '../../src/assets/images/status/verifiedandproject.svg';
import isContract from '../../src/assets/images/status/verified.svg';
import icNothing from '../../src/assets/images/status/warning.svg';
import isProject from '../../src/assets/images/status/verifiedLine.svg';


const CheckStatus = ({tokenStatus, contractVerified, isOnlyIcon}: {tokenStatus: any, contractVerified?: boolean, isOnlyIcon?: boolean}) => {

    const isContractVerified = !tokenStatus?.projectVerified && (tokenStatus?.contractVerified || contractVerified )
    const isProjectVerified = tokenStatus?.projectVerified  && !tokenStatus?.contractVerified
    const isFull = tokenStatus?.projectVerified && tokenStatus?.contractVerified  
    const isNothing = !tokenStatus?.projectVerified && !tokenStatus?.contractVerified  

  const { checkText, checkImg } = useMemo(() => {
    if(isFull){
      return {
        checkText: 'Contract Verified & Project Confirmed',
        checkImg: isPer
      };
    }

    if(isContractVerified ){
      return {
        checkText: 'Contract Verified',
        checkImg: isContract
      };
    }
    if(isProjectVerified ){
      return {
        checkText: 'Project Confirmed',
        checkImg: isProject
      };
    }
    return {
      checkText: 'Contract Not Yet Verified',
      checkImg: icNothing
    };

  }, [isFull, isContractVerified, isProjectVerified, contractVerified])

    return (
      <div style={{display: isOnlyIcon ? 'inline-block': 'inline-flex'}} className='align-items-center'>
        <Tooltip title={isOnlyIcon && <span style={{whiteSpace: 'nowrap', fontSize: '12px'}}>{checkText}</span>} >
          <img src={checkImg} alt='icon'/>
        </Tooltip>
        {!isOnlyIcon && <>
          &nbsp;
          <span style={{color: (isNothing ? '#B47D00': '')}}>
            {checkText}
          </span>
        </>}
      </div>
    )
}

export default CheckStatus