import { Row, Col } from 'antd'

import {
  BoxContentPlaceHolder,
  TitleHeaderBox,
  InnerPlaceHolder,
  TxtColStyle,
} from './tokenDetailsPageStyles'

import { formatNumber } from '../../utils'

export default function Vrc2021Overview({ data }: { data: any }) {
  return (
    <BoxContentPlaceHolder>
      <TitleHeaderBox>Market Overview</TitleHeaderBox>
      <InnerPlaceHolder>
        {!!data.price &&
          <Row gutter={24}>
            <Col span={24} md={6} lg={8}>
              <TxtColStyle className="col-txt-left">
                Price
              </TxtColStyle>
            </Col>
            <Col span={24} md={18} lg={16}>
              <TxtColStyle className="col-txt-right">
                ${formatNumber(data.price)}
              </TxtColStyle>
            </Col>
          </Row>
        }

        {!!data.marketcap &&
          <Row gutter={24}>
            <Col span={24} md={6} lg={8}>
              <TxtColStyle className="col-txt-left">
                Market Cap
              </TxtColStyle>
            </Col>
            <Col span={24} md={18} lg={16}>
              <TxtColStyle className="col-txt-right">
                ${formatNumber(data.marketcap, 2)}
              </TxtColStyle>
            </Col>
          </Row>
        }

        {!!data.fullyDilutedMarketcap &&
          <Row gutter={24}>
            <Col span={24} md={6} lg={8}>
              <TxtColStyle className="col-txt-left">
                Fully Diluted Market Cap
              </TxtColStyle>
            </Col>
            <Col span={24} md={18} lg={16}>
              <TxtColStyle className="col-txt-right">
                ${formatNumber(data.fullyDilutedMarketcap, 2)}
              </TxtColStyle>
            </Col>
          </Row>
        }

        <Row gutter={24}>
          <Col span={24} md={6} lg={8}>
            <TxtColStyle className="col-txt-left">
              Max Total Supply
            </TxtColStyle>
          </Col>
          <Col span={24} md={18} lg={16}>
            <TxtColStyle className="col-txt-right">
              {formatNumber(data.totalSupplyNumber, 2)}
            </TxtColStyle>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={6} lg={8}>
            <TxtColStyle className="col-txt-left">
              Circulating Supply
            </TxtColStyle>
          </Col>
          <Col span={24} md={18} lg={16}>
            <TxtColStyle className="col-txt-right">
              {formatNumber(data.circulatingSupplyNumber, 2)}
            </TxtColStyle>
          </Col>
        </Row>
      </InnerPlaceHolder>
    </BoxContentPlaceHolder>
  )
}