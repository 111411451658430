import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { TokenParamsType } from '../../constants/types'
import { columnsRewards, Datatable, DataTableTotal } from '../../common'
import { useAppSelector } from '../../app/hooks'
import { getUserRewards } from './accountsSlice'

export default function UserRewards() {
  const { address } = useParams<TokenParamsType>()

  const getRewardData = useCallback(
    (params: any) => getUserRewards({ ...params, address }),
    [address]
  )

  const { userReward } = useAppSelector((state) => state['accounts']['details'])
  // console.log(userReward.data)
  return (
    
    <Datatable
      loading={userReward.loading}
      total={userReward.total}
      columns={columnsRewards}
      dataSource={userReward.data}
      header={<DataTableTotal total={userReward.total} type="epoch-reward" />}
      getData={getRewardData}
    />
  )
}

