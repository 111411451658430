import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { TxActionType, TransactionsState } from '../../constants/types'
import { fetchTxDetailsByHash, fetchTxInternal, fetchTxList } from '../../api'
import { requestLimit } from '../../features/general/generalSlice'

// Create thunk
export const getTxDetailsByHash = createAsyncThunk(
  'transactions/getTxDetailsByHash',
  async (hash: string, thunkAPI) => {
    try {
      const response = await fetchTxDetailsByHash(hash)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      } 
      return thunkAPI.rejectWithValue(error.response.status)
    }
  }
)

export const getTxList = createAsyncThunk(
  'transactions/getTxList',
  async (params: { offset: number, limit: number }, thunkAPI) => {
    try {
      const { offset, limit } = params

      const response = await fetchTxList(offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }      
      return thunkAPI.rejectWithValue(error.response.status)
    }
  }
)

export const getInternalTx = createAsyncThunk(
  'transactions/getInternalTx',
  async (params: { hash: string, offset: number, limit: number }, thunkAPI) => {
    try {
      const { hash, offset, limit } = params
      const response = await fetchTxInternal(hash, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.status)
    }
  }
)

// Declare slice
const initialState = {
  list: {
    data: [],
    loading: 'idle',
    total: null,
  },
  details: {
    data: null,
    internalTx: {
      loading: 'idle',
      data: [],
      total: 0,
    },
    loading: 'idle',
  }
} as TransactionsState

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    // reset transaction list
    resetTxList(state) {
      state.list.data = []
      state.list.loading = 'idle'
      state.list.total = null
    }
  },
  extraReducers: (builder) => {
    // transaction details
    builder.addCase(getTxDetailsByHash.pending, (state, action) => {
      state.details.loading = 'pending'
      state.details.data = null
    })

    builder.addCase(getTxDetailsByHash.fulfilled, (state, action) => {
      state.details.loading = 'succeeded'

      const actions = action.payload.actions.reduce((acc: any, curr: TxActionType) => {
        if (!acc[curr.type]) {
          acc[curr.type] = [curr]
        } else {
          acc[curr.type] = [...acc[curr.type], curr]
        }

        return acc
      }, {})

      state.details.data = { ...action.payload, actions }
      if (state.details.data && action.payload.status) state.details.data.status = action.payload.status.toLowerCase()
    })

    builder.addCase(getTxDetailsByHash.rejected, (state, action) => {
      state.details.loading = 'failed'
      state.details.data = null
    })

    // transaction list
    builder.addCase(getTxList.pending, (state, action) => {
      state.list.loading = 'pending'
    })

    builder.addCase(getTxList.fulfilled, (state, action) => {
      state.list.loading = 'succeeded'
      state.list.data = action.payload.data
      state.list.total = action.payload.total
    })

    builder.addCase(getTxList.rejected, (state, action) => {
      state.list.loading = 'failed'
      state.list.data = []
      state.list.total = null
    })

    // transaction InternalTx
    builder.addCase(getInternalTx.pending, (state, action) => {
      state.details.internalTx.loading = 'pending'
    })

    builder.addCase(getInternalTx.fulfilled, (state, action) => {
      state.details.internalTx.loading = 'succeeded'
      state.details.internalTx = action.payload
    })

    builder.addCase(getInternalTx.rejected, (state, action) => {
      state.details.internalTx.loading = 'failed'
      state.details.internalTx.data = []
    })
  },
})

export const { resetTxList } = transactionsSlice.actions
export default transactionsSlice.reducer
