import { EpochDetailsType, LoadingType } from '../../constants/types'
import { Datatable, DataTableTotal, slashedColumns } from '../../common'

interface EpochDetailsRewardProps {
  epoch: EpochDetailsType
  loading: LoadingType
}

export default function EpochDetailsSlashedNode({ epoch, loading }: EpochDetailsRewardProps) {
  if (!epoch) return null

  return (
    <Datatable
      loading={loading}
      total={epoch.slashednode.length}
      columns={slashedColumns}
      dataSource={epoch.slashednode}
      showPagination={false}
      header={<DataTableTotal total={epoch.slashednode.length} type="slashed-node" />}
    />
  )
}