import { useEffect, useState } from 'react'
import { Table, Select, Space, Spin } from 'antd'
import { ClockCircleOutlined } from '@ant-design/icons'

import { MAX_RECORD } from '../../constants'
import { PaginationDirType, LoadingType, } from '../../constants/types'
import { useAppDispatch } from '../../app/hooks'
import { formatUtc, timeFormatDistance } from '../../utils'
import { PaginationWrapper, ContentTable, TextLink } from './dataTableStyles'
import DataTableLoading from './DataTableLoading'
import DataTablePagination from './DataTablePagination'

const { Option } = Select

interface DataTableProps {
  loading?: LoadingType
  total?: null | number
  columns: any
  dataSource: any
  header?: any
  showPagination?: boolean
  getData?: Function
}

export default function DataTable({
  columns,
  dataSource,
  total,
  header,
  loading,
  getData,
  showPagination = true,
}: DataTableProps) {
  const dispatch = useAppDispatch()
  const [pagination, setPagination] = useState({ numPerPage: 20, currPage: 1 })
  const [utc, setUtc] = useState(false)

  const maxRecord = total && (total < MAX_RECORD) ? total : MAX_RECORD
  const pages = total && pagination ? Math.ceil(maxRecord / pagination.numPerPage) : 0
  const offset = pagination.numPerPage * (pagination.currPage - 1)

  function changePage(dir: PaginationDirType) {
    if (!pages) return
    let currPage = pagination.currPage

    switch (true) {
      case dir === 'first':
        currPage = 1
        break
      case dir === 'last':
        currPage = pages
        break
      case dir === 'next':
        if ((pagination.currPage + 1) <= pages) {
          currPage = pagination.currPage + 1
        }
        break
      case dir === 'prev':
        if ((pagination.currPage - 1) >= 1) {
          currPage = pagination.currPage - 1
        }
        break
    }

    if (currPage !== pagination.currPage) setPagination({ ...pagination, currPage })
  }

  function changeNumPerPage(value: string) {
    let numPerPage = Number(value)

    if (numPerPage !== pagination.numPerPage) setPagination({ currPage: 1, numPerPage })
  }

  useEffect(() => {
    if (getData) dispatch(getData({ offset, limit: pagination.numPerPage }))
  }, [pagination, getData])
  dataSource = dataSource.map((item: any, index: number) => {
    const rank = index + offset + 1

    return { ...item, rank, key: index }
  })

  const parsedColumns = columns && Array.isArray(columns) && columns.map((col) => {
    if (col.dataIndex === 'timestamp') {
      col.title = (
        <>
          {utc ?
            (<TextLink>Time (UTC)</TextLink>)
            : (<TextLink>Time <ClockCircleOutlined /></TextLink>)}
        </>)
      col.render = (time:any) => utc ? formatUtc(time, "MM-dd-yyyy HH:mm:ss") : timeFormatDistance(time)
      col.onHeaderCell = () => {
        return {
          onClick:() => {
            setUtc(!utc)
          }
        }
      }
    }
    return col
  })

  return (
    <ContentTable className="content-table">
      {!!total &&
        <PaginationWrapper>
          <Space>
            {(loading === 'pending' && dataSource.length > 0) && <Spin />}
            {header}
          </Space>
          <div className="d-ms-down-none">
            {showPagination && <DataTablePagination changePage={changePage} pages={pages} pagination={pagination} />}
          </div>
        </PaginationWrapper>
      }

      {(loading === 'pending' && dataSource.length === 0) &&
        <DataTableLoading />
      }

      {(loading !== 'pending' && dataSource.length === 0) &&
        <Table
          className="style-table-bordered"
          columns={parsedColumns}
          dataSource={[]}
          pagination={false}
          size="middle"
          scroll={{ x: parsedColumns.length > 5 ? 1100 : 600 }}
        />
      }

      {dataSource.length > 0 &&
        <Table
          className="style-table-bordered"
          columns={parsedColumns}
          dataSource={dataSource}
          pagination={false}
          size="middle"
          scroll={{ x: parsedColumns.length > 5 ? 1100 : 600 }}
        />
      }

      {!!total && showPagination &&
        <PaginationWrapper>
          <div className="d-ms-down-none">
            {(pages > 1) &&
              <Space>
                Show
                <Select defaultValue={pagination.numPerPage.toString()} onChange={changeNumPerPage}>
                  <Option value="20">20</Option>
                  <Option value="50">50</Option>
                  <Option value="100">100</Option>
                </Select>
                per page
              </Space>
            }
          </div>

          <DataTablePagination changePage={changePage} pages={pages} pagination={pagination} />
        </PaginationWrapper>
      }
    </ContentTable>
  )
}

