import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  fetchAccountDetails,
  fetchAccountList,
  fetchInternalTx,
  fetchStats,
  fetchTokenTransactions,
  fetchTokensInAccount,
  fetchUserRewards,
  fetchVrcTokenTransactions,
} from '../../api'
import { AccountsState } from '../../constants/types'
import { requestLimit } from '../../features/general/generalSlice'

import BigNumber from 'bignumber.js'

// Create thunk
export const getAccountList = createAsyncThunk(
  'accounts/getAccountList',
  async (params: { offset: number, limit: number }, thunkAPI) => {
    try {
      const { offset, limit } = params

      const response = await fetchAccountList(offset, limit)
      const stastsResponse = await fetchStats()
      thunkAPI.dispatch(requestLimit(false))
      return { ...response.data, stats: { ...stastsResponse.data } }
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      } 
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getAccountDetails = createAsyncThunk(
  'accounts/getAccountDetails',
  async (address: string, thunkAPI) => {
    try {
      const response = await fetchAccountDetails(address)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error:any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getTokensInAccount = createAsyncThunk(
  'accounts/getTokensInAccount',
  async (params: { address: string, offset: number, limit: number }, thunkAPI) => {
    try {
      const { address, offset, limit } = params
      const response = await fetchTokensInAccount(address, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error:any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getTokensInAccountForDropdown = createAsyncThunk(
  'accounts/getTokensInAccountForDropdown',
  async (params: { address: string, offset: number, limit: number }, thunkAPI) => {
    try {
      const { address, offset, limit } = params
      const response = await fetchTokensInAccount(address, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error:any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getTokenInternalTx = createAsyncThunk(
  'accounts/getTokenInternalTx',
  async (params: { address: string, offset: number, limit: number }, thunkAPI) => {
    try {
      const { address, offset, limit } = params
      const response = await fetchInternalTx(address, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getUserRewards = createAsyncThunk(
  'accounts/getUserRewards',
  async (params: { address: string, offset: number, limit: number }, thunkAPI) => {
    try {
      const { address, offset, limit } = params
      const response = await fetchUserRewards(address, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getTokenTransactions = createAsyncThunk(
  'accounts/getTokenTransactions',
  async (params: { address: string, offset: number, limit: number }, thunkAPI) => {
    try {
      const { address, offset, limit } = params
      const response = await fetchTokenTransactions(address, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return { ...response.data, address }
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getVrcTokenTransactions = createAsyncThunk(
  'accounts/getVrcTokenTransactions',
  async (params: { address: string, offset: number, limit: number }, thunkAPI) => {
    try {
      const { address, offset, limit } = params
      const response = await fetchVrcTokenTransactions(address, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return { ...response.data, address }
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// Declare slice
const initialState = {
  list: {
    data: [],
    loading: 'idle',
    total: null,
  },
  details: {
    data: null,
    loading: 'idle',
    tokensInAccount: {
      loading: 'idle',
      data: [],
      total: 0,
      usdValue: 0
    },
    tokensInAccountForDropdown: {
      loading: 'idle',
      data: [],
      total: 0,
      usdValue: 0
    },
    internalTx: {
      loading: 'idle',
      data: [],
      total: 0,
    },
    userReward: {
      loading: 'idle',
      data: [],
      total: 0,
    },
    transactions: {
      loading: 'idle',
      address: '',
      data: [],
      total: 0,
    },
    vrcTokenTxns: {
      loading: 'idle',
      address: '',
      data: [],
      total: 0,
    }
  }
} as AccountsState

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    // reset account list
    resetAccountList(state) {
      state.list.data = []
      state.list.loading = 'idle'
      state.list.total = null
    },
    resetAccountDetail(state) {
      state.details.data = null
      state.details.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    // account list
    builder.addCase(getAccountList.pending, (state) => {
      state.list.loading = 'pending'
    })

    builder.addCase(getAccountList.fulfilled, (state, action) => {
      state.list.loading = 'succeeded'

      const data = action.payload.data.map((item: any, index: number) => {
        const rank = action.meta.arg.offset + index + 1
        const percentage = action.payload.stats.circulatingSupply && new BigNumber(item.balanceNumber).times(100).div(action.payload.stats.circulatingSupply).toNumber()
        return { ...item, rank, percentage }
      })

      state.list.data = data
      state.list.total = action.payload.total
    })

    builder.addCase(getAccountList.rejected, (state) => {
      state.list.loading = 'failed'
      state.list.data = []
      state.list.total = null
    })

    // account detail
    builder.addCase(getAccountDetails.pending, (state) => {
      state.details.loading = 'pending'
    })

    builder.addCase(getAccountDetails.fulfilled, (state, action) => {
      let tomoInUsd = 0
      state.details.loading = 'succeeded'
      if (action.payload.balanceNumber && action.payload.tomoPrice) {
        tomoInUsd = new BigNumber(action.payload.balanceNumber).multipliedBy(action.payload.tomoPrice).toNumber()
      }
      state.details.data = { ...action.payload, tomoInUsd }
    })

    builder.addCase(getAccountDetails.rejected, (state) => {
      state.details.loading = 'failed'
      state.details.data = null
    })

    // Tokens in account
    builder.addCase(getTokensInAccount.pending, (state) => {
      state.details.tokensInAccount.loading = 'pending'
    })

    builder.addCase(getTokensInAccount.fulfilled, (state, action) => {
      state.details.tokensInAccount.loading = 'succeeded'
      state.details.tokensInAccount = action.payload
    })
    
    builder.addCase(getTokensInAccount.rejected, (state) => {
      state.details.tokensInAccount.loading = 'failed'
      state.details.tokensInAccount.data = []
    })

    // Tokens in account for dropdown
    builder.addCase(getTokensInAccountForDropdown.pending, (state) => {
      state.details.tokensInAccountForDropdown.loading = 'pending'
    })

    builder.addCase(getTokensInAccountForDropdown.fulfilled, (state, action) => {
      state.details.tokensInAccountForDropdown.loading = 'succeeded'
      state.details.tokensInAccountForDropdown = action.payload
    })

    builder.addCase(getTokensInAccountForDropdown.rejected, (state) => {
      state.details.tokensInAccountForDropdown.loading = 'failed'
      state.details.tokensInAccountForDropdown.data = []
    })

    // Transactions
    builder.addCase(getTokenTransactions.pending, (state) => {
      state.details.transactions.loading = 'pending'
    })

    builder.addCase(getTokenTransactions.fulfilled, (state, action) => {
      state.details.transactions.loading = 'succeeded'
      let transactionTag
      const newData = action.payload.data.map((item: any) => {
        if (item.from === action.payload.address) {
          transactionTag = 'out'
        } else if (item.to === action.payload.address) {
          transactionTag = 'in'
        } else {
          transactionTag = undefined
        }
        return { ...item, transactionTag }
      })
      state.details.transactions.data = newData
      state.details.transactions.address = action.payload.address
      state.details.transactions.total = action.payload.total
    })

    builder.addCase(getTokenTransactions.rejected, (state) => {
      state.details.transactions.loading = 'failed'
      state.details.transactions.data = []
    })

    // vrc token transactions
    builder.addCase(getVrcTokenTransactions.pending, (state) => {
      state.details.vrcTokenTxns.loading = 'pending'
    })

    builder.addCase(getVrcTokenTransactions.fulfilled, (state, action) => {
      state.details.vrcTokenTxns.loading = 'succeeded'
      let transactionTag
      const newData = action.payload.data.map((item: any) => {
        if (item.from === action.payload.address) {
          transactionTag = 'out'
        } else if (item.to === action.payload.address) {
          transactionTag = 'in'
        } else {
          transactionTag = undefined
        }
        return { ...item, transactionTag }
      })
      state.details.vrcTokenTxns.data = newData
      state.details.vrcTokenTxns.address = action.payload.address
      state.details.vrcTokenTxns.total = action.payload.total
    })

    builder.addCase(getVrcTokenTransactions.rejected, (state) => {
      state.details.vrcTokenTxns.loading = 'failed'
      state.details.vrcTokenTxns.data = []
    })

    // InternalTx
    builder.addCase(getTokenInternalTx.pending, (state) => {
      state.details.internalTx.loading = 'pending'
    })

    builder.addCase(getTokenInternalTx.fulfilled, (state, action) => {
      state.details.internalTx.loading = 'succeeded'
      state.details.internalTx = action.payload
    })

    builder.addCase(getTokenInternalTx.rejected, (state) => {
      state.details.internalTx.loading = 'failed'
      state.details.internalTx.data = []
    })

    // Reward
    builder.addCase(getUserRewards.pending, (state) => {
      state.details.userReward.loading = 'pending'
    })

    builder.addCase(getUserRewards.fulfilled, (state, action) => {
      state.details.userReward.loading = 'succeeded'
      state.details.userReward = action.payload
    })

    builder.addCase(getUserRewards.rejected, (state) => {
      state.details.userReward.loading = 'failed'
      state.details.userReward.data = []
    })
  },
})

export const { resetAccountList, resetAccountDetail } = accountsSlice.actions
export default accountsSlice.reducer
