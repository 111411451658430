import React, { useEffect } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import Countdown from 'react-countdown'
// import Timer from 'react-compound-timer'
import { Typography, Row,Col } from 'antd'

import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { format, addMinutes } from 'date-fns'
import { BlockNumberType } from '../../constants/types'
import { getStats, resetStats } from './generalSlice'
import visualImg from '../../assets/images/visual.svg'
import { Container, BoxInfo, TextMain, TextOverlay, BlockBox} from './countDownPageStyleds'



const { Paragraph } = Typography

function CountDownPage() {

    const {blockNumber} = useParams<BlockNumberType>()
    const dispatch = useAppDispatch()
    const stats = useAppSelector((state) => state.general.stats.data)

    const getData = () => {
        dispatch(getStats())
    }
    useEffect(() => {
        getData()        
        const timerInterval = setInterval(() => {
            getData()
        }, 7000)

        // reset state
        return () => {
        clearInterval(timerInterval)
        dispatch(resetStats())
        }
    }, [])
    let remainBlock = 0
    if (stats && stats.block && blockNumber) {
        const a = new BigNumber(blockNumber)
        remainBlock = a.isGreaterThan(new BigNumber(stats.block)) ? a.minus(new BigNumber(stats.block)).toNumber() : 0
        if(blockNumber < stats.block) return <Redirect to={`/block/${blockNumber}`}/>
    }
    const renderer = ({ days = 0, hours = 0, minutes = 0, seconds = 0, completed = false }) => {
        return (
            <div className="box-countdown">
                <div id="clockdiv" className="inner-countdown">
                    <div className="txt-clock">
                        <TextMain className="days">{days}</TextMain>
                        <TextOverlay className="smalltext">Days</TextOverlay>
                    </div>
                    <div className="cm-after">:</div>
                    <div className="txt-clock">
                        <TextMain className="hours">{hours}</TextMain>
                        <TextOverlay className="smalltext">Hours</TextOverlay>
                    </div>
                    <div className="cm-after">:</div>
                    <div className="txt-clock">
                        <TextMain className="minutes">{minutes}</TextMain>
                        <TextOverlay className="smalltext">Mins</TextOverlay>
                    </div>
                    <div className="cm-after">:</div>
                    <div className="txt-clock">
                        <TextMain className="seconds">{seconds}</TextMain>
                        <TextOverlay className="smalltext">Secs</TextOverlay>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <Container>
            <div className="img-visual"><img src={visualImg} alt="" /></div>
            <BoxInfo className="countdown">
                {stats && stats.block &&
                    <>
                        <Countdown
                            date={Date.now() + remainBlock * 3000}
                            renderer={renderer}
                        />                    
                        <Paragraph style={{paddingTop: "40px", paddingBottom: '30px', fontSize: "18px"}}>
                            <TextOverlay>Estimated Target Date:</TextOverlay>&nbsp;
                            <TextOverlay>{format(addMinutes(Date.now() + remainBlock * 3000, (new Date()).getTimezoneOffset()), "MMMM dd, yyyy HH:mm:ss a '+UTC'")}</TextOverlay>
                        </Paragraph>
                        <Row gutter={24}>
                            <Col span={24} md={12} lg={8}>
                                <BlockBox className="active">
                                    <Paragraph style={{marginBottom: '5px'}}>
                                        <span>Countdown For block:</span>                                        
                                    </Paragraph>
                                    <span>#{ blockNumber}</span>
                                </BlockBox>
                            </Col>
                            <Col span={24} md={12} lg={8}>
                                <BlockBox>
                                    <Paragraph style={{marginBottom: '5px'}}>
                                        <TextMain>Current Block:</TextMain>
                                    </Paragraph>
                                    <TextMain>#{ stats.block}</TextMain>
                                </BlockBox>
                            </Col>
                            <Col span={24} md={12} lg={8}>
                                <BlockBox>
                                    <Paragraph style={{marginBottom: '5px'}}>
                                        <TextMain>Remaining Block:</TextMain>
                                    </Paragraph>
                                    <TextMain>#{ remainBlock}</TextMain>
                                </BlockBox>
                            </Col>
                        </Row>
                    </>
                }
            </BoxInfo>
        </Container>
    )
}

export default CountDownPage
