import styled from "styled-components";

export const MainDetails = styled.div`
  @media(max-width: 767px){
    .rp-mb{
      margin-bottom: 30px;
    }
    .ant-row{
      margin-bottom: 15px;
    }    
  }
`
export const BoxContentPlaceHolder = styled.div`
    background: ${({ theme }) => theme.bg2};
    padding: 15px 24px;
    height: 100%;
    @media(max-width: 767px){
      padding-left: 15px;
      padding-right: 15px;
    }
`
export const InnerPlaceHolder = styled.div`
`
export const TxtColStyle = styled.div`
    margin: 10px 0;
    word-break: break-all;
    &.col-txt-left {
      color:${({ theme }) => theme.textOverlay};
    }
    &.col-txt-right {
      color:${({ theme }) => theme.text};
    }
    @media(max-width: 767px){
      margin: 0;
    }
`
export const TextOverlay = styled.span`
      color: ${({ theme }) => theme.textOverlay};
`
export const BoxContentTable = styled.div`
    margin-top: 25px;
    .style-table-bordered {
      padding: 15px 0 0;
    }
    .content-table {
      padding: 0;
    }
`
export const TitleHeaderBox = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.border};
    padding-bottom: 15px;
    color: ${({ theme }) => theme.text};
`
export const TxtInfoTable = styled.div`
  margin: 5px 0 20px 0px;
`