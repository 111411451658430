import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { CaretDownOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { TokenParamsType } from '../../constants/types'
import { TokenIcon } from '../../common'
import { useAppDispatch, useAppSelector, useOnClickOutside } from '../../app/hooks'
import { formatNumber } from '../../utils'
import {
  getTokensInAccountForDropdown,
  resetAccountDetail
} from './accountsSlice'
import {
  List,
  Tooltip,
} from 'antd'
import {
  ListStyled,
  TokenLink,
  TextOverlay,
  TotalPrice,
  TokenCount,
  TokenList,
  TextMainStyle,
  ListButton,
  ViewMore,
} from './accountDetailsPageStyles'

export default function ListTokenBalance({ showMore }: { showMore: any }) {
  const [isOpen, setIsOpen] = useState(false)

  const node = useRef<HTMLDivElement>()
  const { address } = useParams<TokenParamsType>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (address) {
      dispatch(getTokensInAccountForDropdown({ address, offset: 0, limit: 50 }))
    }
  }, [address, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetAccountDetail())
    }
  }, [])

  const { data, loading, tokensInAccountForDropdown } = useAppSelector((state) => state['accounts']['details'])

  useOnClickOutside(node, () => setIsOpen(false))

  if (!data || (loading === 'pending')) return null
  return (
    <>
      <TokenList
        onClick={() => setIsOpen(!isOpen)}
        ref={node as any}
      >
        <TotalPrice>
          <TextMainStyle>{`$${formatNumber(tokensInAccountForDropdown.usdValue, 2)}`}</TextMainStyle> <TokenCount>{tokensInAccountForDropdown.total}</TokenCount> <CaretDownOutlined />
        </TotalPrice>
        {isOpen &&
          <ListStyled>
            <List
              itemLayout="horizontal"
              header='Tokens VRC'
              dataSource={tokensInAccountForDropdown.data}
              renderItem={(item: any) => (
                <List.Item style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                  <TokenLink to={`/token/${item.token}?account=${data.address}`}>
                    <div>
                      <TokenIcon address={item.token} size={15} />&nbsp;
                      <TextMainStyle>{item.tokenName} ({item.tokenSymbol})</TextMainStyle>
                      <TextOverlay style={{ marginBottom: "0" }}>{formatNumber(item.quantityNumber, 2)} {item.tokenSymbol}</TextOverlay>
                    </div>
                    {item.priceUsd &&
                      <div>
                        <TextMainStyle style={{ marginBottom: "0", textAlign: "right", display: 'block' }}>{formatNumber(item.valueUsd, 2)}</TextMainStyle>
                        <TextOverlay style={{ marginBottom: "0" }}>@{formatNumber(item.priceUsd, 4)}</TextOverlay>
                      </div>
                    }
                  </TokenLink>

                </List.Item>
              )}
            />

            {(tokensInAccountForDropdown.data.length > 50) && <ListButton block onClick={showMore}>Show more</ListButton>}
          </ListStyled>
        }
      </TokenList>
      <ViewMore onClick={showMore}>
        <Tooltip title="View more">
          <ArrowRightOutlined />
        </Tooltip>
      </ViewMore>
    </>
  )
}