import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Tooltip } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import { QRCode } from 'react-qr-svg'

import {
  BoxContentPlaceHolder,
  TitleHeaderBox,
  InnerPlaceHolder,
  TxtColStyle,
  Center,
  AddressBox,
  StyleCopyIcon
} from './tokenDetailsPageStyles'

import iconCopy from '../../assets/images/icon/icon-copy.svg'
import iconQrcode from '../../assets/images/icon/icon-qrcode.svg'
import { formatNumber } from '../../utils'
import CheckStatus from '../../common/CheckStatus'

export default function Vrc2021MoreInfo({ data }: { data: any }) {
  const [isCopied, setCopied] = useState(false)

  return (
    <BoxContentPlaceHolder>
      <TitleHeaderBox>More Information</TitleHeaderBox>
      <InnerPlaceHolder>
        <Row gutter={24}>
          <Col span={24} md={6} lg={5}>
            <TxtColStyle className="col-txt-left">
              Contract
            </TxtColStyle>
          </Col>
          <Col span={24} md={18} lg={19}>
            <TxtColStyle className="col-txt-right">
              <AddressBox>
                <Link className='link-hover' style={{textTransform:'lowercase'}} to={`/address/${data.address}`}>
                  {/* <TextStyled transform="lowercase">{data.address}</TextStyled> */}
                  {data.address}
                  </Link>
                <Tooltip placement="top" title={isCopied ? "Copied" : "Copy address to clipboard"}>
                  <CopyToClipboard
                    text={data.address}
                    onCopy={() => setCopied(true)}
                    //@ts-ignore
                    onMouseEnter={() => setCopied(false)}
                  >
                    <StyleCopyIcon style={{margin: '0 5px 0 10px'}}><span><img width="20" src={(iconCopy)} alt="icon copy" /></span></StyleCopyIcon>
                  </CopyToClipboard>
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  color="#fff"
                  title={
                    <Center style={{ padding: '10px' }}>
                      <QRCode
                        bgColor="#FFFFFF"
                        level="Q"
                        style={{ width: 150 }}
                        value={data.address}
                      />
                    </Center>
                  }
                >
                  <StyleCopyIcon><span><img width="20" src={(iconQrcode)} alt="icon QRCODE" /></span></StyleCopyIcon>
                </Tooltip>
              </AddressBox>
            </TxtColStyle>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={6} lg={5}>
            <TxtColStyle className="col-txt-left">
              Token Status
            </TxtColStyle>
          </Col>
          <Col span={24} md={18} lg={19}>
            <TxtColStyle className="col-txt-right">
              <CheckStatus tokenStatus={data}/>
            </TxtColStyle>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={6} lg={5}>
            <TxtColStyle className="col-txt-left">
              Holders
            </TxtColStyle>
          </Col>
          <Col span={24} md={18} lg={19}>
            <TxtColStyle className="col-txt-right">
              {formatNumber(data.holder)}
            </TxtColStyle>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={6} lg={5}>
            <TxtColStyle className="col-txt-left">
              Transfers
            </TxtColStyle>
          </Col>
          <Col span={24} md={18} lg={19}>
            <TxtColStyle className="col-txt-right">
              {formatNumber(data.transferCount)}
            </TxtColStyle>
          </Col>
        </Row>

        {data.decimals &&
          <Row gutter={24}>
            <Col span={24} md={6} lg={5}>
              <TxtColStyle className="col-txt-left">
                Decimals
              </TxtColStyle>
            </Col>
            <Col span={24} md={18} lg={19}>
              <TxtColStyle className="col-txt-right">
                {data.decimals}
              </TxtColStyle>
            </Col>
          </Row>
        }

        {data.website &&
          <Row gutter={24}>
            <Col span={24} md={6} lg={5}>
              <TxtColStyle className="col-txt-left">
                Website
              </TxtColStyle>
            </Col>
            <Col span={24} md={18} lg={19}>
              <TxtColStyle className="col-txt-right">
                <Link to="">{data.website}</Link>
              </TxtColStyle>
            </Col>
          </Row>
        }
      </InnerPlaceHolder>
    </BoxContentPlaceHolder>
  )
}