import { useCallback } from 'react'

import { useAppSelector } from '../../app/hooks'
import { Datatable, DataTableTotal, tokenTransferVrc721Columns } from '../../common'
import { TokenDetailsType } from '../../constants/types'
import { getVrc721TransferList } from './tokensSlice'

interface Vrc721TransferListProps {
  token: TokenDetailsType
  ownerAddress?: string
  tokenId?: string
}

export default function TokenDetailsTransferList({ token, ownerAddress, tokenId }: Vrc721TransferListProps) {
  const { transfers } = useAppSelector((state) => state.tokens.details)

  const getData = useCallback(
    (params: any) => getVrc721TransferList({ ...params, tokenAddress: token.address, ownerAddress, tokenId }),
    [ownerAddress, tokenId, token]
  )

  const { data, total, loading } = transfers['vrc721']

  return (
    <Datatable
      loading={loading}
      total={total}
      columns={tokenTransferVrc721Columns}
      dataSource={data}
      header={<DataTableTotal total={total} type="tx" />}
      getData={getData}
    />
  )
}