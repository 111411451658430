import {useEffect} from 'react'
import { Link, useParams, Redirect } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { Tabs, Row, Col } from 'antd'
import { Helmet } from 'react-helmet-async'

import {
  MainDetails,
  BoxContentTable,
  BoxContentPlaceHolder,
  OwnerHeader,
  OwnerBalanceBox,
  OwnerTitle,
  TextMain
} from './tokenDetailsPageStyles'

import {
  SkeletonLoading,
  PageHeaderTokenDetail,
  TabsWithHash,
} from '../../common'

import { useQuery } from '../../app/hooks'
import { TokenParamsType } from '../../constants/types'
import { formatNumber } from '../../utils'
import { useStateDetails, useAppSelector,useAppDispatch } from '../../app/hooks'
import { getTokenDetailsByAddress, getTokenBalanceOnAccount } from './tokensSlice'
import TokenDetailsHolderList from './TokenDetailsHolderList'
import Vrc2021TransferList from './Vrc2021TransferList'
import Vrc721TransferList from './Vrc721TransferList'
import Vrc721InventoryList from './Vrc721InventoryList'
import { TabStyle } from '../../appStyles'
import ContractInfomation from './ContractInfomation'
import Vrc2021Overview from './Vrc2021Overview'
import Vrc2021MoreInfo from './Vrc2021MoreInfo'
import Vrc721Overview from './Vrc721Overview'
import Vrc721MoreInfo from './Vrc721MoreInfo'
import { fungibleTokenStandard, nonFungibleTokenStandard } from '../../constants'
import CheckStatus from '../../common/CheckStatus'


const { TabPane } = Tabs

export default function TokenDetailsPage() {
  const query = useQuery()
  const owner = query.get('account')?.trim() || ''
  const tokenId = query.get('tokenId')?.trim() || ''
  const { address } = useParams<TokenParamsType>()
  const { tokenBalanceOnAccount } = useAppSelector((state) => state.tokens.details)
  const { loading, data } = useStateDetails(getTokenDetailsByAddress, address, 'tokens')
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (owner && address) dispatch(getTokenBalanceOnAccount({ owner,address }))
  }, [owner,address, dispatch])
  if (loading === 'pending') return <SkeletonLoading />
  if (loading === 'failed') return <Redirect to={`/search?q=${address}`} />
  if (!data) return null

  return (
    <MainDetails>
      {(data.price && data.name && data.symbol) && (
        <Helmet>
          <title>${formatNumber(data.price)} | {data.name} ({data.symbol?.toUpperCase()}) | VIC Scan - Viction Block Explorer</title>
        </Helmet>
      )}

      {(!data.price && data.name && data.symbol) && (
        <Helmet>
          <title>{data.name} ({data.symbol?.toUpperCase()}) | VIC Scan - Viction Block Explorer</title>
        </Helmet>
      )}

      <PageHeaderTokenDetail token={data} />

      <Row gutter={24}>
        <Col span={24} md={12} className="rp-mb">
          {(!data.type || fungibleTokenStandard.includes(data.type.toLowerCase())) && <Vrc2021Overview data={data} />}
          {(data.type && nonFungibleTokenStandard.includes(data.type.toLowerCase())) && <Vrc721Overview data={data} tokenId={tokenId} />}
        </Col>
        <Col span={24} md={12}>
          {(!data.type || fungibleTokenStandard.includes(data.type.toLowerCase())) && <Vrc2021MoreInfo data={data} />}
          {(data.type && nonFungibleTokenStandard.includes(data.type.toLowerCase())) && <Vrc721MoreInfo data={data} tokenId={tokenId} />}
        </Col>
      </Row>

      {!!owner && !tokenBalanceOnAccount.data.isNft && (
        <BoxContentPlaceHolder style={{ marginTop: '25px' }}>
          <Row>
            <Col span={24} md={!!tokenBalanceOnAccount.data.price ? 8 : 12}>
              
              <div>
                <OwnerHeader>FILTERED BY TOKEN HOLDER</OwnerHeader>
                <div><Link style={{wordBreak:"break-word"}} to={`/address/${owner}`}>{owner}</Link></div>
              </div>
            </Col>

            <Col span={12} md={!!tokenBalanceOnAccount.data.price ? 8 : 12}>
              <OwnerBalanceBox>
                <div><OwnerTitle>BALANCE</OwnerTitle></div>
                {tokenBalanceOnAccount && tokenBalanceOnAccount.data.balance && tokenBalanceOnAccount.data.decimals &&
                  <div><TextMain>{formatNumber(new BigNumber(tokenBalanceOnAccount.data.balance).dividedBy(10 ** tokenBalanceOnAccount.data.decimals).toNumber())} {tokenBalanceOnAccount.data.symbol}</TextMain></div>
                }
            </OwnerBalanceBox>
            </Col>
            {!!tokenBalanceOnAccount.data.price && tokenBalanceOnAccount && !!tokenBalanceOnAccount.data.balance && !!tokenBalanceOnAccount.data.decimals &&
              <Col span={12} md={8}>
                <OwnerBalanceBox>
                  <div><OwnerTitle>VALUE</OwnerTitle></div>
                  <div><TextMain>${formatNumber(new BigNumber(tokenBalanceOnAccount.data.balance).dividedBy(10 ** tokenBalanceOnAccount.data.decimals).multipliedBy(tokenBalanceOnAccount.data.price).toNumber())}</TextMain></div>
                </OwnerBalanceBox>
              </Col>
            }
          </Row>
        </BoxContentPlaceHolder>
      )}

      <BoxContentTable>
        <TabStyle>
          {data.type && fungibleTokenStandard.includes(data.type.toLowerCase()) && (
            <TabsWithHash type="card" tabKeys={["holders", "code"]}>
              <TabPane tab="Transfers" key="default">
                <Vrc2021TransferList token={data} />
              </TabPane>

              <TabPane tab="Holders" key="holders">
                <TokenDetailsHolderList token={data} totalSupply={data.totalSupplyNumber} price={data.price} />
              </TabPane>

              <TabPane tab={<div className='d-flex align-items-center'><span>Contract </span> &nbsp; <CheckStatus tokenStatus={data} isOnlyIcon/> </div>} key="code">
                {address && <ContractInfomation address={address} />}
              </TabPane>
            </TabsWithHash>
          )}

          {data.type && ['tr721', 'vrc721', 'vrc725'].includes(data.type.toLowerCase()) && (
            <TabsWithHash type="card" tabKeys={["inventory", "code"]}>
              <TabPane tab="Transfers" key="default">
                {!!owner && <Vrc721TransferList token={data} ownerAddress={owner} />}
                {!!tokenId && <Vrc721TransferList token={data} tokenId={tokenId} />}
                {!owner && !tokenId && <Vrc721TransferList token={data} />}
              </TabPane>

              <TabPane tab="Inventory" key="inventory">
                <Vrc721InventoryList token={data} ownerAddress={owner} tokenId={tokenId} />
              </TabPane>

              <TabPane tab={<div className='d-flex align-items-center'><span>Contract </span> &nbsp; <CheckStatus tokenStatus={data} isOnlyIcon/> </div>} key="code">
                {address && <ContractInfomation address={address} />}
              </TabPane>
            </TabsWithHash>
          )}

          {!data.type && (
            <TabsWithHash type="card" tabKeys={["code"]}>
              <TabPane tab="Transfers" key="default">
                <Vrc2021TransferList token={data} />
              </TabPane>

              <TabPane tab="Contract" key="code">
                {address && <ContractInfomation address={address} />}
              </TabPane>
            </TabsWithHash>
          )}
        </TabStyle>
      </BoxContentTable>
    </MainDetails>
  )
}

