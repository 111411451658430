import { RPC_ENDPOINT, CHAINID } from "../constants/env"

export const MAX_RECORD = 10000
export const supportedWalletOption = [{
    chainId: '0x' + CHAINID.toString(16), // 88
    chainName: CHAINID === 88 ? 'Viction Mainnet' : 'Viction Testnet',
    nativeCurrency: {
        name: 'VIC',
        symbol: 'VIC',
        decimals: 18,
    },
    rpcUrls: [RPC_ENDPOINT],
}]

export const tagWarningAddress: {[key: string]: {text: string, link: string, tag: string[]}} = {
    '0xbbE2a100822fc7022c0f42E277b338F67371c5b0': {
        text: 'This address involved in CoinEx Attack on Sep 12.',
        link: 'https://announcement.coinex.com/hc/en-us/articles/19187420867348-Latest-Progress-of-the-Hacking-Attack-on-Sep-12-2023-Updated-on-Sep-14-',
        tag: ['Hacker', 'CoinEx Attack']
    },
}

export const nonFungibleTokenStandard = ["trc721", "vrc721", "vrc725"]
export const fungibleTokenStandard = ["trc20", "trc21", "vrc20", "vrc21", "vrc25"]

export enum WalletAdapter {
    COIN98,
    VICTION,
    RAMPER,
    METAMASK,
}
