import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { PageHeader, Datatable, DataTableTotal, txColumns } from '../../common'
import { getTxList, resetTxList } from './transactionsSlice'

export default function TransactionList() {
  const dispatch = useAppDispatch()
  const { data, loading, total } = useAppSelector((state) => state['transactions']['list'])

  // reset state when component unmount
  useEffect(() => {
    return () => {
      dispatch(resetTxList())
    }
  }, [])
  return (
    <div>
      <PageHeader title="Transactions" />

      <Datatable
        loading={loading}
        total={total}
        columns={txColumns}
        dataSource={data}
        header={<DataTableTotal total={total} type="tx" />}
        getData={getTxList}
      />
    </div>
  )
}