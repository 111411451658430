

import bgBoxSearch from '../assets/images/bg-box-search.svg';
import bgBoxSearchDark from '../assets/images/bg-box-search-dark.svg';
import bgGeometryFooter from '../assets/images/bg-geometry.svg';
import bgGeometryFooterDark from '../assets/images/bg-geometry-dark.svg';

const Colors = (mode: string) => {
  return {

    // text
    text: mode === 'dark' ? '#F6F6F6' : '#141414',
    textOverlay: mode === 'dark' ? '#C3C3C3' : '#8B8B8B',
    // backgrounds / greys
    bg: mode === 'dark' ? '#1A1A1A' : '#E0DED8',
    bg1: mode === 'dark' ? '#353535' : '#EBE9E5',
    bg2: mode === 'dark' ? '#282828' : '#fff',
    bg3: mode === 'dark' ? '#1A1A1A' : '#606060',
    bg4: mode === 'dark' ? '#353535' : '#F6F6F6',
    bgHover: mode === 'dark' ? '#282828' : '#e0e0e0',
    trHover: mode === 'dark' ? '#353535' : '#F6F6F6',
    bgImageSearch: mode === 'dark' ? `${bgBoxSearchDark}` : `${bgBoxSearch}`,
    bgImageFooter: mode === 'dark' ? `${bgGeometryFooterDark}` : `${bgGeometryFooter}`,
    qrc: mode === 'dark' ? '#fff' : '#282828',

    //bg fixed
    bgWhite: mode === 'dark' ? '#fff' : '#fff',


    // border color
    border: mode === 'dark' ? '#353535' : '#EBE9E5',
    border1: mode === 'dark' ? '#282828' : '#F6F5F4',

    //primary colors
    primary: mode === 'dark' ? '#766B60' : '#766B60',
    primaryHover: mode === 'dark' ? 'rgba(118,107,96,.8)' : 'rgba(118,107,96,.8)',
    // color text link
    textLink: mode === 'dark' ? '#CDA349' : '#CDA349',

    // color text
    primaryText: mode === 'dark' ? '#F6F6F6' : '#fff',



    // other
    default: mode === 'dark' ? '#282828' : '#ccc',
    bgdefault: mode === 'dark' ? 'darkgray' : '#ccc',

    transfer: mode === 'dark' ? '#3f3f3f' : '#B3F8E9',
    swap: mode === 'dark' ? 'darkorange' : '#2db7f5',
    apporve: mode === 'dark' ? 'darkorange' : '#108ee9',

    in: mode === 'dark' ? '#02977e' : '#02977e',
    bgin: mode === 'dark' ? 'rgba(0,201,167,.2)' : 'rgba(0,201,167,.2)',

    out: mode === 'dark' ? '#b47d00' : '#b47d00',
    bgout: mode === 'dark' ? 'rgba(219,154,4,.2)' : 'rgba(219,154,4,.2)',

    up: '#009E0F',
    down: '#CF2A27',

    fail: mode === 'dark' ? '#FD4040' : '#DF1F38',
    bgfail: 'rgba(222,68,55,.1)',

    success: '#27AE60',
    bgsuccess: 'rgb(31, 199, 51, 0.16)',

    pending: '#b47d00',
    bgpending: 'rgba(219,154,4,.2)',
    warning: '#FF8F00',
    redAlert: '#DC143C',

    //Style button
    bgBtn: mode === 'dark' ? '#353535' : '#FFFFFF',
    textBtn: mode === 'dark' ? '#c2c2c2' : '#606060',
    bdBtn: mode === 'dark' ? '#c2c2c2' : '#e8e8e8',

    bgBtnActive: mode === 'dark' ? 'rgba(118,107,96,.3)' : 'rgba(118,107,96,.3)',
    bdBtnActive: mode === 'dark' ? '#766B60' : '#766B60',
    textBtnActive: mode === 'dark' ? '#fff' : '#766B60',

    bgBtnDisable: mode === 'dark' ? '#353535' : '#F6F6F6',
    textBtnDisable: mode === 'dark' ? '#3d3d3d' : '#F6F6F6',
  }
}

export default Colors