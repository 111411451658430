import { useCallback } from 'react'

import { useAppSelector } from '../../app/hooks'
import { Datatable, DataTableTotal, txColumns } from '../../common'
import { getTxListByBlock } from './blocksSlice'

export default function TransactionList({ blockNumber }: { blockNumber: string | undefined }) {
  const { data, loading, total } = useAppSelector((state) => state.blocks.details.transactions)

  const getData = useCallback(
    (params: any) => getTxListByBlock({ ...params, blockNumber }),
    []
  )

  return (
    <Datatable
      loading={loading}
      total={total}
      columns={txColumns}
      dataSource={data}
      header={<DataTableTotal total={total} type="tx" />}
      getData={getData}
    />
  )
}