import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { PageHeader, Datatable, DataTableTotal, tokenColumns } from '../../common'
import { getNormalTokenList, resetVrc2021List } from './tokensSlice'

export default function TokenListPage() {
  const dispatch = useAppDispatch()
  const { data, loading, total } = useAppSelector((state) => state['tokens']['list']['vrc2021'])  

  // reset state when component unmount
  useEffect(() => {
    return () => {
      dispatch(resetVrc2021List())
    }
  }, [])

  return (
    <div>
      <PageHeader title="Tokens" />

      <Datatable
        loading={loading}
        total={total}
        columns={tokenColumns}
        dataSource={data}
        header={<DataTableTotal total={total} type="token" />}
        getData={getNormalTokenList}
      />
    </div>
  )
}