import axios, { AxiosResponse } from 'axios'

import { TOMOSCAN_API_V2_URL } from '../constants/env'

export async function fetchTxDetailsByHash(hash: string): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/transaction/${hash}`)
}

export async function fetchTxList(offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/transaction/list?offset=${offset}&limit=${limit}`)
}

export async function fetchTxInternal(hash: string, offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/transaction/internal?txhash=${hash}&offset=${offset}&limit=${limit}`)
}

export async function fetchBlockDetailsByNumberOrHash(blockNumberOrHash: string): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/block/${blockNumberOrHash}`)
}

export async function fetchTxListByBlock(blockNumber: string, offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/transaction/listByBlock/${blockNumber}?offset=${offset}&limit=${limit}`)
}

export async function fetchBlockList(author: string | undefined, offset = 0, limit = 20): Promise<AxiosResponse> {
  if (author) {
    return await axios.get(`${TOMOSCAN_API_V2_URL}/block/list?author=${author}&offset=${offset}&limit=${limit}`)
  }
  return await axios.get(`${TOMOSCAN_API_V2_URL}/block/list?offset=${offset}&limit=${limit}`)
}

export async function fetchStats(): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/stats`)
}

export async function fetchEpochList(offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/epoch/list?offset=${offset}&limit=${limit}`)
}

export async function fetchEpochDetailsByNumber(epochNumber: string): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/epoch/${epochNumber}`)
}

export async function fetchEpochDetailsReward(epochNumber: string, rewardType: string, offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/epoch/${epochNumber}/reward?rewardType=${rewardType}&offset=${offset}&limit=${limit}`)
}

// vrc20/21 list
export async function fetchNormalTokenList(offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/token/list?offset=${offset}&limit=${limit}`)
}

// vrc721 list
export async function fetchVrc721TokenList(offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/token/nft/list?offset=${offset}&limit=${limit}`)
}

// vrc20/21 holder list
export async function fetchVrc2021HolderList(address: string, offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/tokenholder/${address}?offset=${offset}&limit=${limit}`)
}

// vrc721 holder list
export async function fetchVrc721HolderList(address: string, offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/tokenholder/nft/${address}?offset=${offset}&limit=${limit}`)
}

// vrc20/21 transfer list
export async function fetchVrc2021TransferList(address: string, account: string | undefined, offset = 0, limit = 20): Promise<AxiosResponse> {
  let query = `tokenAddress=${address}&offset=${offset}&limit=${limit}`
  if (account) {
    query = `tokenAddress=${address}&account=${account}&offset=${offset}&limit=${limit}`
  }
  return await axios.get(`${TOMOSCAN_API_V2_URL}/tokentx/list?${query}`)
}

// vrc721 transfer list
export async function fetchVrc721TransferList(tokenAddress: string, ownerAddress: string | undefined, tokenId: string | undefined, offset = 0, limit = 20): Promise<AxiosResponse> {
  let query = `tokenAddress=${tokenAddress}&offset=${offset}&limit=${limit}`

  if (ownerAddress && !tokenId) {
    query = `tokenAddress=${tokenAddress}&account=${ownerAddress}&offset=${offset}&limit=${limit}`
  }

  if (!ownerAddress && tokenId) {
    query = `tokenAddress=${tokenAddress}&tokenId=${tokenId}&offset=${offset}&limit=${limit}`
  }

  if (ownerAddress && tokenId) {
    query = `tokenAddress=${tokenAddress}&account=${ownerAddress}&tokenId=${tokenId}&offset=${offset}&limit=${limit}`
  }

  return await axios.get(`${TOMOSCAN_API_V2_URL}/tokentx/nft/list?${query}`)
}

export async function fetchTokenDetailsByAddress(address: string): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/token/${address}`)
}

export async function fetchMasternodeList(offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/masternodes?offset=${offset}&limit=${limit}`)
}

export async function fetchAccountList(offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/account/list?offset=${offset}&limit=${limit}`)
}
// Account Detail
export async function fetchAccountDetails(address: string): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/account/${address}`)
}

export async function fetchTokensInAccount(address: string, offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/account/${address}/tokenBalance?offset=${offset}&limit=${limit}`)
}

export async function fetchInternalTx(address: string, offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/transaction/internal?account=${address}&offset=${offset}&limit=${limit}`)
}

export async function fetchUserRewards(address: string, offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/account/${address}/reward?offset=${offset}&limit=${limit}`)
}

export async function fetchTokenTransactions(address: string, offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/transaction/list?account=${address}&offset=${offset}&limit=${limit}`)
}

export async function fetchVrcTokenTransactions(address: string, offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/tokentx/list?account=${address}&offset=${offset}&limit=${limit}`)
}

export async function fetchContractList(offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/contract/verified/list?offset=${offset}&limit=${limit}`)
}

export async function fetchTxListByAdddress(address: string, offset = 0, limit = 20): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/transaction/list?account=${address}&offset=${offset}&limit=${limit}`)
}

export async function fetchContractEventLogs(address: string): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/eventlog?address=${address}`)
}

export async function fetchCompilerVersion(): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/contract/solc/versions`)
}

export async function fetchContractVerify(params: any): Promise<AxiosResponse> {
  return await axios.post(`${TOMOSCAN_API_V2_URL}/contract/verify`, params)
}

export async function fetchTomoTokenList(): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/token/all`)
}

export async function searchOneId(text: string): Promise<AxiosResponse> {
  return await axios.get(`${TOMOSCAN_API_V2_URL}/oneid/search?text=${text}`)
}

export async function searchD3(text: string): Promise<AxiosResponse> {
  const D3_API = `https://cloudflare-dns.com/dns-query?name=${text}&type=16`
  return axios.get(D3_API, {
    headers: {
      accept: 'application/dns-json'
    }
  })
}


export async function fetchVrc721InventoryList(tokenAddress: string, ownerAddress: string, tokenId: string, offset = 0, limit = 20): Promise<AxiosResponse> {
  let query = `tokenAddress=${tokenAddress}&offset=${offset}&limit=${limit}`

  if (ownerAddress && !tokenId) {
    query = `tokenAddress=${tokenAddress}&account=${ownerAddress}&offset=${offset}&limit=${limit}`
  }

  if (!ownerAddress && tokenId) {
    query = `tokenAddress=${tokenAddress}&tokenId=${tokenId}&offset=${offset}&limit=${limit}`
  }

  if (ownerAddress && tokenId) {
    query = `tokenAddress=${tokenAddress}&account=${ownerAddress}&tokenId=${tokenId}&offset=${offset}&limit=${limit}`
  }

  return await axios.get(`${TOMOSCAN_API_V2_URL}/nft/inventory?${query}`)
}

export async function fetchTokenBalanceOnAccount(owner: string,address: string ): Promise<AxiosResponse> {
  
  return await axios.get(`${TOMOSCAN_API_V2_URL}/account/${owner}/tokenBalanceOf?tokenAddress=${address}`)
}

