import { useState, MouseEvent } from 'react'
import Web3 from 'web3'
import styled from 'styled-components'
import { Modal, Button } from 'antd';

// Logo of wallets
import MetamaskLog from '../assets/images/metamask.svg'
import Coin98Logo from '../assets/images/coin98.svg'
import VictionLogo from '../assets/images/viction-wallet-logo.svg'
import RamperLogo from '../assets/images/ramper-wallet-logo.svg'

import { CHAINID } from '../constants/env';

import { Link } from 'react-router-dom';
import { supportedWalletOption } from '../constants';
import { WalletAdapter } from '../constants/index'

// import WalletConnect from '../assets/images/walletconnect.svg'
declare global {
    interface Window {
        ethereum: any
        web3: any
        coin98: any
        viction: any
        ramper2: any
    }
}
type ModalWalletProp = {
    account: string
    isConnected: boolean
    setAccount: (v: string) => void
    setIsConnected: (v: boolean) => void
}

const ModalWallet = ({ account, isConnected, setAccount, setIsConnected }: ModalWalletProp) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true)
    }
    const handleCancel = () => {
        setIsModalVisible(false)
    }

    // the proiority order: coin98 > viction > ramper > metamask
    const connectCoin98 = async () => {
        window.web3 = new Web3(window.coin98.provider)
        const networkId = await window.coin98.provider.request({
            method: 'net_version',
        });
        setIsModalVisible(false)
        try {
            if (Number(networkId) !== CHAINID) {
                await window.coin98.provider.request({
                    method: 'wallet_addEthereumChain',
                    params: supportedWalletOption,
                })
                const getAccount = (await window.web3.eth.getAccounts())[0]
                if (getAccount) {
                    setIsConnected(true)
                    setAccount(getAccount)
                } else {
                    setIsConnected(false)
                }
                return true
            } else {
                await window.coin98.provider.send('eth_requestAccounts');
                const getAccount = (await window.web3.eth.getAccounts())[0]
                if (getAccount) {
                    setIsConnected(true)
                    setAccount(getAccount)
                } else {
                    setIsConnected(false)
                }
            }
        } catch (error) {
            console.error(error)
            return false
        }
    }
    const connectMetamask = async () => {

        window.web3 = new Web3(window.ethereum)
        const networkId = await window.ethereum.request({
            method: 'net_version',
        });
        setIsModalVisible(false)
        try {
            if (Number(networkId) !== CHAINID) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: supportedWalletOption,
                })
                const getAccount = (await window.web3.eth.getAccounts())[0]
                if (getAccount) {
                    setIsConnected(true)
                    setAccount(getAccount)
                } else {
                    setIsConnected(false)
                }
                return true
            } else {
                await window.ethereum.send('eth_requestAccounts');
                const getAccount = (await window.web3.eth.getAccounts())[0]
                if (getAccount) {
                    setIsConnected(true)
                    setAccount(getAccount)
                } else {
                    setIsConnected(false)
                }
            }
        } catch (error) {
            console.error(error)
            return false
        }
    }
    const connectViction = async () => {
        window.web3 = new Web3(window.viction)
        setIsModalVisible(false)
        try {
            const res = await window.viction.request({
                method: 'eth_requestAccounts',
            });;
            const getAccount = res[0]
            if (getAccount) {
                setIsConnected(true)
                setAccount(getAccount)
            } else {
                setIsConnected(false)
            }
        } catch (error) {
            console.error(error)
            return false
        }
    }
    const connectRamper = async () => {
        window.web3 = new Web3(window.ramper2.provider)
        const networkId = await window.ramper2.provider.request({
            method: 'net_version',
        });
        setIsModalVisible(false)
        try {
            if (Number(networkId) !== CHAINID) {
                await window.ramper2.provider.request({
                    method: 'wallet_addEthereumChain',
                    params: supportedWalletOption,
                })
                const getAccount = (await window.web3.eth.getAccounts())[0]
                if (getAccount) {
                    setIsConnected(true)
                    setAccount(getAccount)
                } else {
                    setIsConnected(false)
                }
                return true
            } else {
                await window.ramper2.provider.send('eth_requestAccounts');
                const getAccount = (await window.web3.eth.getAccounts())[0]
                if (getAccount) {
                    setIsConnected(true)
                    setAccount(getAccount)
                } else {
                    setIsConnected(false)
                }
            }
        } catch (error) {
            console.error(error)
            return false
        }
    }
    const selectWalletAdapter = async (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
        const walletType = parseInt(e.currentTarget.getAttribute('value') ?? '0')
        switch (walletType) {
            case WalletAdapter.COIN98:
                if (window.coin98) {
                    await connectCoin98()
                } else {
                    alert('Please install Coin98 wallet')
                }
                break
            case WalletAdapter.VICTION:
                if (window.coin98) {
                    await connectCoin98()
                } else if (window.viction) {
                    await connectViction()
                } else {
                    alert('Please install Viction wallet')
                }
                break
            case WalletAdapter.RAMPER:
                if (window.coin98) {
                    await connectCoin98()
                } else if (window.viction) {
                    await connectViction()
                } else if (window.ramper2) {
                    await connectRamper()
                } else {
                    alert('Please install Ramper wallet')
                }
                break
            case WalletAdapter.METAMASK:
                if (window.coin98) {
                    await connectCoin98()
                } else if (window.viction) {
                    await connectViction()
                } else if (window.ramper2) {
                    await connectRamper()
                } else if (window.ethereum) {
                    await connectMetamask()
                } else {
                    alert('Please install Metamask')
                }
                break
            default: alert('unsupported wallet')

        }
    }
    return (
        <>
            <div className={!isConnected ? 'error' : 'success'} style={{ marginBottom: "20px" }}>
                {!isConnected ?
                    <ButtonConnect onClick={showModal}>
                        <TextMain>Connect a Wallet</TextMain>
                    </ButtonConnect>
                    :
                    <>
                        <TextMain>Connected</TextMain> [<Link to={`/address/${account}`}>{account}</Link>]
                    </>
                }
            </div>
            <ModalStyled title="Connect a Wallet" visible={isModalVisible} footer={null} onCancel={handleCancel}>
                <ButtonStyled onClick={(e) => selectWalletAdapter(e)} value={WalletAdapter.COIN98}>
                    <TextMain style={{ fontSize: "18px" }}>Coin98</TextMain>
                    <img width="24px" src={Coin98Logo} alt="Coin98Logo" />
                </ButtonStyled>
                <ButtonStyled onClick={(e) => selectWalletAdapter(e)} value={WalletAdapter.VICTION}>
                    <TextMain style={{ fontSize: "18px" }}>Viction</TextMain>
                    <img width="24px" src={VictionLogo} alt="VictionLogo" />
                </ButtonStyled>
                <ButtonStyled onClick={(e) => selectWalletAdapter(e)} value={WalletAdapter.RAMPER}>
                    <TextMain style={{ fontSize: "18px" }}>Ramper</TextMain>
                    <img width="24px" src={RamperLogo} alt="RamperLogo" />
                </ButtonStyled>
                <ButtonStyled onClick={(e) => selectWalletAdapter(e)} value={WalletAdapter.METAMASK}>
                    <TextMain style={{ fontSize: "18px" }}>MetaMask</TextMain>
                    <img width="24px" src={MetamaskLog} alt="MetaMask" />
                </ButtonStyled>
                {/* <ButtonStyled>
                    <TextMain style={{ fontSize: "18px" }}>WalletConnect</TextMain>
                    <img width="24px" src={WalletConnect} alt="WalletConnect" />
                </ButtonStyled> */}
            </ModalStyled>
        </>
    );
}

export default ModalWallet

const ModalStyled = styled(Modal)`
    .ant-modal-content{
        border-radius: 16px;
        overflow: hidden;
        background-color: ${({ theme }) => theme.bg};
    }
    .ant-modal-header{
        background-color: ${({ theme }) => theme.bg};
        border-bottom: 1px solid ${({ theme }) => theme.border};
        .ant-modal-title{
            color: ${({ theme }) => theme.text};
            font-size: 18px;
        }
    }
    .anticon-close{
        color: ${({ theme }) => theme.textOverlay};
    }
`

const TextMain = styled.span`
    color: ${({ theme }) => theme.text};
`
const ButtonConnect = styled(Button)`
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.bg4};
    border: none;
    :hover,:focus{
        background-color: ${({ theme }) => theme.bg4};
    }
`
const ButtonStyled = styled(Button)`
    height: auto;
    display: flex;
    width: 100%;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 12px 16px;
    border: 1px solid ${({ theme }) => theme.border};
    background-color: ${({ theme }) => theme.bg2};
    :hover,:focus{
        background-color: ${({ theme }) => theme.bg2};
    }
`