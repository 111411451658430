import React from "react"
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Divider } from 'antd'

import { RootState } from '../../app/store'

import LogoTomoscan from '../../assets/images/tomoscan-logo-dark.svg'
import LogoTomoscanLight from '../../assets/images/tomoscan-logo-light.svg'
import {
  Wrapper,
  CenterStyle,
  ParagraphStyle,
  TitleStyle
} from "./requestLimitStyles"

function RequestLimit() {
    const mode = useSelector((state: RootState) => state.general.mode)
    return (
      <Wrapper>
        <CenterStyle>
            <Link to="/">
              <img
            className="logo-size"
              width="300"
              style={{marginBottom: "20px"}}
                src={(mode === "dark" ? LogoTomoscanLight : LogoTomoscan)} alt="VICSCAN"
              />
            </Link>
            <div className="mt-3">
                <TitleStyle level={1}>429</TitleStyle>
                <ParagraphStyle style={{ fontSize: "25px" }}>Too many requests received</ParagraphStyle>
                <Divider style={{ borderTopColor: "rgba(145,165,180,.6" }} />
                <ParagraphStyle>Please try again later</ParagraphStyle>
            </div>
          </CenterStyle>
      </Wrapper>
    )
}

export default RequestLimit
