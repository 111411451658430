import React from 'react'
import { Redirect } from 'react-router'
import { useStateDetails } from '../../app/hooks'
import { SkeletonLoading } from '../../common'
import ContentOfTheContractTab from '../contracts/ContentOfTheContractTab'
import { getContractDetailsByAddress } from '../contracts/contractsSlice'

const ContractInfomation = ({ address}: any) => {
    const { loading, data } = useStateDetails(getContractDetailsByAddress, address, 'contracts')
    if (loading === 'pending') return <SkeletonLoading />
    if (loading === 'failed') return <Redirect to={`/search?q=${address}`} />
    if (!data) return null
    return (
        <ContentOfTheContractTab data={data} address={address} />
    )
}

export default ContractInfomation