import React, { useState } from 'react'
import styled from 'styled-components'
import {DownloadOutlined} from '@ant-design/icons'
import {
  Modal,
  Tooltip,
  Button,
  Col,
  Row,
  Form,
  Input,
} from 'antd'
import { getUnixTime } from 'date-fns'
import { useGeneralContext } from '../providers/general'
import { TOMOSCAN_API_V2_URL } from '../constants/env'
import { DatePicker } from '../common'
import { getFirstAndLastDay } from '../utils'

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    background: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-modal-header {
    background: transparent;
    border: 0;
    padding: 34px 34px 0;
    @media only screen and (max-width: 767px) {
      padding: 24px 14px 0;
    }
    .ant-modal-title {
      font-weight: 900;
      font-size: 20px;
    }
  }
  .ant-modal-body {
    padding: 14px 34px;
    @media only screen and (max-width: 767px) {
      padding: 14px;
    }
    .ant-form-item {
      margin-bottom: 14px;
    }
    label {
      color: #606060;
      font-weight: bold;
    }
    .txt-desc {
      color: #606060;
      margin-bottom: 15px;
      ul {
        padding-left: 15px;
      }
    }
  }
  .ant-modal-footer {
    padding:  4px 34px 34px;
    border: 0;
    @media only screen and (max-width: 767px) {
      padding:  4px 14px 24px;
    }
    .style-footer-button {
      display: flex;
      gap: 16px;
    }
  }

  /*dark mode modal*/
  &.style-modal-dark {
    .ant-modal-content {
      background-color: #333333!important;
      .ant-modal-title {
        color: #C3C3C3!important;
      }
      .ant-modal-close {
        color: #FFFFFF!important;
      }
    }
    .ant-input-affix-wrapper,
    .ant-select-selector,
    .ant-picker {
      background-color: transparent!important;
      color: #ffffff!important;
    }
    input {
      background-color: transparent!important;
      color: #ffffff!important;
    }
    .ant-picker-suffix,
    .ant-select-arrow {
      color: #ffffff!important;
    }
    .ant-form-item-label label {
      color: #C3C3C3!important;
    }
    .ant-form-item-explain {
      color: #C3C3C3!important;
    }
    .txt-desc {
      color: #C3C3C3!important;
    }
  }
`
const TextMain = styled.span`
  color: ${({ theme }) => theme.textLink};
  cursor: pointer;
  &:hover{
      text-decoration: underline;
  }
`
const ExportStyled = styled.div`
  position: absolute;
  right: 24px;
  top: 34px;
  @media(max-width: 767px){
    position: static;
  }
`
const ButtonStyled = styled(Button)`
  border-radius: 8px;
  padding: 8px 24px;
  height: auto!important;
  font-size: 15px;
  display: block;
  width: 100%;
  margin: 0!important;
  &.btn-purple-light {
    background: transparent;
    border: 1px solid #108ee9;
    color: #108ee9;
  }
  &.btn-purple-dark {
    background: #108ee9;
    border: 1px solid #108ee9;
    color: #fff;
  }
`

export default function ExportData({ account, type = 'all', title }) {  
  const { firstDay, lastDay } = getFirstAndLastDay()
  const [range, setRange] = useState({ from: firstDay.getTime() / 1000, to: lastDay.getTime() / 1000 })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [tokenAddress, setTokenAddress] = useState('')
//   const [isReCaptcha, setReCaptcha] = useState(false)
//   const refCaptcha = useRef()

  const showModal = () => {
    setIsModalVisible(true)
    setRange({ from: firstDay.getTime() / 1000, to: lastDay.getTime() / 1000 })
    // setDataType(type)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    // refCaptcha.current.reset()
    // setReCaptcha(false)
  }

  function handleChangeFrom(date) {
    const from = getUnixTime(date)
    
    if (!date || from > range.to) return
    setRange({ ...range, from })
  }

  function handleChangeTo(date) {
    const to = getUnixTime(date)

    if (!date || to < range.from) return
    setRange({ ...range, to })
  }

  function handleChangeTokenAddress(e) {
    setTokenAddress(e.target.value)
  }

//   function handleChangeReCaptcha(value) {
//     if (value) {
//       setReCaptcha(true)
//     } else {
//       setReCaptcha(false)
//     }
//   }

  const { mode } = useGeneralContext()
  return (
    <>
      <ExportStyled>
        <Tooltip title="Maximum 10,000 records">
          <TextMain style={{ marginBottom: "10px", display: "inline-block" }} onClick={showModal}>{`Export to CSV`} <DownloadOutlined /></TextMain>
        </Tooltip>
      </ExportStyled>
      <ModalStyled
        className={mode === 'dark' ? 'style-modal-dark' : 'style-modal-light'}
        title={`Export ${title}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={
          <div className="style-footer-button">
            <ButtonStyled className="btn-purple-light" onClick={handleCancel}>Cancel</ButtonStyled>
            <ButtonStyled className="btn-purple-dark" key="download">
              {type === 'internalTx' &&
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${TOMOSCAN_API_V2_URL}/transaction/exportcsv?account=${account}&fromTime=${range.from}&toTime=${range.to}&internalTx=true`}
                >
                  Download
                </a>
              }
              {type === 'transaction' &&
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${TOMOSCAN_API_V2_URL}/${type}/exportcsv?account=${account}&fromTime=${range.from}&toTime=${range.to}`}
                >
                  Download
                </a>
              }
              {type === 'tokenTx' && tokenAddress &&
                <a
                  style={{color: '#fff'}}
                  target="_blank"
                  rel="noreferrer"
                  href={`${TOMOSCAN_API_V2_URL}/${type}/exportcsv?account=${account}&tokenAddress=${tokenAddress}&fromTime=${range.from}&toTime=${range.to}`}
                >
                  Download
                </a>
              }
              {type === 'tokenTx' && !tokenAddress &&
                <a
                  style={{color: '#fff'}}
                  target="_blank"
                  rel="noreferrer"
                  href={`${TOMOSCAN_API_V2_URL}/${type}/exportcsv?account=${account}&fromTime=${range.from}&toTime=${range.to}`}
                >
                  Download
                </a>
              }
              {type === 'userRewards' &&
                <a
                  style={{color: '#fff'}}
                  target="_blank"
                  rel="noreferrer"
                  href={`${TOMOSCAN_API_V2_URL}/account/${account}/reward/exportcsv?fromTime=${range.from}&toTime=${range.to}`}
                >
                  Download
                </a>
              }
              </ButtonStyled>
          </div>
        }
      >
        <div className="txt-desc">
          <ul>
            <li>If timeout happens, try selecting shorter time period.</li>
          </ul>
        </div>
        <Form layout="vertical">
          <Row gutter={16}>            
            <Col span={24} md={12}>
              <Form.Item label="From">
                <DatePicker
                  value={new Date(range.from * 1000)}
                  onChange={handleChangeFrom}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="To">
                <DatePicker
                  value={new Date(range.to * 1000)}
                  onChange={handleChangeTo}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            {type === 'tokenTx' &&
              <Col span={24}>
                <Form.Item
                  label="Token Address"
                >
                <Input placeholder="Add token address (Option)" onChange={ handleChangeTokenAddress}/>
                </Form.Item>
              </Col>
            }
            {/* <Col span={24}>
              <ReCAPTCHA
                ref={refCaptcha}
                sitekey="6Ld07kkcAAAAANcruCOKILpfps6r1kBDDkgvUGxq"
                onChange={handleChangeReCaptcha}
                theme={mode === 'dark' ? 'dark' : 'light'}
              />
            </Col> */}
          </Row>
        </Form>

      </ModalStyled>
    </>
  )
}