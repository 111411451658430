import { useEffect } from 'react'
import { Link } from 'react-router-dom'
// import { TwitterTimelineEmbed } from 'react-twitter-embed'

// import icPrice from '../../assets/images/ic-price.svg';
// import icEpochs from '../../assets/images/ic-epochs.svg';
// import icBlocks from '../../assets/images/ic-blocks.svg';
// import icTransactions from '../../assets/images/ic-transactions.svg';
// import icMarketCap from '../../assets/images/ic-market-cap.svg';
// import icAccount from '../../assets/images/ic-account.svg';
import icListBlock from '../../assets/images/ic-list-block.svg';
import icListTransactions from '../../assets/images/ic-list-transactions.svg';

import {
  Typography,
  Row,
  Col,
} from 'antd';

import {
  SearchBoxWrapper,
  ContentHomePage,
  MainDetailToken,
  TitleBoxStart,
  InfoDetails,
  CardBoxTable,
  CardTableContent,
  TitleHeaderBox,
  TitleSearch,
  ContentMainTop,
  ContentMainTable,
  InnerBox,

} from './homePageStyles'

import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { formatNumber, timeFormatDistance, toTomo } from '../../utils'
import { BlockDetailsType, TxDetailsType } from '../../constants/types'
import { SearchBox, DataTableLoading } from '../../common'
import { getStats, resetStats } from './generalSlice'
import { getBlockList, resetBlockList } from '../blocks/blocksSlice'
import { getTxList, resetTxList } from '../transactions/transactionsSlice'

const { Text } = Typography

export default function HomePage() {
  const dispatch = useAppDispatch()
  const stats = useAppSelector((state) => state.general.stats)
  const blocks = useAppSelector((state) => state.blocks.list)
  const txs = useAppSelector((state) => state.transactions.list)
  // const mode = useAppSelector((state) => state.general.mode)

  const getData = () => {
    dispatch(getStats())
    dispatch(getBlockList({author: undefined, offset: 0, limit: 10 }))
    dispatch(getTxList({ offset: 0, limit: 10 }))
  }

  useEffect(() => {
    getData()

    const timerInterval = setInterval(() => {
      getData()
    }, 7000)

    // reset state
    return () => {
      clearInterval(timerInterval)
      dispatch(resetStats())
      dispatch(resetBlockList())
      dispatch(resetTxList())
    }
  }, [])

  return (
    <>
      <ContentHomePage>
        <ContentMainTop>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <InnerBox>
                <SearchBoxWrapper>
                  <div className="w-100">
                    <TitleSearch>Viction - Blockchain Explorer</TitleSearch>
                    <SearchBox />
                  </div>
                </SearchBoxWrapper>

                <MainDetailToken>
                  <Row>
                    <Col xs={12} md={8}>
                      <InfoDetails>
                        {/* <div className="media-image">
                          <img src={(icPrice)} alt="" />
                        </div> */}
                        <div className="media-info txt-ellipsis">
                          <TitleBoxStart>VIC Price</TitleBoxStart>
                          <Text>${formatNumber(stats.data?.price)}</Text>
                        </div>
                      </InfoDetails>
                    </Col>

                    <Col xs={12} md={8}>
                      <InfoDetails  href="/epochs">
                        {/* <div className="media-image">
                          <img src={(icMarketCap)} alt="" />
                        </div> */}
                        <div className="media-info txt-ellipsis">
                          <TitleBoxStart>Epochs</TitleBoxStart>
                          <Text>{formatNumber(stats.data?.epoch)} <span className="txt-sap">({formatNumber(stats.data?.tps)} TPS)</span></Text>
                        </div>
                      </InfoDetails>
                    </Col>

                    <Col xs={12} md={8}>
                      <InfoDetails href="/blocks">
                        {/* <div className="media-image">
                          <img src={(icTransactions)} alt="" />
                        </div> */}
                        <div className="media-info txt-ellipsis">
                          <TitleBoxStart>Blocks</TitleBoxStart>
                          <Text>{formatNumber(stats.data?.block)}</Text>
                        </div>
                      </InfoDetails>
                    </Col>

                    <Col xs={12} md={8}>
                      <InfoDetails>
                        {/* <div className="media-image">
                          <img src={(icEpochs)} alt="" />
                        </div> */}
                        <div className="media-info txt-ellipsis">
                          <TitleBoxStart>VIC Market cap</TitleBoxStart>
                          <Text>${formatNumber(stats.data?.marketCap, 2)}</Text>
                        </div>
                      </InfoDetails>
                    </Col>

                    <Col xs={12} md={8}>
                      <InfoDetails href="/txs">
                        {/* <div className="media-image">
                          <img src={(icBlocks)} alt="" />
                        </div> */}
                        <div className="media-info txt-ellipsis">
                          <TitleBoxStart>Transactions</TitleBoxStart>
                          <Text>{formatNumber(stats.data?.txs)}</Text>
                        </div>
                      </InfoDetails>
                    </Col>

                    <Col xs={12} md={8}>
                      <InfoDetails  href="/accounts">
                        {/* <div className="media-image">
                          <img src={(icAccount)} alt="" />
                        </div> */}
                        <div className="media-info txt-ellipsis">
                          <TitleBoxStart>Accounts</TitleBoxStart>
                          <Text>{formatNumber(stats.data?.accounts)}</Text>
                        </div>
                      </InfoDetails>
                    </Col>
                  </Row>
                </MainDetailToken>
              </InnerBox>
            </Col>
            {/* <Col xs={24} lg={7} className="hide-mobile">
              <CardBoxTable>
                <TitleHeaderBox style={{ marginBottom: '14px' }}>News</TitleHeaderBox>
                <TwitterTimelineEmbed
                  key={mode}
                  sourceType="list"
                  id="1482215334858100738"
                  theme={mode === "dark" ? "dark" : "light"}
                  options={{ height: 350, id: "list:1482215334858100738" }}
                  noScrollbar
                  noHeader
                />
              </CardBoxTable>
            </Col> */}
          </Row>
        </ContentMainTop>

        <ContentMainTable>
          <Row gutter={[24, 24]}>
            <Col xs={24} lg={12}>
              <CardBoxTable>
                <TitleHeaderBox>Latest Blocks</TitleHeaderBox>
                <CardTableContent>
                  {(blocks.loading === 'pending') && (blocks.data.length === 0) &&
                    <div className="list-group-item">
                      <DataTableLoading />
                    </div>
                  }
                  {(blocks.data.length > 0) &&
                    blocks.data.map((block: BlockDetailsType) => {
                      return (
                        <div className="list-group-item" key={block.number}>
                          <Row gutter={24}>
                            <Col span={9}>
                              <div className="d-flex align-items-center">
                                <div className="txt-icon"><img src={(icListBlock)} alt="" /></div>
                                <div className="txt-body">
                                  <Text><Link to={`/block/${block.number}`}>{block.number}</Link></Text>
                                  <Text className="txt-ellipsis" type="secondary">{timeFormatDistance(block.timestamp)}</Text>
                                </div>
                              </div>
                            </Col>

                            <Col span={10}>
                              <Text className="txt-ellipsis">{block.m1Name}</Text>
                              <Text className="txt-ellipsis"><Link to={`/address/${block.m1}`}>{block.m1}</Link></Text>
                            </Col>

                            <Col span={5} className="text-right">
                              <Text className="txt-ellipsis">{block.transactions} txns</Text>
                              <Text className="txt-ellipsis" type="secondary">in 2 secs</Text>
                            </Col>
                          </Row>
                        </div>
                      )
                    })
                  }
                </CardTableContent>
                <div className="btn-view-all"><Link to="/blocks">View all Blocks</Link></div>
              </CardBoxTable>
            </Col>

            <Col xs={24} lg={12}>
              <CardBoxTable>
                <TitleHeaderBox>Latest Transactions</TitleHeaderBox>
                <CardTableContent>
                  {(txs.loading === 'pending') && (txs.data.length === 0) &&
                    <div className="list-group-item">
                      <DataTableLoading />
                    </div>
                  }
                  {(txs.data.length > 0) &&
                    // @ts-ignore
                    txs.data.map((tx: TxDetailsType) => {
                      return (
                        <div className="list-group-item" key={tx.hash}>
                          <Row gutter={24}>
                            <Col span={9}>
                              <div className="d-flex align-items-center">
                                <div className="txt-icon"><img src={(icListTransactions)} alt="" /></div>
                                <div className="txt-body">
                                  <Text><Link to={`/tx/${tx.hash}`}>{tx.hash}</Link></Text>
                                  <Text className="txt-ellipsis" type="secondary">{timeFormatDistance(tx.timestamp)}</Text>
                                </div>
                              </div>
                            </Col>

                            <Col span={10}>
                              <Text className="txt-ellipsis">From <Link to={`/address/${tx.from}`}>{tx.fromName || tx.from}</Link></Text>
                              <Text className="txt-ellipsis">To <Link to={`/address/${tx.to}`}>{tx.toName || tx.to}</Link></Text>
                            </Col>

                            <Col span={5} className="text-right">
                              <Text className="txt-ellipsis">Value</Text>
                              <Text className="txt-ellipsis"><Link to="">{formatNumber(toTomo(tx.value, 18))} VIC</Link></Text>
                            </Col>
                          </Row>
                        </div>
                      )
                    })
                  }
                </CardTableContent>
                <div className="btn-view-all"><Link to="/txs">View all Transactions</Link></div>
              </CardBoxTable>
            </Col>
          
            {/* <Col xs={24} className="hide-desktop">
              <CardBoxTable>
                <TitleHeaderBox style={{ marginBottom: '14px' }}>News</TitleHeaderBox>
                <TwitterTimelineEmbed
                  key={mode}
                  sourceType="list"
                  id="1482215334858100738"
                  theme={mode === "dark" ? "dark" : "light"}
                  options={{ height: 350, id: "list:1482215334858100738" }}
                  noScrollbar
                  noHeader
                />
              </CardBoxTable>
            </Col> */}
          </Row>
        </ContentMainTable>
      </ContentHomePage>
    </>
  )
}
