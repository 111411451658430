import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useParams, Link, Redirect } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import iconCopy from '../../assets/images/icon/icon-copy.svg'

import {
  Typography,
  Tabs,
  Space,
  Row,
  Col,
  Divider,
  Tooltip,
  Button,
} from 'antd'

import {
  ClockCircleOutlined,
  BlockOutlined,
  InfoCircleOutlined,
  CaretRightOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
  SwapRightOutlined
} from '@ant-design/icons'

import TokenTomo from '../../assets/images/token-tomo.png'

import {
  MainDetailsTransfer,
  TxtColStyle,
  TextInfoTable,
  ValueRow,
  Status,
  TextOverlay,
  StyleCopyIcon,
  TimeStyle
} from './transactionDetailsPageStyles'

import { TabStyle } from '../../appStyles'

import { getInternalTx, getTxDetailsByHash } from './transactionsSlice'
import { TxDetailsType, TxParamsType } from '../../constants/types'
import { toTomo, formatNumber, timeFormatUtc, timeFormatDistance } from '../../utils'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { PageHeader, SkeletonLoading, Datatable, TagStyled, AddressLink, TabsWithHash } from '../../common'
import TransactionDetailLogs from './TransactionDetailsLogs'
import ContractIconTooltip from '../../common/ContractIconTooltip'

const { Text } = Typography
const { TabPane } = Tabs

export default function TransactionDetailsTransfer() {
  const [isCopied, setCopied] = useState(false)

  const dispatch = useAppDispatch()
  const { hash } = useParams<TxParamsType>()

  const getInternalTxData = useCallback(
    (params: any) => getInternalTx({ ...params, hash }),
    []
  )
  useEffect(() => {
    dispatch(getTxDetailsByHash(hash))
  }, [dispatch, hash])

  const { data, internalTx, loading }: { data: null | TxDetailsType, internalTx: any, loading: string } = useAppSelector((state) => state.transactions.details)

  if (loading === 'pending') return <SkeletonLoading />
  if (loading === 'failed') return <Redirect to={`/search?q=${hash}`} />
  if (!data) return null
  const valueInUsd = new BigNumber(data.value).times(data.TOMOPrice).toNumber()
  const toAddress : string = data.to ?? data.contractAddress
  const feePayer = data.isGasSponsored ? (data.to || data.contractAddress) : data.from
  return (
    <MainDetailsTransfer>
      {hash &&
        <Helmet>
          <title>{`Transaction ${hash}`}</title>
        </Helmet>
      }
      <PageHeader title="Transaction details" />
      <TabStyle>
        <TabsWithHash type="card" tabKeys={["internalTxs", "logs"]}>
          <TabPane tab="Overview" key="default">
            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TxtColStyle className="col-txt-left">
                  Transaction ID &nbsp;
                  <Tooltip
                    placement='top'
                    title="A TxHash or transaction hash is a unique 66-character identifier that is generated whenever a transaction is executed."
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
                </TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TxtColStyle className="col-txt-right">
                  {/* <Space> */}
                    <Text>{data.hash}</Text>
                    <Tooltip
                      placement='top'
                      title={isCopied ? 'Copied' : 'Copy txhash to clipboard'}
                    >
                      <CopyToClipboard
                        text={data.hash}
                        onCopy={() => setCopied(true)}
                        //@ts-ignore
                        onMouseEnter={() => setCopied(false)}
                      >
                        <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                      </CopyToClipboard>
                    </Tooltip>
                  {/* </Space> */}
                </TxtColStyle>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TxtColStyle className="col-txt-left">
                  Type &nbsp;
                  <Tooltip
                    placement='top'
                    title="The transaction type."
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
                </TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TxtColStyle className="col-txt-right">
                  <TagStyled>{data.type}</TagStyled>
                </TxtColStyle>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TxtColStyle className="col-txt-left">
                  Status &nbsp;
                  <Tooltip
                    placement='top'
                    title="The status of the transaction."
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
                </TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TxtColStyle className="col-txt-right">
                  <Status status={data.status}>
                    {data.status === 'fail' ?
                      <>
                        <CloseCircleFilled />&nbsp;
                        <Text style={{ textTransform: 'capitalize', color: 'inherit' }}>{data.status} </Text> with error "{data.failReason ? data.failReason : ''}"
                      </>
                      : <><CheckCircleFilled /> <Text style={{ textTransform: 'capitalize', color: 'inherit' }}>{data.status}</Text></>}
                  </Status>
                </TxtColStyle>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TxtColStyle className="col-txt-left">
                  Timestamp
                  <Tooltip
                    placement='top'
                    title="The date and time at which a transaction is produced."
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
                </TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TxtColStyle className="col-txt-right">
                  {timeFormatUtc(data.timestamp)}
                  <Divider type='vertical' />
                  <TimeStyle type="secondary"><ClockCircleOutlined /> {timeFormatDistance(data.timestamp)}</TimeStyle>
                </TxtColStyle>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TxtColStyle className="col-txt-left">
                  Block
                  <Tooltip
                    placement='top'
                    title="Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced."
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
                </TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TxtColStyle className="col-txt-right">
                  <Link to={`/block/${data.blockNumber}`}>#{data.blockNumber}</Link>
                  <Divider type='vertical' />
                  <Text type="secondary"><BlockOutlined /> {formatNumber(data.latestBlock - data.blockNumber)} block confirmations</Text>
                </TxtColStyle>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TxtColStyle className="col-txt-left">
                  From
                  <Tooltip
                    placement='top'
                    title="The sending party of the transaction."
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
                </TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TxtColStyle className="col-txt-right">
                  <Space style={{flexWrap: "wrap"}}>
                    <Link to={`/address/${data.from}`}>{data.from}</Link>
                    {data.fromName ? data && data.to && <TextOverlay>({data.fromName})</TextOverlay> : ''}
                    <Tooltip
                      placement='top'
                      title={isCopied ? 'Copied' : 'Copy address to clipboard'}
                    >
                      <CopyToClipboard
                        text={data.from}
                        onCopy={() => setCopied(true)}
                        //@ts-ignore
                        onMouseEnter={() => setCopied(false)}
                      >
                        <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                      </CopyToClipboard>
                    </Tooltip>
                  </Space>
                </TxtColStyle>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TxtColStyle className="col-txt-left">
                  To
                  <Tooltip
                    placement='top'
                    title="The receiving party of the transaction (could be a contract address)."
                  >
                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
                </TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TxtColStyle className="col-txt-right">
                  <Space style={{flexWrap: "wrap"}}>
                    {data.to ?
                      <Space>
                        {data.toContract && <ContractIconTooltip />}
                        <Link to={`/address/${data.to}`}>{data.to}</Link>
                        {data.toName ? data && data.to && <TextOverlay>({data.toName})</TextOverlay> : ''}
                      </Space>
                      : data.contractAddress && <div>[Contract <Link to={`/address/${data.contractAddress}`}><ContractIconTooltip /> {data.contractAddress}</Link> Created]</div>
                    }
                    <Tooltip
                      placement='top'
                      title={isCopied ? 'Copied' : 'Copy address to clipboard'}
                    >
                      <CopyToClipboard
                        text={toAddress}
                        onCopy={() => setCopied(true)}
                        //@ts-ignore
                        onMouseEnter={() => setCopied(false)}
                      >
                        <Space>
                          <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                          {/* more detail swap */}
                          <Text italic type="secondary"></Text>
                          {/* more detail swap */}
                        </Space>
                      </CopyToClipboard>
                    </Tooltip>
                  </Space>
                </TxtColStyle>
                {data?.internal && data.internal.length > 0 &&
                  <TxtColStyle className="col-txt-right">
                    {data.internal.map((item: any, key: number) => {
                      return (
                        <div key={item.hash + key} style={{ width: "100%", fontSize: "12px", display: "flex", flexWrap:"wrap", gap: "8px", paddingLeft: "10px", alignItems: "center"}}>
                          <SwapRightOutlined />
                          <TextOverlay> TRANSFER </TextOverlay>
                          {formatNumber(toTomo(item.value, 18), 7)} VIC 
                          <TextOverlay> From </TextOverlay>
                            <div style={{ maxWidth: '190px' }}>
                              <Tooltip title={item.from}>
                                <Link className="txt-ellipsis" to={`/address/${item.from}`}>{item.from}</Link>
                              </Tooltip>
                            </div>
                          <TextOverlay> To </TextOverlay>
                          <div style={{ maxWidth: '190px' }}>
                            <Tooltip title={item.to}>
                            <Link className="txt-ellipsis" to={`/address/${item.to}`}>{item.to}</Link>
                            </Tooltip>
                          </div>
                        </div>
                      )
                    })}
                  </TxtColStyle>
                }
              </Col>
            </Row>

            {/* more detail swap */}
            {(data.actions.swap && data.actions.swap.length > 0) &&
              <Row gutter={24}>
                <Col span={24} md={5} lg={4}>
                  <TxtColStyle className="col-txt-left">
                    Transaction Action
                    <Tooltip
                    placement='top'
                    title="Highlighted events of the transaction."
                    >
                      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={20}>
                  <TxtColStyle className="col-txt-right">
                    {
                      data.actions.swap.map((item, index) => {
                        return (
                          <ValueRow key={index}>
                            <Text strong>Swap &nbsp;
                              {formatNumber(
                                toTomo(
                                  item.data && item.data.inPoolToken ? item.data.inPoolToken.amount : 0,
                                  item.data && item.data.inPoolToken ? item.data.inPoolToken.tokenDecimals : 18))}</Text>&nbsp;
                            <Link to={`/token/${item.data && item.data.inPoolToken ? item.data.inPoolToken.address : ''}`}>{item.data && item.data.inPoolToken ? item.data.inPoolToken.tokenSymbol : ''}</Link>&nbsp;
                            <Text strong>For &nbsp;
                              {formatNumber(
                                toTomo(
                                  item.data && item.data.outPoolToken ? item.data.outPoolToken.amount : 0,
                                  item.data && item.data.outPoolToken ? item.data.outPoolToken.tokenDecimals : 18
                                ))}</Text>&nbsp;
                            <Link to={`/token/${item.data && item.data.outPoolToken ? item.data.outPoolToken.address : ''}`}>{item.data && item.data.outPoolToken ? item.data.outPoolToken.tokenSymbol : ''}</Link>&nbsp;
                            <Text strong>On</Text>&nbsp;
                            <AddressLink title={item.data && item.data.lpName ? item.data.lpName : ''} address={item.data && item.data.lp ? item.data.lp : ''} />
                          </ValueRow>
                        )
                      })
                    }
                  </TxtColStyle>
                </Col>
              </Row>
            }

            {data.actions.mint_add_liquidity &&
              <Row gutter={24}>
                <Col span={24} md={5} lg={4}>
                  <TxtColStyle className="col-txt-left">Transaction Action</TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={20}>
                  <TxtColStyle className="col-txt-right">
                    {
                      data.actions.mint_add_liquidity.map((item, index) => {
                        return (
                          <Space key={index} style={{flexWrap: 'wrap'}}>
                            Supply
                            {formatNumber(toTomo(item.data.token0Amount, item.data.token0Decimals))}
                            <AddressLink type="token" tooltip={false} title={item.data.token0Symbol} address={item.data.token0Address} />
                            and
                            {formatNumber(toTomo(item.data.token1Amount, item.data.token1Decimals))}
                            <AddressLink type="token" tooltip={false} title={item.data.token1Symbol} address={item.data.token1Address} />
                            to
                            <AddressLink type="token" tooltip={false} title={item.data.lpName} address={item.data.address} />
                          </Space>
                        )
                      })
                    }
                  </TxtColStyle>
                </Col>
              </Row>
            }

            {data.actions.burn_remove_liquidity &&
              <Row gutter={24}>
                <Col span={24} md={5} lg={4}>
                  <TxtColStyle className="col-txt-left">Transaction Action</TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={20}>
                  <TxtColStyle className="col-txt-right">
                    {
                      data.actions.burn_remove_liquidity.map((item, index) => {
                        return (
                          <Space key={index} style={{flexWrap: "wrap"}}>
                            Remove
                            {formatNumber(toTomo(item.data.token0Amount, item.data.token0Decimals))}
                            <AddressLink type="token" tooltip={false} title={item.data.token0Symbol} address={item.data.token0Address} />
                            and
                            {formatNumber(toTomo(item.data.token1Amount, item.data.token1Decimals))}
                            <AddressLink type="token" tooltip={false} title={item.data.token1Symbol} address={item.data.token1Address} />
                            from
                            <AddressLink type="token" tooltip={false} title={item.data.lpName} address={item.data.address} />
                          </Space>
                        )
                      })
                    }
                  </TxtColStyle>
                </Col>
              </Row>
            }

            {(data.actions.transfer && data.actions.transfer.length > 0) &&
              <Row gutter={24}>
                <Col span={24} md={5} lg={4}>
                  <TxtColStyle className="col-txt-left">Tokens transferred</TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={20}>
                  <TxtColStyle className="col-txt-right">
                    {
                      data.actions.transfer.map((item, index) => {
                        const tokenId = item.data?.tokenId || item.data?.tokenIdString
                        const longTokenId = tokenId?.toString()?.length > 10
                        const tokenIdText = longTokenId ? `${tokenId?.toString()?.slice(0, 4)}...${tokenId?.toString()?.slice(-4)}` : tokenId
                        return (
                          <ValueRow key={index}>
                              <TextOverlay style={{fontSize: "10px", marginTop:"3px"}}><CaretRightOutlined /></TextOverlay>
                              <Text strong style={{whiteSpace:"nowrap"}}>From</Text>&nbsp;
                              <div style={{ maxWidth: '200px' }}><AddressLink address={item.data.from?.toLowerCase()} /></div>&nbsp;
                              <Text strong style={{whiteSpace:"nowrap"}}>To</Text>&nbsp;
                              <div style={{ maxWidth: '200px' }}><AddressLink address={item.data.to?.toLowerCase()} /></div>&nbsp;
                              <Text strong style={{whiteSpace:"nowrap"}}>For {formatNumber(toTomo(item.data.amount, item.data.tokenDecimals))}</Text>&nbsp;
                              <Link to={`/token/${item.data && item.data.address ? item.data.address : ''}`}>{item.data && item.data.tokenSymbol ? item.data.tokenSymbol : ''}</Link>&nbsp;
                              {tokenId  && (<Text>Token ID[<Link to={`/token/${item.data?.address}?tokenId=${tokenId}`}>{tokenIdText}</Link>]</Text>)}
                              {longTokenId && (
                              <Tooltip
                              placement='top'
                              title={isCopied ? 'Copied' : 'Copy TokenID to clipboard'}>
                                <CopyToClipboard
                                  text={String(tokenId)}
                                  onCopy={() => setCopied(true)}
                                  //@ts-ignore
                                  onMouseEnter={() => setCopied(false)}>
                                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                                </CopyToClipboard>
                              </Tooltip>
                              )}

                          </ValueRow>
                        )
                      })
                    }
                  </TxtColStyle>
                </Col>
              </Row>
            }
            {/* /more detail swap */}

            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TxtColStyle className="col-txt-left">
                  Value
                  <Tooltip
                    placement='top'
                    title="The value being transacted in Ether and fiat value."
                    >
                      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                </TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TxtColStyle className="col-txt-right d-flex align-items-center">
                  <Text strong>{formatNumber(toTomo(data.value, 18), 7)}</Text> <img height="16" style={{ margin: '0 3px 0 10px' }} src={(TokenTomo)} alt="VIC" /><Link to="">VIC</Link> <Text type='secondary'>(${formatNumber(toTomo(valueInUsd, 18), 2)})</Text>
                  <Divider type='vertical' />
                  VIC price: ${formatNumber(data.TOMOPrice)}
                </TxtColStyle>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TxtColStyle className="col-txt-left">
                  Fee
                  <Tooltip
                    placement='top'
                    title="Amount paid to process the transaction in Ether and fiat value."
                    >
                      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                </TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TxtColStyle className="col-txt-right">
                  {formatNumber(toTomo(data.fee, 18))} VIC
                </TxtColStyle>
              </Col>
            </Row>

            {data.fee > 0 &&
                        <Row gutter={24}>
                        <Col span={24} md={5} lg={4}>
                          <TxtColStyle className="col-txt-left">
                            Fee Payer
                            <Tooltip
                              placement='top'
                              title="The party who paying transaction fee."
                              >
                                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                              </Tooltip>
                          </TxtColStyle>
                        </Col>
                        <Col span={24} md={19} lg={20}>
                          <TxtColStyle className="col-txt-right">
                            <Link to={`/address/${feePayer}`}>{feePayer}</Link>
                            <Tooltip
                              placement='top'
                              title={isCopied ? 'Copied' : 'Copy FeePayer address to clipboard'}
                            >
                              <CopyToClipboard
                                text={String(feePayer)}
                                onCopy={() => setCopied(true)}
                                //@ts-ignore
                                onMouseEnter={() => setCopied(false)}
                              >
                                <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                              </CopyToClipboard>
                            </Tooltip>
                          </TxtColStyle>
                        </Col>
                      </Row>
            }

            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TxtColStyle className="col-txt-left">
                  Gas Price
                  <Tooltip
                    placement='top'
                    title="Cost per unit of gas spent for the transaction, in VIC and Gwei."
                    >
                      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                </TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TxtColStyle className="col-txt-right">
                  {formatNumber(toTomo(data.gasPrice, 9))} Gwei &nbsp;
                  <Text type="secondary">({formatNumber(toTomo(data.gasPrice, 18), 18)} VIC)</Text>
                </TxtColStyle>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TxtColStyle className="col-txt-left">
                Gas Limit & Usage
                  <Tooltip
                    placement='top'
                    title="Maximum amount of gas allocated for the transaction & the amount eventually used. Normal VIC transfers involve 21,000 gas units while contracts involve higher values."
                    >
                      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                </TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TxtColStyle className="col-txt-right">
                  {formatNumber(data.gas)} <Divider type='vertical' /> {formatNumber(data.gasUsed)}
                  <TextOverlay style={{ marginLeft: '5px' }}>({formatNumber(data.gasUsed * 100 /data.gas, 2)}%)</TextOverlay>
                </TxtColStyle>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TxtColStyle className="col-txt-left">
                  Nonce
                  <Tooltip
                    placement='top'
                    title="The nonce number of account in this transaction."
                    >
                      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                  <Divider type='vertical' />
                  Position
                  <Tooltip
                    placement='top'
                    title="The position of the transaction in the block."
                    >
                      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                </TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TxtColStyle className="col-txt-right">
                  {data.nonce} <Divider type='vertical' /> <Text type="secondary">{data.transactionIndex}</Text>
                </TxtColStyle>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} md={5} lg={4}>
                <TxtColStyle className="col-txt-left">
                  Input data
                  <Tooltip
                    placement='top'
                    title="Additional data included for this transaction. Commonly used as part of contract interaction or as a message sent to the recipient."
                    >
                      <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                    </Tooltip>
                </TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={20}>
                <TxDetailsInputData data={data} />
              </Col>
            </Row>

          </TabPane>

          <TabPane tab="Internal Txs" key="internalTxs">
            <TextInfoTable>
              The contract call <strong>From</strong>
              <div style={{ maxWidth: '200px' }}><AddressLink address={data.from} /></div>
              <strong>To</strong>
              <div style={{ maxWidth: '200px' }}><AddressLink address={data.to} /></div>
              produced {internalTx.data.length} Internal Transaction
            </TextInfoTable>
            <Datatable
              loading={internalTx.loading}
              columns={columns}
              dataSource={internalTx.data}
              getData={getInternalTxData}
            />
          </TabPane>

          {(data.logs.length > 0) &&
            <TabPane tab={`Logs (${data.logs.length})`} key="logs">
              <TransactionDetailLogs data={data.logs} />
            </TabPane>
          }
        </TabsWithHash>
      </TabStyle>
    </MainDetailsTransfer>
  )
}

export function TxDetailsInputData({ data }: { data: null | TxDetailsType }) {
  const [isDecode, setIsDecode] = useState(false)

  function toggleDecode() {
    setIsDecode(!isDecode)
  }

  if (!data) return null

  if (data.encodedInput &&
    data.decodedInput &&
    (Object.keys(data.encodedInput).length > 0) &&
    (Object.keys(data.decodedInput).length > 0)
  ) {
    const params = data.decodedInput.params.map((item: any) => `${item.type} ${item.name}`)
    const inputDataSource = data.decodedInput.params.map((item: any, index: number) => {
      const inputData = data.decodedInput.data[item.name]

      return { ...item, index, data: inputData }
    })
    return (
      <>
        <TxtColStyle className="col-txt-right bg-box">
          {!isDecode &&
            <>
              <ValueRow>Function: {data.decodedInput.name}({params.join(', ')})</ValueRow>
              <ValueRow>MethodId: {data.encodedInput.methodId}</ValueRow>
              {
              data.encodedInput.data.map((item, index) => {
                  return (
                    <ValueRow key={index}>[{index}]: {item}</ValueRow>
                  )
                })
              }
            </>
          }

          {isDecode &&
            <Datatable
              dataSource={inputDataSource}
              columns={inputDataColumns}
            />
          }
        </TxtColStyle>

        <TxtColStyle className="col-txt-right">
          <Button className="btn-switch-decode" onClick={toggleDecode}>{isDecode ? "Switch Back" : "Decode Input Data"}</Button>
        </TxtColStyle>
      </>
    )
  }

  return (
    <TxtColStyle className="col-txt-right bg-box" style={{maxHeight: '400px', overflowY:'auto'}}>
      <p>{data.input}</p>
    </TxtColStyle>
  )
}

const columns = [
  // {
  //   title: 'Type Trace Address',
  //   key: 'trace',
  //   dataIndex: 'trace',
  //   ellipsis: true,
  //   width: 200,
  // },
  {
    title: 'From',
    key: 'from',
    dataIndex: 'from',
    ellipsis: true,
    render: (from: string) =>
      <Link to={`/address/${from}`}>{from}</Link>
  },
  {
    title: 'To',
    key: 'to',
    dataIndex: 'to',
    ellipsis: true,
    render: (to: string) =>
      <Link to={`/address/${to}`}>{to}</Link>
  },
  {
    title: 'Value (VIC)',
    key: 'value',
    dataIndex: 'value',
    ellipsis: true,
    render: (value: number) => <>{formatNumber(toTomo(value, 18), 7)}</>,
  },
  // {
  //   title: 'Gas Limit',
  //   key: 'gas',
  //   dataIndex: 'gas',
  // },
]

const inputDataColumns = [
  {
    title: '#',
    key: 'index',
    dataIndex: 'index',
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
  },
  {
    title: 'Data',
    key: 'data',
    dataIndex: 'data',
    render: (data: Array<string>, record: any) =>
      Array.isArray(data) && data.length > 0 ?
          data.map((value:string) => {
            return <Link to={`/address/${value}`}>{value} <br></br></Link>
          })
        : record.type === 'address' ? <Link to={`/address/${data}`}>{data}</Link> : <Text>{data?.toLocaleString()}</Text>
  }
]
