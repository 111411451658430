import { FunctionComponent } from 'react'
import { formatNumber } from '../../utils'
import { Space, Typography } from 'antd'
import { MAX_RECORD } from '../../constants'
import { TextSmall } from './dataTableStyles'

const { Text } = Typography

type DataTableTotalProps = {
  total: null | number
  type: string
}

const DataTableTotal: FunctionComponent<DataTableTotalProps> = ({ total, type }) => {
  if (!total || !type) return null
  const MAX_RECORD_ABBR = MAX_RECORD / 1000

  switch (true) {
    case (type === 'tx'):
      return (
        <Space>
          <Text type="secondary">
            Total <Text>{total && formatNumber(total)}</Text> {total && total > 1 ? 'transactions' : 'transaction'}
            {(total > MAX_RECORD) && <TextSmall type="secondary">&nbsp;(Showing the latest {MAX_RECORD_ABBR}k records)</TextSmall>}
          </Text>
        </Space>
      )

    case (type === 'block'):
      return (
        <Space>
          <Text type="secondary">Total <Text>{total && formatNumber(total)}</Text> {total && total > 1 ? 'blocks' : 'block'}
            {(total > MAX_RECORD) && <TextSmall type="secondary">&nbsp;(Showing the latest {MAX_RECORD_ABBR}k records)</TextSmall>}
          </Text>
        </Space>
      )

    case (type === 'epoch'):
      return (
        <Space>
          <Text type="secondary">Total <Text>{total && formatNumber(total)}</Text> {total && total > 1 ? 'epochs' : 'epoch'}
            {(total > MAX_RECORD) && <TextSmall type="secondary">&nbsp;(Showing the latest {MAX_RECORD_ABBR}k records)</TextSmall>}
          </Text>
        </Space>
      )

    case (type === 'epoch-reward'):
      return (
        <Space>
          <Text type="secondary">Total <Text>{total && formatNumber(total)}</Text> {total && total > 1 ? 'rewards' : 'reward'}
            {(total > MAX_RECORD) && <TextSmall type="secondary">&nbsp;(Showing the latest {MAX_RECORD_ABBR}k records)</TextSmall>}
          </Text>
        </Space>
      )

    case (type === 'token'):
      return (
        <Space>
          <Text type="secondary">Total <Text>{total && formatNumber(total)}</Text> tokens
            {(total > MAX_RECORD) && <TextSmall type="secondary">&nbsp;(Showing the latest {MAX_RECORD_ABBR}k records)</TextSmall>}
          </Text>
        </Space>
      )

    case (type === 'token-holder'):
      return (
        <Space>
          <Text type="secondary">Total <Text>{total && formatNumber(total)}</Text> {total && total > 1 ? 'holders' : 'holder'}
            {(total > MAX_RECORD) && <TextSmall type="secondary">&nbsp;(Showing the latest {MAX_RECORD_ABBR}k records)</TextSmall>}
          </Text>
        </Space>
      )

    case (type === 'masternode'):
      return (
        <Space>
          <Text type="secondary">Total <Text>{total && formatNumber(total)}</Text> masternodes</Text>
        </Space>
      )

    case (type === 'slashed-node'):
      return (
        <Space>
          <Text type="secondary">Total <Text>{total && formatNumber(total)}</Text> slashed nodes</Text>
        </Space>
      )

    case (type === 'address'):
      return (
        <Space>
          <Text type="secondary">Total <Text>{total && formatNumber(total)}</Text> accounts
            {(total > MAX_RECORD) && <TextSmall type="secondary">&nbsp;(Showing the latest {MAX_RECORD_ABBR}k records)</TextSmall>}
          </Text>
        </Space>
      )

    case (type === 'contract'):
      return (
        <Space>
          <Text type="secondary" >Total <Text>{total && formatNumber(total)}</Text> contracts
            {(total > MAX_RECORD) && <TextSmall type="secondary">&nbsp;(Showing the latest {MAX_RECORD_ABBR}k records)</TextSmall>}
          </Text>
        </Space>
      )
    case (type === 'vrc2021'):
      return (
        <Space>
          <Text type="secondary">
            Total <Text>{total && formatNumber(total)}</Text> {total && total > 1 ? 'transfers' : 'transfer'}
            {(total > MAX_RECORD) && <TextSmall type="secondary">&nbsp;(Showing the latest {MAX_RECORD_ABBR}k records)</TextSmall>}
          </Text>
        </Space>
      )
    default:
      return (
        <Space>
          <Text type="secondary">Total <Text>{total && formatNumber(total)}</Text> {total && total > 1 ? 'records' : 'record'}
            {(total > MAX_RECORD) && <TextSmall type="secondary">&nbsp;(Showing the latest {MAX_RECORD_ABBR}k records)</TextSmall>}
          </Text>
        </Space>
      )
  }
}

export default DataTableTotal