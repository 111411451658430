import styled from 'styled-components'
import { Menu } from 'antd'

export const Wrapper = styled.header`
  background-color: ${({ theme }) => theme.bg2};
  .logo-size {
    height: 28px;
  }
  .ant-row {
    align-items: center;
    height:64px;
    flex-flow: nowrap;
  }
  .ml-auto {
    margin-left: auto;
  }
  .ant-menu-horizontal {
    border: 0;
    justify-content: flex-end;
    min-width: 600px;
    @media(max-width: 991px){
      min-width: 550px;
      li.ant-menu-overflow-item {
        padding: 0 15px;
      }
    }
  }
  .sp_md {
    .d-block {
      display: block;
    }
  }
  .btn-theme {
    background: transparent;
    box-shadow: 0 0 0;
    border-radius: 0px;
    border: 0;
    padding: 4px 15px;
    font-weight: bold;
    font-size: 14px;
    margin-left: 15px;
    position: relative;
    width: 100%;
    &:before {
      background: ${({ theme }) => theme.textOverlay};
      content: '';
      display: block;
      position: absolute;
      height: 20px;
      width: 1px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      @media(max-width: 767px){
        display: none;
      }
    }
    &:after {
      content: '';
      display: none;
    }
    img {
      height: 20px;
    }
  }
`

export const ContentOnPC = styled.div`
  max-width: 1232px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
  @media(max-width: 767px){
    height: 0;
    overflow: hidden;
  }
`

export const NavBar = styled.div`
  display: flex;
  align-items: center;
`

export const MenuStyled = styled(Menu)`
  background: unset;
  .ant-menu-submenu,
  .ant-menu-item,
  .ant-menu-item a {
    font-size: 15px;
    color: ${({ theme }) => theme.text};
    svg {
      width: 12px;
    }
    &:after {
      display: none;
    }
  }
  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    &:after {
      display: none;
    }
  }
`

//SP
export const ContentOnSP = styled.div`
  display: none;
  padding: 0 15px;
  @media(max-width: 767px){
    display: block;
  }
  .btn-group {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.text};
    font-size: 20px;
  }
`
export const MenuMobile = styled(Menu)`
  padding: 10px 0;
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    width: 180px;
    padding: 10px 25px;
  }
`