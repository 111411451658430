import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Typography, Row, Col } from 'antd';

import { TxDetailsLogType } from '../../constants/types';
import { TxtColStyle, BoxItemLog, ButtonType, DataType, LoadMoreBtn } from './transactionDetailsPageStyles';

const { Paragraph } = Typography;
const LIMIT = 5

interface TransactionDetailLogsProps {
  data: TxDetailsLogType[];
}

const TopicRender = ({ log }: any) => {

  const [topicType, setTopicType] = useState('hex')

  const onClickType = (e: any) => {
    const value = e.target.value
    setTopicType(value)
  }
  return (
    <TxtColStyle className="col-txt-right bg-box">
      {log.decodedTopics &&
        <DataType>
          <ButtonType value="hex" onClick={onClickType} className={topicType === 'hex' ? 'active' : ''}>Hex</ButtonType>
          <ButtonType value="dec" onClick={onClickType} className={topicType === 'dec' ? 'active' : ''}>Dec</ButtonType>
        </DataType>
      }
      <div className="box-hex-dex">
        {log.decodedTopics && topicType === 'dec' ?
          log.decodedTopics.map((t: any, i: any) => (
            <div className="items-topics" key={i}>
              {i + 1}. {t}
            </div>
          ))
          :
          log.topics.map((topic: any, topicIdx: number) => (
            <div className="items-topics" key={topicIdx}>
              {topicIdx + 1}. {topic}
            </div>
          ))
        }
      </div>
    </TxtColStyle>
  )
}

const DataRender = ({ log }: any) => {
  const [topicType, setTopicType] = useState('hex')

  const onClickType = (e: any) => {
    const value = e.target.value
    setTopicType(value)
  }
  let newParam: Array<any> = []
  if (log.params && log.params.length > 0) {
    log.params.forEach((p: any) => {
      if (!p.indexed) {
        newParam.push(p.name)
      }
    })
  }
  return (
    <TxtColStyle className="col-txt-right bg-box">
      {log.decodedData &&
        <DataType>
          <ButtonType value="hex" onClick={onClickType} className={topicType === 'hex' ? 'active' : ''}>Hex</ButtonType>
          <ButtonType value="dec" onClick={onClickType} className={topicType === 'dec' ? 'active' : ''}>Dec</ButtonType>
        </DataType>
      }
      {log.decodedData && topicType === 'dec' ?
        newParam.map((p: any, i: any) => (
          <Paragraph key={i}>
            {p}: {log.decodedData[p]}
          </Paragraph>
        ))
        :
        log.data
      }
    </TxtColStyle>
  )
}
function TransactionDetailLogs({ data }: TransactionDetailLogsProps) {
  const [offset, setOffset] = useState(0)
  const [logs, setLogs] = useState<Array<any>>([])

  function onLoadMore() {
    setOffset(offset + LIMIT)
  }

  useEffect(() => {
    setLogs([...logs, ...data.slice(offset, offset + LIMIT)])
  }, [offset])

  return (
    <>
      {logs.map((log, logIdx) => {
        const getParams = log.params && log.params.reduce((acc: any, current: any, index: any, logArr: any) => {
          const indexed = current.indexed ? `index_topic ${current.type} ${current.name}` : ` ${current.type} ${current.name}`
          return index === logArr.length - 1 ? acc + ' ' + indexed : acc + ' ' + indexed + ','
        }, '')
        return (
          <BoxItemLog key={logIdx}>
            <Row gutter={24}>
              <Col span={24} md={5} lg={6}>
                <TxtColStyle className="col-txt-left">Address</TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={18}>
                <TxtColStyle className="col-txt-right d-flex align-items-center">
                  <Link to={`/address/${log.address}`}>{log.address}</Link>
                </TxtColStyle>
              </Col>
            </Row>
            {log.functionName &&
              <Row gutter={24}>
                <Col span={24} md={5} lg={6}>
                  <TxtColStyle className="col-txt-left">Name</TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={18}>
                  <TxtColStyle className="col-txt-right">
                    {`${log.functionName} (${getParams})`} &nbsp;
                    <Link to={`/address/${log.address}`}>View code</Link>
                  </TxtColStyle>
                </Col>
              </Row>
            }

            <Row gutter={24}>
              <Col span={24} md={5} lg={6}>
                <TxtColStyle className="col-txt-left">Topics</TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={18}>
                {log.topics.length > 0 &&
                  <TopicRender log={log} />
                }
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} md={5} lg={6}>
                <TxtColStyle className="col-txt-left">Data</TxtColStyle>
              </Col>
              <Col span={24} md={19} lg={18}>
                {log.data.length > 0 && <DataRender log={log} />}
              </Col>
            </Row>
          </BoxItemLog>
        );
      })}

      <div style={{textAlign: 'center'}}>
        {((data.length > LIMIT) && (logs.length < data.length)) && <LoadMoreBtn onClick={onLoadMore}>Load More</LoadMoreBtn>}
      </div>
    </>
  );
}

export default TransactionDetailLogs;
