import { searchD3, searchOneId } from "../api";

export async function buildOneIdSuggestions(searchString: string): Promise<Array<{ address: string, oneId: string }>> {
  try {
    const response = await searchOneId(searchString)
    if (response?.data) {
      let result: Array<{ address: string, oneId: string }> = response?.data
      return result
    }

  } catch (err) {
  }
  return []
}

export async function buildD3Domain(searchString: string): Promise<Array<{ address: string, domainName: string }>> {
  const tldSupport = ['vic'];

  try {
    if (!searchString) throw new Error('Empty search text');
    const id = searchString.trim().toLowerCase();
    const [, tldName] = id.split('.');
    const isSupport = tldSupport.includes(tldName);
    if (!isSupport) throw new Error("TLD Name no Supported");
    const domain = `_web3connect.${id}.vana`;

    const response = await searchD3(domain);
    const isSuccess = response.status === 200;
    if (!isSuccess) throw new Error('API Error');

    const result = response.data.Answer.find((v: any) => v.name === domain);
    const [, address] = JSON.parse(result.data).split('=');
    if (!address) throw new Error('TLD Name no resolve');

    return [{ domainName: id, address }];
  } catch (err) {

  }
  return []
}