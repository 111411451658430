import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { PageHeader, Datatable, DataTableTotal, contractColumns } from '../../common'
import { getContractList, resetContractList } from './contractsSlice'

export default function BlockListPage() {
  const dispatch = useAppDispatch()
  const { data, loading, total } = useAppSelector((state) => state['contracts']['list'])
  // reset state when component unmount
  useEffect(() => {
    return () => {
      dispatch(resetContractList())
    }
  }, [])

  return (
    <div>
      <PageHeader title="Verified Contracts" />

      <Datatable
        loading={loading}
        total={total}
        columns={contractColumns}
        dataSource={data}
        header={<DataTableTotal total={total} type="contract" />}
        getData={getContractList}
      />
    </div>
  )
}
