import styled from 'styled-components'
import { Tooltip } from 'antd'
import { FileTextOutlined } from '@ant-design/icons'

export default function ContractIconTooltip(props: any) {
  return (
    <Tooltip title="Contract">
      <IconColor>
        <FileTextOutlined  {...props} />
      </IconColor>
    </Tooltip>
  )
}

const IconColor = styled.span`
  color: ${({theme})=> theme.textOverlay};
`