import { Helmet } from 'react-helmet-async'
import { PageHeader } from '../../common'
import { useParams, Link } from 'react-router-dom'
import {
  Tabs,
  Row,
  Col,
  Space,
} from 'antd'

import {
  MainDetails,
  BoxContentTable,
  BoxContentPlaceHolder,
  TitleHeaderBox,
  InnerPlaceHolder,
  TxtColStyle,
  TextOverlay
} from './epochDetailsPageStyles'

import { timeFormatUtc, formatNumber, toTomo } from '../../utils'
import { useStateDetails } from '../../app/hooks'
import { EpochParamsType } from '../../constants/types'
import { SkeletonLoading } from '../../common'

import { getEpochDetailsByNumber } from './epochsSlice'
import EpochDetailsReward from './EpochDetailsReward'
import EpochDetailsSlashedNode from './EpochDetailsSlashedNode'
import { TabStyle } from '../../appStyles'
import TabsWithHash from '../../common/TabsWithHash';

const { TabPane } = Tabs

export default function EpochDetailsPage() {
  const { epochNumber } = useParams<EpochParamsType>()
  const { loading, data } = useStateDetails(getEpochDetailsByNumber, epochNumber, 'epochs')
  if (loading === 'pending' || !data) return <SkeletonLoading />

  return (
    <MainDetails>
      {epochNumber &&
        <Helmet>
          <title>{`Epoch ${epochNumber}`}</title>
        </Helmet>
      }
      <PageHeader title="Epoch Details" />

      <Row gutter={24}>
        <Col span={24} md={12} className="rp-mb">
          <BoxContentPlaceHolder>
            <TitleHeaderBox>Overview</TitleHeaderBox>
            <InnerPlaceHolder>
              <Row gutter={24}>
                <Col span={24} md={5} lg={6}>
                  <TxtColStyle className="col-txt-left">
                    Epoch
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={18}>
                  <TxtColStyle className="col-txt-right">
                    {data.epoch}
                  </TxtColStyle>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24} md={5} lg={6}>
                  <TxtColStyle className="col-txt-left">
                    Start block
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={18}>
                  <TxtColStyle className="col-txt-right">
                    <Space>
                      <Link to={`/block/${data.startBlock}`}>{data.startBlock}</Link>
                      <TextOverlay>({timeFormatUtc(data.startTime)})</TextOverlay>
                    </Space>
                  </TxtColStyle>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24} md={5} lg={6}>
                  <TxtColStyle className="col-txt-left">
                    End block
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={18}>
                  <TxtColStyle className="col-txt-right">
                    <Space>
                      <Link to={`/block/${data.endBlock}`}>{data.endBlock}</Link>
                      <TextOverlay>({timeFormatUtc(data.endTime)})</TextOverlay>
                    </Space>
                  </TxtColStyle>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24} md={5} lg={6}>
                  <TxtColStyle className="col-txt-left">
                    Epoch duration
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={18}>
                  <TxtColStyle className="col-txt-right">
                    {formatNumber(data.duration)} seconds
                  </TxtColStyle>
                </Col>
              </Row>
            </InnerPlaceHolder>
          </BoxContentPlaceHolder>
        </Col>
        <Col span={24} md={12}>
          <BoxContentPlaceHolder>
            <TitleHeaderBox>More information</TitleHeaderBox>
            <InnerPlaceHolder>
              <Row gutter={24}>
                <Col span={24} md={5} lg={7}>
                  <TxtColStyle className="col-txt-left">
                    Total reward
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={17}>
                  <TxtColStyle className="col-txt-right">
                    <b>{formatNumber(toTomo(data.totalReward, 18))}</b> VIC
                  </TxtColStyle>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24} md={5} lg={7}>
                  <TxtColStyle className="col-txt-left">
                    Masternode number
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={17}>
                  <TxtColStyle className="col-txt-right">
                    <b>{data.masternode.length}</b> Masternode, <b>{data.slashednode.length}</b> Slashed Node
                  </TxtColStyle>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24} md={5} lg={7}>
                  <TxtColStyle className="col-txt-left">
                    Voter number
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={17}>
                  <TxtColStyle className="col-txt-right">
                    {formatNumber(data.voterNumber)}
                  </TxtColStyle>
                </Col>
              </Row>
            </InnerPlaceHolder>
          </BoxContentPlaceHolder>
        </Col>
      </Row>

      <BoxContentTable>
        <TabStyle>
          <TabsWithHash type="card" tabKeys={["rewardFoundation", "slashedNode"]}>
            <TabPane tab="Reward Voter" key="default">
              <EpochDetailsReward type="voter" />
            </TabPane>

            <TabPane tab="Reward Foundation" key="rewardFoundation">
              <EpochDetailsReward type="foundation" />
            </TabPane>

            <TabPane tab="Slashed Node" key="slashedNode">
              <EpochDetailsSlashedNode epoch={data} loading={loading} />
            </TabPane>
          </TabsWithHash>
        </TabStyle>
      </BoxContentTable>
    </MainDetails>
  )
}