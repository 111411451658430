import { Row, Col, Tag, Tooltip } from 'antd'
import styled from 'styled-components'
import { TitleStyled, PageTitle, SubTitleStyled } from '../common/TypographyStyled'

import { TokenType } from '../constants/types'
import { truncateBetween } from '../utils'
import { SearchBox, TokenIcon } from './index'
import CheckStatus from './CheckStatus'


interface PageHeaderProps {
  title?: string
  token?: any
}

export function PageHeader({ title }: PageHeaderProps) {
  return (
    <Wrapper>
      <Row gutter={24} style={{ flexDirection: "row-reverse" }}>
        <Col span={24} md={12}><SearchBox /></Col>
        <Col span={24} md={12}><TitleStyled className="title-header-pages" level={2}>{title}</TitleStyled></Col>
      </Row>
    </Wrapper>
  )
}

export function PageHeaderTokenDetail({ token }: PageHeaderProps) {

  return (
    <Wrapper>
      <Row gutter={24} style={{ flexDirection: "row-reverse" }}>
        <Col span={24} md={12}><SearchBox /></Col>
        <Col span={24} md={12}>
          <PageTitle className="title-header-pages d-flex align-items-center">
            <TokenIcon address={token.address} size={64} />
            <div className='ml-1'>
              {token.name && token.symbol ?
                <SubTitleStyled className="txt-ellipsis">{`${token.name} (${token.symbol?.toUpperCase()})`}</SubTitleStyled>
                :
                <Tooltip placement="bottom" title={token.address}>
                  <AddressName>{truncateBetween(token.address.toLowerCase())}</AddressName>
                </Tooltip>
              }
              <div style={{fontSize: '16px', fontWeight: 400, marginTop: '4px'}} className='d-flex align-items-center'>
                {/* <TokenTypeTag type={token.type} /> */}
                <span style={{textTransform: 'uppercase'}}>{token.type}</span>
                <span style={{color: '#949494', marginLeft: '5px', marginRight:'5px'}}>&bull;</span>
                <div className='flex align-items-center'>
                  <CheckStatus tokenStatus={token}/>
                </div>
              </div>
            </div>
          </PageTitle>
        </Col>
      </Row>
    </Wrapper>
  )
}

export function TokenTypeTag({ type }: { type: TokenType | undefined }) {

  switch (true) {
    case type === 'vrc20':
      return (<TagVRC20>VRC20</TagVRC20>)
    case type === 'vrc21':
      return (<TagVRC21>VRC21</TagVRC21>)
    case type === 'vrc721' || type === 'tr721':
      return (<TagVRC721>VRC721</TagVRC721>)
    case type === 'vrc25':
      return (<TagVRC25>VRC25</TagVRC25>)
    case type === 'native':
      return (<TagNative>NATIVE</TagNative>)
    default:
      return <TagVRC20>{type}</TagVRC20>
  }
}

export const Wrapper = styled.div`
  padding: 25px 0;
  @media(max-width: 767px){
    padding-bottom: 0;
    .ant-row{
      margin-bottom: 0;
    }
  }
`
export const TagNative = styled(Tag)`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primaryText};
  border: 0;
  border-radius: 0!important;
  font-weight: 500;
  margin: 10px 10px 0 0;
  text-transform: uppercase;
`
export const TagVRC20 = styled(Tag)`
  background-color: ${({ theme }) => theme.bgout};
  color: ${({ theme }) => theme.out};
  border: 0;
  border-radius: 0!important;
  font-weight: 500;
  margin: 10px 10px 0 0;
  text-transform: uppercase;
  padding-left:15px;
  padding-right: 15px;
`
export const TagVRC21 = styled(Tag)`
background-color: ${({ theme }) => theme.bgout};
  color: ${({ theme }) => theme.out};
  border: 0;
  border-radius: 0!important;
  font-weight: 500;
  padding-left:15px;
  padding-right: 15px;
  margin: 10px 10px 0 0;
  text-transform: uppercase;
`
export const TagVRC721 = styled(Tag)`
background-color: ${({ theme }) => theme.bgout};
  color: ${({ theme }) => theme.out};
  border: 0;
  border-radius: 0!important;
  font-weight: 500;
  margin: 10px 10px 0 0;
  text-transform: uppercase;
  padding-left:15px;
  padding-right: 15px;
`
export const TagVRC725 = styled(Tag)`
background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primaryText};
  border: 0;
  border-radius: 0!important;
  font-weight: 500;
  margin: 10px 10px 0 0;
  text-transform: uppercase;
  padding-left:15px;
  padding-right: 15px;
`


export const TagVRC25 = styled(Tag)`
background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primaryText};
  border: 0;
  border-radius: 0!important;
  font-weight: 500;
  margin: 10px 10px 0 0;
  text-transform: uppercase;
  padding-left:15px;
  padding-right: 15px;
`
const AddressName = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.text};
`

export const TagWarningAddress = styled(Tag)`
  background-color: ${({ theme }) => theme.warning};
  color: ${({ theme }) => theme.redAlert};
  border: 0;
  border-radius: 0!important;
  font-weight: 500;
  margin: 10px 10px 0 0;
  text-transform: uppercase;
  padding-left:15px;
  padding-right: 15px;
`