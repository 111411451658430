import { useState, } from 'react'
import { Helmet } from 'react-helmet-async'
import { QRCode } from 'react-qr-svg'
import { useParams, Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import iconCopy from '../../assets/images/icon/icon-copy.svg'

import {
  Tabs,
  Typography,
  Row,
  Col,
  Tooltip,
  Space
} from 'antd'
import {
  QuestionCircleOutlined,
} from '@ant-design/icons'
import {
  MainDetails,
  BoxContentTable,
  BoxContentPlaceHolder,
  TitleHeaderBox,
  InnerPlaceHolder,
  TxtColStyle,
  AddressBox,
  Center,
  TextOverlay,
  TextMain,
} from './contractDetailsPageStyles'

import iconQrcode from '../../assets/images/icon/icon-qrcode.svg'

import { TokenParamsType } from '../../constants/types'
import { PageHeader, AddressLink, TabsWithHash, ExportData } from '../../common'

import { toTomo, formatNumber, timeFormatUtc } from '../../utils'

import ContractDetailsTxListTab from './ContractDetailsTxListTab'
import { TabStyle } from '../../appStyles'
import ContractEventLogs from './ContractEventLog'
import ContentOfTheContractTab from './ContentOfTheContractTab'
import TotalTokenBalance from '../accounts/TotalTokenBalance'
import ListTokenBalance from '../accounts/ListTokenBalance'
import InteralTxns from '../accounts/InternalTxns'
import VrcTokenTxns from '../accounts/VrcTokenTxns'
import UserRewards from '../accounts/UserRewards'
import CheckStatus from '../../common/CheckStatus'


const { TabPane } = Tabs
const { Paragraph, Text } = Typography

export default function ContractDetailsPage({ data }: any) {
  const [isCopied, setCopied] = useState(false)
  const [activeKey, setActiveKey] = useState('')
  const { address } = useParams<TokenParamsType>()

  if (!data) return null
  return (
    <MainDetails>
      {address &&
        <Helmet>
          <title>{`Contract ${address}`}</title>
        </Helmet>
      }
      <PageHeader title="Contract details" />
      <AddressBox>
        { <div style={{marginRight: '4px'}}>
          <CheckStatus tokenStatus={data?.tokenData} contractVerified={data.contractData?.abiCode ? true : false} isOnlyIcon/>
        </div>}
        <TextMain style={{ marginRight: '10px', wordBreak: "break-word" }}>{data.address.toLowerCase()}</TextMain>
        <Tooltip placement="top" title={isCopied ? "Copied" : "Copy address to clipboard"}>
          <CopyToClipboard
            text={data.address}
            onCopy={() => setCopied(true)}
            //@ts-ignore
            onMouseEnter={() => setCopied(false)}
          >
            {/* <StyleCopyIcon style={{ marginRight: '10px' }}><span><img width="20" src={(iconCopy)} alt="icon copy" /></span></StyleCopyIcon> */}
            <img width="20" style={{ marginRight: '10px' }} src={(iconCopy)} alt="icon copy" />
          </CopyToClipboard>
        </Tooltip>
        <Tooltip
          placement="bottom"
          color="#ffffff"
          title={
            <Center>
              <QRCode
                level="Q"
                style={{ width: 150 }}
                value={data.address}
              />
            </Center>
          }
        >
          {/* <ButtonStyled icon={<span><img width="20" src={(iconQrcode)} alt="icon QRCODE" /></span>} /> */}
          <img width="20" src={(iconQrcode)} alt="icon QRCODE" />
        </Tooltip>
      </AddressBox>

      <Row gutter={24}>
        <Col span={24} md={12} className="rp-mb">
          <BoxContentPlaceHolder>
            <TitleHeaderBox>Contract Overview</TitleHeaderBox>
            <InnerPlaceHolder>
              {data.tokenData &&
                <Row gutter={24}>
                  <Col span={24} md={5} lg={8}>
                    <TxtColStyle className="col-txt-left">
                      Name
                    </TxtColStyle>
                  </Col>
                  <Col span={24} md={19} lg={16}>
                    <TxtColStyle className="col-txt-right">
                      {data.tokenData.tokenSymbol}
                    </TxtColStyle>
                  </Col>
                </Row>
              }
              {!data.tokenData && data.contractData.contractName &&
                <Row gutter={24}>
                  <Col span={24} md={5} lg={8}>
                    <TxtColStyle className="col-txt-left">
                      Name
                    </TxtColStyle>
                  </Col>
                  <Col span={24} md={19} lg={16}>
                    <TxtColStyle className="col-txt-right">
                      {data.contractData.contractName}
                    </TxtColStyle>
                  </Col>
                </Row>
              }
              <Row gutter={24}>
                <Col span={24} md={5} lg={8}>
                  <TxtColStyle className="col-txt-left">
                    VIC Balance
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={16}>
                  <TxtColStyle className="col-txt-right">
                    <Space>
                      {formatNumber(toTomo(data.balance, 18), 2)} VIC <TextOverlay>({`$${formatNumber(data.tomoInUsd, 2)}`})</TextOverlay>
                      <Tooltip title={() => <TomoToolTip tomoPrice={data.tomoPrice} />}><QuestionCircleOutlined /></Tooltip>
                    </Space>
                  </TxtColStyle>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24} md={5} lg={8} className='d-flex align-items-center'>
                  <TxtColStyle className="col-txt-left">
                    Other VRC Token Balance
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={16}>
                  <TxtColStyle className="col-txt-right" style={{display: "flex"}}>
                    <ListTokenBalance showMore={() => setActiveKey('totalBalance')} />
                  </TxtColStyle>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24} md={5} lg={8}>
                  <TxtColStyle className="col-txt-left">
                    Transactions Count
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={16}>
                  <TxtColStyle className="col-txt-right">
                    {formatNumber(data.txCount)}
                  </TxtColStyle>
                </Col>
              </Row>
              {data.tokenData &&
                <Row gutter={24}>
                  <Col span={24} md={5} lg={8}>
                    <TxtColStyle className="col-txt-left">
                      Token Tracker
                    </TxtColStyle>
                  </Col>
                  
                  <Col span={24} md={19} lg={16}>
                    <TxtColStyle className="col-txt-right">
                      <Link to={`/token/${data.address}`}>{data.tokenData.tokenName} ({data.tokenData.tokenSymbol})</Link>
                    </TxtColStyle>
                  </Col>
                </Row>
              }
            </InnerPlaceHolder>
          </BoxContentPlaceHolder>
        </Col>
        <Col span={24} md={12}>
          <BoxContentPlaceHolder>
            <TitleHeaderBox>More information</TitleHeaderBox>
            <InnerPlaceHolder>
              <Row gutter={24}>
                <Col span={24} md={5} lg={8}>
                  <TxtColStyle className="col-txt-left">
                    Public Name Tag
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={16}>
                  <TxtColStyle className="col-txt-right">
                    {data.accountName ? data.accountName : "Not Available."}
                  </TxtColStyle>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24} md={5} lg={8}>
                  <TxtColStyle className="col-txt-left">
                    Creator
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={16}>
                  <TxtColStyle className="col-txt-right">
                    {data.contractData.createdBy && data.contractData.createdAtHash ?
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ maxWidth: '40%' }}><AddressLink address={data.contractData.createdBy} /></div>
                        <span style={{ whiteSpace: "nowrap" }}>at tx</span> <div style={{ maxWidth: '45%' }}><AddressLink address={data.contractData.createdAtHash} type="tx" /></div>
                      </div>
                      : <TextMain>Unknown</TextMain>
                    }
                  </TxtColStyle>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24} md={5} lg={8}>
                  <TxtColStyle className="col-txt-left">
                    Last transaction
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={16}>
                  <TxtColStyle className="col-txt-right">
                    {timeFormatUtc(data.timestamp)}
                  </TxtColStyle>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24} md={5} lg={8}>
                  <TxtColStyle className="col-txt-left">
                    Compiler
                  </TxtColStyle>
                </Col>
                <Col span={24} md={19} lg={16}>
                  <TxtColStyle className="col-txt-right">
                    {data.contractData.compiler ?
                      <TextMain>{data.contractData.compiler}</TextMain>
                      : <><TextMain>Unverified contract</TextMain> (<Link to={`/contracts/verify/${data.address}`}>Verify and publish</Link>)</>
                    }
                  </TxtColStyle>
                </Col>
              </Row>
            </InnerPlaceHolder>
          </BoxContentPlaceHolder>
        </Col>
      </Row>

      <BoxContentTable>
        <TabStyle>
          {/* @ts-ignore */}
          <TabsWithHash onChange={(activeKey) => setActiveKey(activeKey)}
            activeTab={activeKey}
            type="card"
            tabKeys={["internalTxns", "vrcTokenTxns", "code", "event", "userRewards", "totalBalance"]}
          >
            <TabPane tab="Transactions" key="default">
              <ExportData account={address} type="transaction" title="Transactions"/>
              <ContractDetailsTxListTab address={address} />
            </TabPane>
            <TabPane tab="Internal Txns" key="internalTxns">
              <ExportData account={address} type="internalTx" title="Internal Txns"/>
              <InteralTxns />
            </TabPane>
            <TabPane tab="Token Txns" key="vrcTokenTxns">
              <ExportData account={address} type="tokenTx" title="Token Txns"/>
              <VrcTokenTxns />
            </TabPane>
            <TabPane tab={
              <><Text style={{color: "#8B8B8B", marginRight: '4px'}}>Contract</Text><CheckStatus tokenStatus={data?.tokenData} contractVerified={data.contractData?.abiCode ? true : false} isOnlyIcon/></>
            } key="code">
              {address && <ContentOfTheContractTab data={data} address={address} />}
            </TabPane>
            <TabPane tab="Event" key="event">
              {address && <ContractEventLogs address={address} />}
            </TabPane>
            <TabPane tab="Rewards" key="userRewards">
                <ExportData account={data.address} type="userRewards" title="Rewards"/>
                <UserRewards />
              </TabPane>
            <TabPane tab="Total Balance" key="totalBalance">
              <TotalTokenBalance />
            </TabPane>
          </TabsWithHash>
        </TabStyle>
      </BoxContentTable>
    </MainDetails >
  )
}

const TomoToolTip = ({ tomoPrice }: any) => {
  return (
    <>
      <Paragraph style={{ color: "#fff", textAlign: "center", fontSize: "11px", marginBottom: "0px" }}>
        {`$${formatNumber(tomoPrice, 2)}/VIC`}
      </Paragraph>
      <Paragraph style={{ color: "#fff", textAlign: "center", fontSize: "11px", marginBottom: "0px" }}>
        Price oracle from &nbsp;
        <a href="https://www.coingecko.com/en/coins/tomochain" target="blank">Coingecko</a>
      </Paragraph>
    </>
  )
}