import React, { useEffect, Fragment, ReactNode } from 'react'
import { withRouter } from 'react-router-dom'
import { History } from 'history';

type ScrollToTopProps = {
  history: History
  children?: ReactNode
}
function ScrollToTop({ history, children }: ScrollToTopProps) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [])

  return <Fragment>{children}</Fragment>
}

export default withRouter(ScrollToTop)