import styled from 'styled-components';

export const Layout = styled.div.attrs(props => ({
  className: 'd-flex flex-column h-100',
}))`
  background-color: ${({ theme }) => theme.bg};
`
export const TabStyle = styled.div`
  background-color:${({ theme }) => theme.bg2};
  padding: 24px;
  @media(max-width: 767px){
    padding: 15px;
  }
  .ant-tabs-nav:before{
    // border-bottom: none!important;
  }
  .ant-tabs-nav-list{
    gap: 32px;
  }
  .ant-tabs-tab {
    border: 0!important;
    color: ${({ theme }) => theme.textOverlay}!important;
    padding: 5px 0!important;
    background-color: transparent!important;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 1px solid !important;
    border-color: transparent !important;

    &.ant-tabs-tab-active{
      border-color: #000 !important;
      *{
        color: #000!important;
      }
    }    
  }
  .ant-tabs-content-holder{
    .ant-tabs-nav:before{
      border-bottom: none!important;
    }
    .ant-tabs-nav-list{
      background-color: transparent;
      background: ${({ theme }) => theme.bg};
      padding: 2px;
      gap:0;
      .ant-tabs-tab{
        font-weight: 400;
        // border-bottom: 1px solid ${({ theme }) => theme.border}!important;
        border: 0 !important;
        padding: 5px 24px!important;
        text-transform: none;

        &.ant-tabs-tab-active{
          background: ${({ theme }) => theme.primary}!important;
          *{
            color: #fff!important;
          }
        }    
      }
    }
  }
  
  .col-txt-left{
    color: ${({ theme }) => theme.textOverlay}
  }
  @media(max-width: 767px){
    padding: 15px;
    .ant-tabs-tab{
      font-size: 14px;
    }
  }
`