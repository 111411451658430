import { CheckCircleFilled, SearchOutlined } from '@ant-design/icons'
import { Layout, Tabs } from 'antd'
import { ChangeEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ButtonSearch,
  FormItemStyled,
  FormStyled,
  InputGroupStyled,
  InputStyled,
  ListHeader,
  ListItemStyled,
  ListStyled,
  ListWrapper,
  OneIdExtension,
  OneIdHeader,
  OneIdTitle,
  TokenExtension,
  TokenHeader,
  TokenTitle,
} from './searchBoxStyled'

import TokenIcon from '../TokenIcon'

import { useDebouncedCallback } from 'use-debounce'
import { buildD3Domain, buildOneIdSuggestions } from '../../domainServices'
import { useGeneralContext } from '../../providers/general'
import DomainNameIcon from '../DomainNameIcon'
import { TokenVerifiedIcon } from '../TypographyStyled'

const { Content } = Layout
const { TabPane } = Tabs


function buildTokenSuggestions(searchString: string, tokenList: Array<any>) {
  let tokenSuggestions = tokenList.filter((token: any) => (
    token?.name?.toLowerCase().includes(searchString.toLowerCase()) ||
    token?.symbol?.toLowerCase().includes(searchString.toLowerCase()) ||
    token?.address?.toLowerCase().includes(searchString.toLowerCase())
  ))
  tokenSuggestions = tokenSuggestions.sort((a: any, b: any) => {
    if (a?.projectVerified === b?.projectVerified) {
      return b?.contractVerified - a?.contractVerified
    } else {
      return b?.projectVerified - a?.projectVerified
    }
  })
  return tokenSuggestions
}

function SuggestionList({ data, onSelect }: { data: { tokens: Array<any>, oneIds: Array<any>, d3Domains: Array<any> }, onSelect: Function }) {
  const total = data.tokens.length + data.oneIds.length + data.d3Domains.length;
  return (
    <ListWrapper>
      <Tabs type="card" key="sub-tab">
        <TabPane tab={`All(${total})`} key="all">
          {data.oneIds.length > 0 &&
            <ListStyled
              itemLayout="horizontal"
              bordered
              header={<ListHeader>OneIDs</ListHeader>}
              dataSource={data.oneIds}
              renderItem={(item: any) => (
                <ListItemStyled onClick={() => onSelect(item.address, 'address')}>
                  <OneIdHeader>
                    <DomainNameIcon.OneID size={27} />
                    <span style={{ wordBreak: 'break-all' }}>
                      <OneIdTitle>{item.oneId}</OneIdTitle>
                      <OneIdExtension>{item.address}</OneIdExtension>
                    </span>
                  </OneIdHeader>
                </ListItemStyled>
              )}
            />
          }
          {data.tokens.length > 0 &&
            <ListStyled
              itemLayout="horizontal"
              bordered
              header={<ListHeader>Tokens</ListHeader>}
              dataSource={data.tokens}
              renderItem={(item: any) => (
                <ListItemStyled onClick={() => onSelect(item.address, 'token')}>
                  <TokenHeader>
                    <TokenIcon address={item.address.toLowerCase()} size={27} />
                    <span style={{ wordBreak: 'break-all' }}>
                      <TokenTitle>{item.name} ({item.symbol?.toUpperCase()}){item.projectVerified ? <TokenVerifiedIcon><CheckCircleFilled /></TokenVerifiedIcon> : ""}</TokenTitle>
                      <TokenExtension>{item.address}</TokenExtension>
                    </span>
                  </TokenHeader>
                </ListItemStyled>
              )}
            />
          }
          {data.d3Domains.length > 0 && (
            <ListStyled
              itemLayout="horizontal"
              bordered
              header={<ListHeader>D3</ListHeader>}
              dataSource={data.d3Domains}
              renderItem={(item: any) => (
                <ListItemStyled onClick={() => onSelect(item.address, 'address')}>
                  <OneIdHeader>
                    <DomainNameIcon.D3 size={27} />
                    <span style={{ wordBreak: 'break-all' }}>
                      <OneIdTitle>{item.domainName}</OneIdTitle>
                      <OneIdExtension>{item.address}</OneIdExtension>
                    </span>
                  </OneIdHeader>
                </ListItemStyled>
              )}
            />
          )}
        </TabPane>
        <TabPane tab={`Tokens(${data.tokens.length})`} key="tokens">
          <ListStyled
            itemLayout="horizontal"
            bordered
            // header={<ListHeader>Tokens</ListHeader>}
            dataSource={data.tokens}
            renderItem={(item: any) => (
              <ListItemStyled onClick={() => onSelect(item.address, 'token')}>
                <TokenHeader>
                  <TokenIcon address={item.address.toLowerCase()} size={27} />
                  <span style={{ wordBreak: 'break-all' }}>
                    <TokenTitle>{item.name} ({item.symbol?.toUpperCase()}){item.projectVerified ? <TokenVerifiedIcon><CheckCircleFilled /></TokenVerifiedIcon> : ""}</TokenTitle>
                    <TokenExtension>{item.address}</TokenExtension>
                  </span>
                </TokenHeader>
              </ListItemStyled>
            )}
          />
        </TabPane>
        <TabPane tab={`OneIDs(${data.oneIds.length})`} key="oneid">
          <ListStyled
            itemLayout="horizontal"
            bordered
            // header={<ListHeader>Tokens</ListHeader>}
            dataSource={data.oneIds}
            renderItem={(item: any) => (
              <ListItemStyled onClick={() => onSelect(item.address, 'address')}>
                <OneIdHeader>
                  <DomainNameIcon.OneID size={27} />
                  <span style={{ wordBreak: 'break-all' }}>
                    <OneIdTitle>{item.oneId}</OneIdTitle>
                    <OneIdExtension>{item.address}</OneIdExtension>
                  </span>
                </OneIdHeader>
              </ListItemStyled>
            )}
          />
        </TabPane>
        <TabPane tab={`D3(${data.d3Domains.length})`} key="d3Domain">
          <ListStyled
            itemLayout="horizontal"
            bordered
            // header={<ListHeader>Tokens</ListHeader>}
            dataSource={data.d3Domains}
            renderItem={(item: any) => (
              <ListItemStyled onClick={() => onSelect(item.address, 'address')}>
                <OneIdHeader>
                  <DomainNameIcon.D3 size={27} />
                  <span style={{ wordBreak: 'break-all' }}>
                    <OneIdTitle>{item.domainName}</OneIdTitle>
                    <OneIdExtension>{item.address}</OneIdExtension>
                  </span>
                </OneIdHeader>
              </ListItemStyled>
            )}
          />
        </TabPane>
      </Tabs>
    </ListWrapper>
  )
}

export default function SearchBox() {
  const history = useHistory()
  const { tomoTokenList } = useGeneralContext()
  const [suggestions, setSuggestions] = useState<{
    tokens: Array<any>,
    oneIds: Array<{ address: string, oneId: string }>,
    d3Domains: Array<{ address: string, domainName: string }>,
  }>({ tokens: [], oneIds: [], d3Domains: [] })
  const [search, setSearch] = useState({ searchString: '', type: '' })

  const hasResult = Object.values(suggestions).some(v => v.length !== 0);

  const handleSuggestions = useDebouncedCallback(async (keyword: string) => {
    const tokenSuggestions = buildTokenSuggestions(keyword, tomoTokenList)
    const oneIdSuggestions = await buildOneIdSuggestions(keyword)
    const d3Suggestions = await buildD3Domain(keyword);

    setSuggestions({ ...suggestions, tokens: tokenSuggestions, oneIds: oneIdSuggestions, d3Domains: d3Suggestions })
  }, 1000)

  async function handelOnChange(e: ChangeEvent<HTMLInputElement>) {
    const searchString = e.target.value.trim()

    if (!searchString) return resetState()

    handleSuggestions(searchString);
    switch (true) {
      case searchString.startsWith('0x') && searchString.length === 66:
        setSearch({ searchString, type: 'tx' })
        break
      case searchString.startsWith('0x') && searchString.length === 40:
        setSearch({ searchString, type: 'address' })
        break

      case !searchString.match(/\D+/):
        setSearch({ searchString, type: 'block' })
        break

      default:
        setSearch({ searchString, type: 'address' })
        break
    }
  }

  function handleOnSearch() {
    
    if (suggestions.oneIds.length > 0) {
      history.push(`/address/${suggestions.oneIds[0].address}`)
      return resetState()
    }
    if (suggestions.tokens.length > 0) {
      history.push(`/token/${suggestions.tokens[0].address}`)
      return resetState()
    }

    if (search.searchString && search.type) {
      history.push(`/${search.type}/${search.searchString}`)
      return resetState()
    }
  }

  function handleOnSelectSuggestion(address: string, type: string) {
    history.push(`/${type}/${address}`)
  }

  function resetState() {
    setSuggestions({ tokens: [], oneIds: [], d3Domains: [] })
    setSearch({ searchString: '', type: '' })
  }

  return (
    <Content>
      <FormStyled>
        <InputGroupStyled>
          <FormItemStyled>
            <InputStyled
              placeholder="Search OneID, transactions, blocks, accounts and tokens"
              onChange={handelOnChange}
              onPressEnter={handleOnSearch}
              allowClear
            />

            {search.searchString && hasResult &&
              <SuggestionList data={suggestions} onSelect={handleOnSelectSuggestion} />
            }
          </FormItemStyled>
        </InputGroupStyled>

        <ButtonSearch icon={<SearchOutlined style={{ fontSize: '18px', margin: '5px 0' }} onClick={handleOnSearch} />} />
      </FormStyled>
    </Content>

  )

}