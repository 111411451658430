import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { EpochsState, RewardType } from '../../constants/types'
import { fetchEpochList, fetchEpochDetailsByNumber, fetchEpochDetailsReward } from '../../api'
import { requestLimit } from '../../features/general/generalSlice'

// Create thunk
export const getEpochList = createAsyncThunk(
  'epochs/getEpochList',
  async (params: {offset: number, limit: number}, thunkAPI) => {
    try {
      const { offset, limit } = params

      const response = await fetchEpochList(offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getEpochDetailsByNumber = createAsyncThunk(
  'blocks/getEpochDetailsByNumber',
  async (param: string, thunkAPI) => {
    try {
      const response = await fetchEpochDetailsByNumber(param)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getEpochDetailsReward = createAsyncThunk(
  'epochs/getEpochDetailsReward',
  async (params: {epochNumber: string, rewardType: RewardType, offset: number, limit: number}, thunkAPI) => {
    try {
      const { epochNumber, rewardType, offset, limit } = params

      const response = await fetchEpochDetailsReward(epochNumber, rewardType, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

const initialListState = {
  loading: 'idle',
  total: null,
  data: [],
}

// Declare slice
const initialState = {
  list: { 
    ...initialListState
  },
  details: {
    data: null,
    loading: 'idle',
    reward: {
      voter: {
        ...initialListState
      },
      masternode: {
        ...initialListState
      },
      foundation: {
        ...initialListState
      }
    }
  }
} as EpochsState

const epochsSlice = createSlice({
  name: 'epochs',
  initialState,
  reducers: {
    // reset block list
    resetEpochList(state) {
      state.list.data = []
      state.list.loading = 'idle'
      state.list.total = null
    },
  },
  extraReducers: (builder) => {
    // epoch list
    builder.addCase(getEpochList.pending, (state, action) => {
      state.list.loading = 'pending'
    })

    builder.addCase(getEpochList.fulfilled, (state, action) => {
      state.list.loading = 'succeeded'
      state.list.data = action.payload.data
      state.list.total = action.payload.total
    })

    builder.addCase(getEpochList.rejected, (state, action) => {
      state.list.loading = 'failed'
      state.list.data = []
      state.list.total = null
    })

    // epoch details
    builder.addCase(getEpochDetailsByNumber.pending, (state, action) => {
      state.details.loading = 'pending'
      state.details.data = null
    })
    builder.addCase(getEpochDetailsByNumber.fulfilled, (state, action) => {
      state.details.loading = 'succeeded'
      state.details.data = action.payload
    })
    builder.addCase(getEpochDetailsByNumber.rejected, (state, action) => {
      state.details.loading = 'failed'
      state.details.data = null
    })

    // epoch reward
    builder.addCase(getEpochDetailsReward.pending, (state, action) => {
      const { rewardType } = action.meta.arg   
      state.details.reward[rewardType].loading = 'pending'
    })

    builder.addCase(getEpochDetailsReward.fulfilled, (state, action) => {
      const { rewardType } = action.meta.arg
      
      state.details.reward[rewardType].loading = 'succeeded'
      state.details.reward[rewardType].data = action.payload.data
      state.details.reward[rewardType].total = action.payload.total
    })

    builder.addCase(getEpochDetailsReward.rejected, (state, action) => {
      const { rewardType } = action.meta.arg

      state.details.reward[rewardType].loading = 'failed'
      state.details.reward[rewardType].data = []
      state.details.reward[rewardType].total = null
    })
  }
})

export const { resetEpochList } = epochsSlice.actions
export default epochsSlice.reducer
