import { Skeleton, Row, Col, Divider } from 'antd'

function SkeletonLoading() {
  return (
    <div>
      <Row gutter={[32, 32]}>
        <Col span={12}><Skeleton active paragraph={{ rows: 5 }} /></Col>
        <Col span={12}><Skeleton active paragraph={{ rows: 5 }} /></Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}><Skeleton active paragraph={{ rows: 8 }} /></Col>
      </Row>
    </div>
  )
}

export default SkeletonLoading