import styled from "styled-components"
import { Button, List } from 'antd'
import { Link } from "react-router-dom"
export const MainDetails = styled.div`
  .ant-tabs-content .content-table{
    padding: 0;
  }
  @media(max-width: 767px){
    .rp-mb{
      margin-bottom: 30px;
    }
    .ant-row{
      margin-bottom: 15px;
    }    
  }
`

export const BoxContentPlaceHolder = styled.div`
    background: ${({ theme }) => theme.bg2};
    padding: 15px 25px;
    height: 100%;
    @media(max-width: 767px){
      padding-left: 15px;
      padding-right: 15px;
    }
`
export const InnerPlaceHolder = styled.div`
`
export const TxtColStyle = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    &.col-txt-left {
      color:${({ theme }) => theme.textOverlay};
    }
    &.col-txt-right {
      color:${({ theme }) => theme.text};
    }
    @media(max-width: 767px){
      margin: 0;
    }
`
export const TokenList = styled.div`
  width: 100%;
  position: relative;
`
export const TotalPrice = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.border};
  padding: 6px 10px;
  align-items: center;
  cursor: pointer;
  position: relative;
  .anticon-caret-down{
    position: absolute;
    right: 10px;
    color: ${({ theme }) => theme.textOverlay};
    top: 12px;
    font-size: 10px;
  }
`
export const TokenCount = styled.span`
  font-size: 10px;
  font-weight: 700;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  width: 15px;
  height: 15px;
  line-height: 15px;
  display: inline-block;
  text-align: center;
  margin-left: 12px;
`
export const ListStyled = styled(List)`
  border: 1px solid ${({ theme }) => theme.border};
  padding: 5px;
  position: absolute;
  width: 100%;
  top: 100%;
  background: ${({ theme }) => theme.bg2};
  z-index: 1;
  box-shadow: 0 2px 7px rgb(52 152 219 / 5%), 0 0 10px hsl(210deg 8% 46% / 10%);
  .ant-list-items {
    margin: 0 !important;
    max-height: 295px;
    overflow-y: auto;    
  }

  .ant-list-header {
    border-bottom: 1px solid ${({ theme }) => theme.border} !important;
    padding: 0 0 5px 10px;
    font-weight: 500;
    color: ${({ theme }) => theme.text};
  }
  .ant-list-item{
    border-bottom-color: ${({ theme }) => theme.border};
  }

  .ant-spin-nested-loading {
    border-radius: 0 !important;
    border: 0 !important;
  }
  .ant-empty-description{
    color: ${({ theme }) => theme.textOverlay};
  }
`

export const ListButton = styled(Button)`
  border-radius: 5px;

  &:hover {
    color: #00C59A;
    border-color: #00C59A;
  }
`

export const TokenLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2px 10px;
  :hover{
    background-color: ${({ theme }) => theme.bgHover};
  }
`
export const TextOverlay = styled.p`
  color: ${({ theme }) => theme.textOverlay};
`
export const ViewMore = styled.a`
  width: 100%;
  max-width: 36px;
  display: inline-block;
  text-align: center;
  line-height: 34px;
  margin-left: 10px;
  color: #fff;
  border: 0;
  transition: .2s;
  background: ${({ theme }) => theme.primary};
  :hover{
    background: ${({ theme }) => theme.primaryHover};
    color: #fff;
  }
`
export const BoxContentTable = styled.div`
    margin-top: 25px;
    position: relative;
`
export const TitleHeaderBox = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
    color: ${({ theme }) => theme.text};
    border-bottom: 1px solid ${({ theme }) => theme.border};
    padding-bottom: 15px;
`
export const TextStyle = styled.span`
  color: ${({ theme }) => theme.textOverlay};
`
export const TextMainStyle = styled.span`
  color: ${({ theme }) => theme.text};
`
export const AddressBox = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: -15px;
`
export const StyleCopyIcon = styled.div`
    cursor: pointer;
    border-radius: 0;
    border: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.textOverlay};
    background-color: ${({ theme }) => theme.bg2};
    &:hover {
        background-color: ${({ theme }) => theme.bgHover};
    }
    > *{
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
    }
`
export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`
export const ButtonStyled = styled(Button)`
  border-radius: 0;
  &.ant-btn,
  &.ant-btn:hover,
  &.ant-btn:focus {
    border: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.textOverlay};
    background-color: ${({ theme }) => theme.bg2};
  }
  &.ant-btn:hover {
    background-color: ${({ theme }) => theme.bgHover};
  }
  > *{
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
  }
`
