import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'

import { TokensState } from '../../constants/types'
import {
  fetchNormalTokenList,
  fetchVrc721TokenList,
  fetchTokenDetailsByAddress,
  fetchVrc2021HolderList,
  fetchVrc721HolderList,
  fetchVrc2021TransferList,
  fetchVrc721TransferList,
  fetchVrc721InventoryList,
  // fetchTokenBalance,
  fetchTokenBalanceOnAccount
} from '../../api'
import { requestLimit } from '../../features/general/generalSlice'

// Create thunk
// vrc20/21 token list
export const getNormalTokenList = createAsyncThunk(
  'tokens/getNormalTokenList',
  async (params: { offset: number, limit: number }, thunkAPI) => {
    try {
      const { offset, limit } = params

      const response = await fetchNormalTokenList(offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// vrc721 token list
export const getVrc721TokenList = createAsyncThunk(
  'tokens/getVrc721TokenList',
  async (params: { offset: number, limit: number }, thunkAPI) => {
    try {
      const { offset, limit } = params

      const response = await fetchVrc721TokenList(offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// vrc20/21 holder list
export const getVrc2021HolderList = createAsyncThunk(
  'tokens/getVrc2021HolderList',
  async (params: { address: string, offset: number, limit: number }, thunkAPI) => {
    try {
      const { address, offset, limit } = params

      const response = await fetchVrc2021HolderList(address, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// vrc721 holder list
export const getVrc721HolderList = createAsyncThunk(
  'tokens/getVrc721HolderList',
  async (params: { address: string, offset: number, limit: number }, thunkAPI) => {
    try {
      const { address, offset, limit } = params

      const response = await fetchVrc721HolderList(address, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// vrc721 inventory list
export const getVrc721InventoryList = createAsyncThunk(
  'tokens/getVrc721InventoryList',
  async (params: { tokenAddress: string, ownerAddress: string, tokenId: string, offset: number, limit: number }, thunkAPI) => {
    try {
      const { tokenAddress, ownerAddress, tokenId, offset, limit } = params

      const response = await fetchVrc721InventoryList(tokenAddress, ownerAddress, tokenId, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// vrc20/21 transfer list
export const getVrc2021TransferList = createAsyncThunk(
  'tokens/getVrc2021TransferList',
  async (params: { address: string, account: string | undefined, offset: number, limit: number }, thunkAPI) => {
    try {
      const { address, account, offset, limit } = params
      const response = await fetchVrc2021TransferList(address, account, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// vrc721 transfer list
export const getVrc721TransferList = createAsyncThunk(
  'tokens/getVrc721TransferList',
  async (params: { tokenAddress: string, ownerAddress: string | undefined, tokenId: string | undefined, offset: number, limit: number }, thunkAPI) => {
    try {
      const { tokenAddress, ownerAddress, tokenId, offset, limit } = params

      const response = await fetchVrc721TransferList(tokenAddress, ownerAddress, tokenId, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// token details
export const getTokenDetailsByAddress = createAsyncThunk(
  'tokens/getTokenDetailsByAddress',
  async (address: string, thunkAPI) => {
    try {
      const response = await fetchTokenDetailsByAddress(address)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)
// token details
export const getTokenBalanceOnAccount = createAsyncThunk(
  'tokens/getTokenBalanceOnAccount',
  async (params:{owner: string,address: string}, thunkAPI) => {
    try {
      const { owner,address  } = params
      const response = await fetchTokenBalanceOnAccount(owner,address )
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// Declare slice
const initialState = {
  list: {
    vrc2021: {
      data: [],
      loading: 'idle',
      total: null,
    },
    vrc721: {
      data: [],
      loading: 'idle',
      total: null,
    }
  },
  details: {
    data: null,
    loading: 'idle',
    holders: {
      vrc2021: {
        data: [],
        loading: 'idle',
        total: null,
      },
      vrc721: {
        data: [],
        loading: 'idle',
        total: null,
      },
    },
    transfers: {
      vrc2021: {
        data: [],
        loading: 'idle',
        total: null,
      },
      vrc721: {
        data: [],
        loading: 'idle',
        total: null,
      },
    },
    inventory: {
      data: [],
      loading: 'idle',
      total: 0,
    },
    tokenBalanceOnAccount: {
      loading: 'idle',
      data: {
        isNft: false,
        symbol: '',
        decimals: null,
        balance: null,
        price: null
      }      
    }
  }
} as TokensState

const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    // reset token list
    resetVrc2021List(state) {
      state.list.vrc2021 = { ...initialState.list.vrc2021 }
    },

    resetVrc721List(state) {
      state.list.vrc721 = { ...initialState.list.vrc721 }
    }
  },
  extraReducers: (builder) => {
    // vrc20/21 token list
    builder.addCase(getNormalTokenList.pending, (state, action) => {
      state.list.vrc2021.loading = 'pending'
    })

    builder.addCase(getNormalTokenList.fulfilled, (state, action) => {
      state.list.vrc2021.loading = 'succeeded'
      state.list.vrc2021.data = action.payload.data
      state.list.vrc2021.total = action.payload.total
    })

    builder.addCase(getNormalTokenList.rejected, (state, action) => {
      state.list.vrc2021.loading = 'failed'
      state.list.vrc2021.data = []
      state.list.vrc2021.total = null
    })

    // vrc721 token list
    builder.addCase(getVrc721TokenList.pending, (state, action) => {
      state.list.vrc721.loading = 'pending'
    })

    builder.addCase(getVrc721TokenList.fulfilled, (state, action) => {
      state.list.vrc721.loading = 'succeeded'
      state.list.vrc721.data = action.payload.data
      state.list.vrc721.total = action.payload.total
    })

    builder.addCase(getVrc721TokenList.rejected, (state, action) => {
      state.list.vrc721.loading = 'failed'
      state.list.vrc721.data = []
      state.list.vrc721.total = null
    })

    // vrc20/21 holder list
    builder.addCase(getVrc2021HolderList.pending, (state, action) => {
      state.details.holders.vrc2021.loading = 'pending'
    })

    builder.addCase(getVrc2021HolderList.fulfilled, (state, action) => {
      state.details.holders.vrc2021.loading = 'succeeded'
      state.details.holders.vrc2021.data = action.payload.data
      state.details.holders.vrc2021.total = action.payload.total
    })

    builder.addCase(getVrc2021HolderList.rejected, (state, action) => {
      state.details.holders.vrc2021.loading = 'failed'
      state.details.holders.vrc2021.data = []
      state.details.holders.vrc2021.total = null
    })

    // vrc721 holder list
    builder.addCase(getVrc721HolderList.pending, (state, action) => {
      state.details.holders.vrc721.loading = 'pending'
    })

    builder.addCase(getVrc721HolderList.fulfilled, (state, action) => {
      state.details.holders.vrc721.loading = 'succeeded'
      state.details.holders.vrc721.data = action.payload.data
      state.details.holders.vrc721.total = action.payload.total
    })

    builder.addCase(getVrc721HolderList.rejected, (state, action) => {
      state.details.holders.vrc721.loading = 'failed'
      state.details.holders.vrc721.data = []
      state.details.holders.vrc721.total = null
    })

    // vrc721 inventory list
    builder.addCase(getVrc721InventoryList.pending, (state, action) => {
      state.details.inventory.loading = 'pending'
    })

    builder.addCase(getVrc721InventoryList.fulfilled, (state, action) => {
      state.details.inventory.loading = 'succeeded'
      state.details.inventory.data = action.payload.data
      state.details.inventory.total = action.payload.total
    })

    builder.addCase(getVrc721InventoryList.rejected, (state, action) => {
      state.details.inventory.loading = 'failed'
      state.details.inventory.data = []
      state.details.inventory.total = 0
    })

    // vrc20/21 transfer list
    builder.addCase(getVrc2021TransferList.pending, (state, action) => {
      state.details.transfers.vrc2021.loading = 'pending'
    })

    builder.addCase(getVrc2021TransferList.fulfilled, (state, action) => {
      state.details.transfers.vrc2021.loading = 'succeeded'
      state.details.transfers.vrc2021.data = action.payload.data
      state.details.transfers.vrc2021.total = action.payload.total
    })

    builder.addCase(getVrc2021TransferList.rejected, (state, action) => {
      state.details.transfers.vrc2021.loading = 'failed'
      state.details.transfers.vrc2021.data = []
      state.details.transfers.vrc2021.total = null
    })

    // vrc721 transfer list
    builder.addCase(getVrc721TransferList.pending, (state, action) => {
      state.details.transfers.vrc721.loading = 'pending'
    })

    builder.addCase(getVrc721TransferList.fulfilled, (state, action) => {
      state.details.transfers.vrc721.loading = 'succeeded'
      state.details.transfers.vrc721.data = action.payload.data
      state.details.transfers.vrc721.total = action.payload.total
    })

    builder.addCase(getVrc721TransferList.rejected, (state, action) => {
      state.details.transfers.vrc721.loading = 'failed'
      state.details.transfers.vrc721.data = []
      state.details.transfers.vrc721.total = null
    })

    // token details
    builder.addCase(getTokenDetailsByAddress.pending, (state, action) => {
      state.details.loading = 'pending'
      state.details.data = null
    })
    builder.addCase(getTokenDetailsByAddress.fulfilled, (state, action) => {
      const { price, totalSupplyNumber } = action.payload
      const fullyDilutedMarketcap = (price && totalSupplyNumber) ? new BigNumber(price).times(totalSupplyNumber).toNumber() : ''

      state.details.loading = 'succeeded'
      state.details.data = { ...action.payload, fullyDilutedMarketcap }
    })
    builder.addCase(getTokenDetailsByAddress.rejected, (state, action) => {
      state.details.loading = 'failed'
      state.details.data = null
    })
    // token balance on account
    builder.addCase(getTokenBalanceOnAccount.pending, (state, action) => {
      state.details.tokenBalanceOnAccount.loading = 'pending'
    })
    builder.addCase(getTokenBalanceOnAccount.fulfilled, (state, action) => {
      state.details.tokenBalanceOnAccount.data = action.payload
      state.details.tokenBalanceOnAccount.loading = 'succeeded'      
    })
    builder.addCase(getTokenBalanceOnAccount.rejected, (state, action) => {
      state.details.tokenBalanceOnAccount.loading = 'failed'
    })
  },
})

export const { resetVrc2021List, resetVrc721List } = tokensSlice.actions
export default tokensSlice.reducer
