import { useCallback } from 'react'

import { useAppSelector } from '../../app/hooks'
import { Datatable, DataTableTotal, txColumns } from '../../common'
import { getTxListByAddress } from './contractsSlice'

export default function ContractDetailsTxListTab({ address }: { address: string | undefined }) {  
  const { data, loading, total } = useAppSelector((state) => state.contracts.details.transactions)

  const getData = useCallback(
    (params: any) => getTxListByAddress({ ...params, address }),
    []
  )

  if (!address) return null

  return (
    <Datatable
      loading={loading}
      total={total}
      columns={txColumns}
      dataSource={data}
      header={<DataTableTotal total={total} type="tx" />}
      getData={getData}
    />
  )
}