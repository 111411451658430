import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { TokenParamsType } from '../../constants/types'
import { columnsTxns, Datatable, DataTableTotal } from '../../common'
import { useAppSelector } from '../../app/hooks'
import { getTokenInternalTx } from './accountsSlice'

export default function InteralTxns() {
  const { address } = useParams<TokenParamsType>()

  const getInternalTxData = useCallback(
    (params: any) => getTokenInternalTx({ ...params, address }),
    [address]
  )

  const { internalTx } = useAppSelector((state) => state['accounts']['details'])

  return (
    
    <Datatable
      loading={internalTx.loading}
      total={internalTx.total}
      columns={columnsTxns}
      dataSource={internalTx.data}
      header={<DataTableTotal total={internalTx.total} type="tx" />}
      getData={getInternalTxData}
    />
  )
}

