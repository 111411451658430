import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getMasternodeList, resetMasternodeList } from './masternodesSlice'
import { PageHeader, Datatable, masternodeColumns, DataTableTotal } from '../../common'

export default function EpochsList() {
  const dispatch = useAppDispatch()
  const { data, loading, total } = useAppSelector((state) => state['masternodes']['list'])

  // reset state when component unmount
  useEffect(() => {
    return () => {
      dispatch(resetMasternodeList())
    }
  }, [])

  return (
    <div>
      <PageHeader title="Masternodes" />
       
      <Datatable
        loading={loading}
        total={total}
        columns={masternodeColumns}
        dataSource={data}
        header={<DataTableTotal total={total} type="masternode" />}
        showPagination={false}
        getData={getMasternodeList}
      />
    </div>
  )
}