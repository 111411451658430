import styled from 'styled-components'

export const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 0;
`
export const BoxInfo = styled.div`
  margin-top: 40px;
  .inner-countdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    .cm-after {
      display: flex;
      font-size: 28px;
      align-items: center;
      transform: translateY(-12%);
      color: ${({theme})=>theme.text};
    }
    .txt-clock{
      line-height: 1.4;
      text-align: center;
      min-width: 80px;
      span{
        display: block;
        font-weight: bold;
        font-size: 50px;
        @media(max-width: 580px){
          font-size: 40px;
        }
      }
      .smalltext{
        font-size: 14px;
      }
    }
}
`
export const TextMain = styled.span`
  color: ${({theme})=>theme.text};
`
export const TextOverlay = styled.span`
  color: ${({theme})=>theme.textOverlay};
`
export const BlockBox = styled.div`
  padding: 10px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg2};
  margin-bottom: 10px;
  > span{
    font-weight: bold;
  }
  &.active{
    background-color: ${({ theme }) => theme.primary};
    span{
      color: #fff;
    }    
  }
`