let TOMOSCAN_API_V2_URL: string;
let RPC_ENDPOINT: string;
let CHAINID : number;

// @ts-ignore
const env = window.env || process.env

if (env) {
  TOMOSCAN_API_V2_URL = env.REACT_APP_TOMOSCAN_API_V2_URL;
  RPC_ENDPOINT = env.REACT_APP_RPC_ENDPOINT ?? 'https://rpc.viction.xyz';
  CHAINID = Number(env.REACT_APP_CHAINID) ?? 88
}

export {
  TOMOSCAN_API_V2_URL,
  RPC_ENDPOINT,
  CHAINID
}