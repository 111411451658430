import { Tag, Button, Typography } from "antd"
import styled from "styled-components"
const {Text} = Typography
export const MainDetailsTransfer = styled.div`
    .ant-tabs-content .content-table{
        padding: 0;
    }
    @media(max-width: 767px){
        .ant-row{
        margin-bottom: 15px;
        }    
    }
`

export const TxtColStyle = styled.div`
    margin: 10px 0;
    word-break: break-all;
    &.col-txt-left {
        color: ${({ theme }) => theme.textOverlay};
      }
      &.col-txt-right {
        color: ${({ theme }) => theme.text};
        .ant-typography{
            color: ${({ theme }) => theme.text};
        }
      }
    &.bg-box {
        background-color: ${({ theme }) => theme.bg4};
        padding: 24px;
        @media(max-width: 767px){
            padding: 15px;
        }
        .style-table-bordered {
            padding: 24px;
            @media(max-width: 767px){
                padding: 15px;
            }
        }
    }
    .btn-switch-decode {
        color: ${({ theme }) => theme.primary};
        background: transparent;
        box-shadow: 0 0 0;
        border: 0;
        padding: 0;
        height: auto;
    }
    .anticon {
        transition: all .2s;
    }
    @media(max-width: 767px){
        margin: 0;
    }
    .box-hex-dex {
        .items-topics {
            margin-bottom: 7px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
`

export const ValueRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    &:last-child {
        margin-bottom: 0px;
    }
`

export const BoxItemLog = styled.div`
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${({ theme }) => theme.border};
`
export const DataType = styled.div`
    margin-bottom: 15px;
`
export const ButtonType = styled.button`
    background-color: ${({ theme }) => theme.border};
    border: none;
    padding: 5px 10px;
    height: 20px;
    line-height: 10px;
    cursor: pointer;
    &.active{
        background-color: ${({ theme }) => theme.primary};
        color: #fff;
    }
`
export const TextInfoTable = styled.div`
    color: ${({ theme }) => theme.text};
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    @media(max-width: 767px){
        margin-bottom: 0;
    }
`
export const Status = styled(Tag) <{ status: string }>`
background: ${({ theme, status }) => status === 'success' ? theme.bgsuccess : status === 'fail' ? theme.bgfail : theme.bgpending};
color: ${({ theme, status }) => theme[status]};
border: 0;
border-radius: 12px;
padding: 3px 15px;
margin: 0;
min-height: 24px;
`
export const TextOverlay = styled.span`
    color: ${({ theme }) => theme.textOverlay};
`
export const LoadMoreBtn = styled(Button)`
    color: #fff;
    font-weight: bold;
    background-color: ${({ theme }) => theme.primary};
    border-radius: 8px;
    border: none;

    &:hover,
    &: focus {
        color: #fff;
        background-color: ${({ theme }) => theme.primaryHover};
    }
`
export const StyleCopyIcon = styled.span`
    cursor: pointer;
    border-radius: 0;
    border: 0;
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.textOverlay};
    margin-left: 10px;
`
export const TimeStyle = styled(Text)`
    @media(max-width: 480px){
        display: block;
    }
`