import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { StatsType, LoadingType } from '../../constants/types'
import { fetchStats } from '../../api'

interface GeneralState {
  mode: string
  requestLimit: boolean
  stats: {
    loading: LoadingType
    data: null | StatsType
  }
}

const initialState = { 
  mode: 'light',  
  stats: {
    loading: 'idle',
    data: null,
  },
  requestLimit: false,
} as GeneralState

// Create thunk
export const getStats = createAsyncThunk(
  'general/getStats',
  async (params, thunkAPI) => {
    try {
      const response = await fetchStats()
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error)
    }
  }
)

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    // theme
    changeMode(state, action: PayloadAction<string>) {
      state.mode = action.payload
    },
    requestLimit(state, action: PayloadAction<boolean>) {
      state.requestLimit = action.payload
    },

    // reset stats
    resetStats(state) {
      state.stats.loading = 'idle'
      state.stats.data = null
    }
  },
  extraReducers: (builder) => {
    // stats
    builder.addCase(getStats.pending, (state, action) => {
      state.stats.loading = 'pending'
    })
    builder.addCase(getStats.fulfilled, (state, action) => {
      state.stats.loading = 'succeeded'
      const marketCap = new BigNumber(action.payload.price).multipliedBy(action.payload.circulatingSupply).toNumber()
      state.stats.data = { ...action.payload, marketCap }
    })
    builder.addCase(getStats.rejected, (state, action) => {
      state.stats.loading = 'failed'
      state.stats.data = null
    })
  }
})

export const { changeMode,requestLimit, resetStats } = generalSlice.actions

const persistConfig = {
  key: 'general',
  storage,
  whitelist: ['mode'],
}
export default persistReducer(persistConfig, generalSlice.reducer)