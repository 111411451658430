import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getEpochList, resetEpochList } from './epochsSlice'
import { PageHeader, Datatable, epochColumns, DataTableTotal } from '../../common'

export default function EpochsList() {
  const dispatch = useAppDispatch()
  const { data, loading, total } = useAppSelector((state) => state['epochs']['list'])

  // reset state when component unmount
  useEffect(() => {
    return () => {
      dispatch(resetEpochList())
    }
  }, [])

  return (
    <div>
      <PageHeader title="Epochs" />
       
      <Datatable
        loading={loading}
        total={total}
        columns={epochColumns}
        dataSource={data}
        header={<DataTableTotal total={total} type="epoch" />}
        getData={getEpochList}
      />
    </div>
  )
}