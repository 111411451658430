import styled from "styled-components";
import { Button, Input } from 'antd'

export const MainDetails = styled.div`
  .ant-tabs-content .content-table{
    padding: 0;
  }
  @media(max-width: 767px){
    .rp-mb{
      margin-bottom: 30px;
    }
    .ant-row{
      margin-bottom: 15px;
    }    
  }
`
export const BoxContentPlaceHolder = styled.div`
    background-color: ${({ theme }) => theme.bg2};
    padding: 15px 24px;
    height: 100%;
    @media(max-width: 767px){
      padding-left: 15px;
      padding-right: 15px;
    }
`
export const InnerPlaceHolder = styled.div`
`
export const TxtColStyle = styled.div`
    margin: 10px 0;
    word-break: break-all;
    &.col-txt-left {
      color: ${({ theme }) => theme.textOverlay};
    }
    &.col-txt-right {
      color: ${({ theme }) => theme.text};
    }
    @media(max-width: 767px){
      margin: 0;
    }
`
export const BoxContentTable = styled.div`
    margin-top: 25px;
    position: relative;
`
export const TitleHeaderBox = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.border};
    color: ${({ theme }) => theme.text};
`
export const TxtInfoTable = styled.div`
  color: ${({ theme }) => theme.textOverlay};
  .ant-typography {
    color: ${({ theme }) => theme.text};
    &.ant-typography-secondary {
      color: ${({ theme }) => theme.textOverlay};
    }
  }
`
export const AddressBox = styled.div`
    display: flex;
`
export const StyleCopyIcon = styled.div`
    border-radius: 0;
    cursor: pointer;
    border-radius: 0;
    color: ${({ theme }) => theme.textOverlay};
    border: 0;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    justify-content: center;
    &:hover {
        background-color: ${({ theme }) => theme.bgHover};
    }
    > *{
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
    }
`

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ButtonStyled = styled(Button)`
  &.ant-btn,
  &.ant-btn:hover,
  &.ant-btn:focus {
    color: ${({ theme }) => theme.textOverlay};
    border: 0;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.bg};
  }
  &.ant-btn:hover {
    background-color: ${({ theme }) => theme.bgHover};
  }
`

export const OwnerHeader = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.text}
`

export const OwnerTitle = styled.span`
  color: ${({ theme }) => theme.textOverlay};
`
export const TextMain = styled.span`
  color: ${({ theme }) => theme.text};
`

export const OwnerBalanceBox = styled.div`
  padding-left: 34px;
  border-left: 1px solid ${({theme})=> theme.border};
  @media(max-width: 767px){
    padding-left: 0px;
    padding-right: 10px;
    margin-top: 20px;
    border-left: none;
  }
`
export const PositionStyled = styled.div`
  position: absolute;
  right: 260px;
  top: 0;
  @media(max-width: 1024px){
    position: static;
    .ant-form ant-form-vertical{
      justify-content: center;
    }
  }
`

export const RelativeStyled = styled.div`
  position: relative;
`

export const InputStyled = styled(Input)`
  min-width: 250px;
  background-color: ${({theme})=> theme.bg1};
  padding-right: 5px;
  border: 1px solid ${({theme})=>theme.border};
  &:focus,&:active{
    outline: none;
    border: none;
  }
  .ant-input-suffix{
    margin-left: 0;
  }
  .ant-input{
    background-color: ${({theme})=> theme.bg1};
  }
`
export const ButtonSubmit = styled(Input)`
  cursor: pointer;
  color: #fff;
  background-color: ${({theme})=> theme.primary};;
  fontSize: 12px;
  &:focus,&:active{
    outline: none;
    border: none;
  }
`
