import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { PageHeader, Datatable, DataTableTotal, token721Columns } from '../../common'
import { getVrc721TokenList, resetVrc721List } from './tokensSlice'

export default function TokenListVrc721Page() {
  const dispatch = useAppDispatch()
  const { data, loading, total } = useAppSelector((state) => state['tokens']['list']['vrc721'])

  // reset state when component unmount
  useEffect(() => {
    return () => {
      dispatch(resetVrc721List())
    }
  }, [])

  return (
    <div>
      <PageHeader title="Non Fungible Tokens" />

      <Datatable
        loading={loading}
        total={total}
        columns={token721Columns}
        dataSource={data}
        header={<DataTableTotal total={total} type="token" />}
        getData={getVrc721TokenList}
      />
    </div>
  )
}