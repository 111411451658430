import styled from "styled-components";
import { Button } from 'antd'

export const MainDetails = styled.div`
    .ant-tabs-content .content-table{
        padding: 0;
    }
    @media(max-width: 767px){
        .ant-row{
        margin-bottom: 15px;
        }    
    }
`

export const TxtColStyle = styled.div`
    margin: .75rem 0;
    word-break: break-all;
    &.col-txt-left {
        color: ${({ theme }) => theme.textOverlay};
    }
    &.col-txt-right {
        color: ${({ theme }) => theme.text};
    }
    &.back-forward-button-line {
        color: ${({ theme }) => theme.text};
        display: flex;
        flex-wrap: wrap;
    }
    &.bg-box {
        background-color: ${({ theme }) => theme.bg4};
        padding: 24px;
        @media(max-width: 767px){
            padding: 15px;
        }
        .style-table-bordered {
            padding: 24px;
            @media(max-width: 767px){
                padding: 15px;
            }
        }
    }
    .anticon {
        color: ${({ theme }) => theme.textOverlay};
        transition: all .2s;
        &:hover {
            color: #14a282;
        }
    }
    @media(max-width: 767px){
        margin: 0;
    }
`
export const TitleHeaderBox = styled.div`
    font-weight: bold;
    font-size: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.border};
    padding-bottom: 15px;
    color: ${({ theme }) => theme.text};
`
export const TextOverlay = styled.span`
    color: ${({ theme }) => theme.textOverlay};
`
export const BoxTableBefore = styled.div`
    background-color: ${({ theme }) => theme.bg2};
    padding: 24px;
    @media(max-width: 767px){
        padding: 15px;
    }
`

export const BoxTableAfter = styled.div`
    margin-top: 25px;
    padding: 15px 0;
    background-color: ${({ theme }) => theme.bg2};
`

export const TxtInfoTable = styled.div`
  margin: 5px 0 20px 0px;
`

export const ButtonStyled = styled(Button)`
  margin-left: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.bgBtn};
  border: 1px solid ${({ theme }) => theme.bdBtn};
  color: ${({ theme }) => theme.textBtn};
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    background-color: ${({ theme }) => theme.bgBtnDisable};
    border: 1px solid ${({ theme }) => theme.textBtnDisable};
    color: rgba(0, 0, 0, 0.25);
  }
  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active {
    background-color: ${({ theme }) => theme.bgBtnActive};
    border: 1px solid ${({ theme }) => theme.bdBtnActive};
    color: ${({ theme }) => theme.textBtnActive};
  }
`

export const StyleCopyIcon = styled.span`
    cursor: pointer;
    border-radius: 0;
    border: 0;
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.textOverlay};
    margin-left: 10px;
`