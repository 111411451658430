import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { PageHeader } from '../../common/PageHeader'
import iconCopy from '../../assets/images/icon/icon-copy.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useParams, Link, Redirect } from 'react-router-dom'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import {
  Row,
  Col,
  Divider,
  Tooltip
} from 'antd'

import {
  ClockCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'

import {
  MainDetails,
  TxtColStyle,
  BoxTableAfter,
  BoxTableBefore,
  TextOverlay,
  TitleHeaderBox,
  ButtonStyled,
  StyleCopyIcon
} from './blockDetailsPageStyles'

import { formatNumber, timeFormatUtc, timeFormatDistance, toTomo } from '../../utils'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { BlockDetailsType, BlockParamsType } from '../../constants/types'
import { getBlockDetailsByNumberOrHash } from './blocksSlice'
import { getStats, resetStats } from '../../features/general/generalSlice'
import { SkeletonLoading } from '../../common'
import BlockTransactionList from './BlockTransactionList'


export default function BlockDetailsOverview() {
  const [isCopied, setCopied] = useState(false)
  const dispatch = useAppDispatch()
  const { blockNumberOrHash } = useParams<BlockParamsType>()
  const stats = useAppSelector((state) => state.general.stats.data)
  useEffect(() => {
    if (blockNumberOrHash) dispatch(getBlockDetailsByNumberOrHash(blockNumberOrHash))
  }, [blockNumberOrHash, dispatch])

  const getData = () => {
        dispatch(getStats())
    }
    useEffect(() => {
        getData()

        // reset state
        return () => {
        dispatch(resetStats())
        }
    }, [])  
  const { data, loading }: { data: null | BlockDetailsType, loading: string } = useAppSelector((state) => state.blocks.details)
  if(stats && stats.block && blockNumberOrHash && stats.block < blockNumberOrHash) return <Redirect to={`/countdown/${blockNumberOrHash}`}/>
  if (loading === 'pending') return <SkeletonLoading />
  if (loading === 'failed') return <Redirect to={`/search?q=${blockNumberOrHash}`} />
  if (!data) return null

  return (
    <MainDetails>
      {blockNumberOrHash &&
        <Helmet>
          <title>{`Block ${blockNumberOrHash}`}</title>
        </Helmet>
      }
      <PageHeader title="Block Details" />
      <BoxTableBefore>
        <TitleHeaderBox>Overview</TitleHeaderBox>
        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              Block height
              <Tooltip
                placement='top'
                title="Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new block."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right back-forward-button-line">
              {formatNumber(data.number)}
              <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy BlockNumber to clipboard'}
              >
                <CopyToClipboard
                  text={String(data.number)}
                  onCopy={() => setCopied(true)}
                  //@ts-ignore
                  onMouseEnter={() => setCopied(false)}
                >
                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                </CopyToClipboard>
              </Tooltip>

              <Tooltip
                placement='top'
                title={data.number > 0 ? 'Previous block' : 'This is the genesis block'}
              >
                <Link to={`/block/${data.number - 1}`}><ButtonStyled disabled={data.number === 0}><LeftOutlined /></ButtonStyled></Link>
              </Tooltip>

              <Tooltip
                placement='top'
                title={data.number < data.latestBlock ? 'Next block' : 'This is the latest block'}
              >
                <Link to={`/block/${data.number +1}`}><ButtonStyled disabled={data.latestBlock === data.number}><RightOutlined /></ButtonStyled></Link>
              </Tooltip>
            </TxtColStyle>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              Epoch
              <Tooltip
                placement='top'
                title="A set of 900 blocks."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              <Link to={`/epoch/${data.epoch}`}>{formatNumber(data.epoch)}</Link>
              <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy Epoch to clipboard'}
              >
                <CopyToClipboard
                  text={String(data.epoch)}
                  onCopy={() => setCopied(true)}
                  //@ts-ignore
                  onMouseEnter={() => setCopied(false)}
                >
                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                </CopyToClipboard>
              </Tooltip>
            </TxtColStyle>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              Timestamp
              <Tooltip
                placement='top'
                title="The date and time at which a block is produced."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              {timeFormatUtc(data.timestamp)}
              <Divider type='vertical' />
              <TextOverlay><ClockCircleOutlined /> {timeFormatDistance(data.timestamp)}</TextOverlay>
              <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy Timestamp value to clipboard'}
              >
                <CopyToClipboard
                  text={String(data.timestamp)}
                  onCopy={() => setCopied(true)}
                  //@ts-ignore
                  onMouseEnter={() => setCopied(false)}
                >
                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                </CopyToClipboard>
              </Tooltip>
            </TxtColStyle>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
                Transactions
                <Tooltip
                placement='top'
                title="The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves Ether value."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>    
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              <b>{formatNumber(data.transactions)}</b> transaction{data.transactions > 1 ? 's' : ''} and <b>{formatNumber(data.internalTxCount)}</b> contract internal transaction{data.internalTxCount > 1 ? 's' : ''} in this block
            </TxtColStyle>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              Hash
              <Tooltip
                placement='top'
                title="The hash of the block header of the current block."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              {data.hash}
              <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy Block Hash to clipboard'}
              >
                <CopyToClipboard
                  text={data.hash}
                  onCopy={() => setCopied(true)}
                  //@ts-ignore
                  onMouseEnter={() => setCopied(false)}
                >
                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                </CopyToClipboard>
              </Tooltip>
            </TxtColStyle>
          </Col>
        </Row>

        {data.parentHash &&
          <Row gutter={24}>
            <Col span={24} md={5} lg={6}>
              <TxtColStyle className="col-txt-left">
                Parent Hash
                <Tooltip
                placement='top'
                title="The hash of the block from which this block was generated, also known as its parent block."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
              </TxtColStyle>
            </Col>
            <Col span={24} md={19} lg={18}>
              <TxtColStyle className="col-txt-right">
                {(data.number - 1) > 0 && <Link to={`/block/${data.number - 1}`}>{data.parentHash}</Link>}
                <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy Parent Hash to clipboard'}
                >
                  <CopyToClipboard
                    text={data.parentHash}
                    onCopy={() => setCopied(true)}
                    //@ts-ignore
                    onMouseEnter={() => setCopied(false)}
                  >
                    <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                  </CopyToClipboard>
                </Tooltip>
              </TxtColStyle>
            </Col>
          </Row>
        }
        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              State Root
              <Tooltip
                placement='top'
                title="The root of the state trie."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              {data.stateRoot}
              <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy StateRoot to clipboard'}
              >
                <CopyToClipboard
                  text={data.stateRoot}
                  onCopy={() => setCopied(true)}
                  //@ts-ignore
                  onMouseEnter={() => setCopied(false)}
                >
                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                </CopyToClipboard>
              </Tooltip>
            </TxtColStyle>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              Creator
              <Tooltip
                placement='top'
                title="The block producer"
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              <Link to={`/address/${data.m1}`}>{data.m1Name ? data.m1Name : data.m1}</Link>
              <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy Creator address to clipboard'}
              >
                <CopyToClipboard
                  text={data.m1}
                  onCopy={() => setCopied(true)}
                  //@ts-ignore
                  onMouseEnter={() => setCopied(false)}
                >
                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                </CopyToClipboard>
              </Tooltip>
            </TxtColStyle>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              Verifier
              <Tooltip
                placement='top'
                title="The validator double checking this block."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              <Link to={`/address/${data.m2}`}>{data.m2Name ? data.m2Name : data.m2}</Link>
              <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy Verifier address to clipboard'}
              >
                <CopyToClipboard
                  text={data.m2}
                  onCopy={() => setCopied(true)}
                  //@ts-ignore
                  onMouseEnter={() => setCopied(false)}
                >
                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                </CopyToClipboard>
              </Tooltip>
            </TxtColStyle>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              Total Fee
              <Tooltip
                placement='top'
                title="Total transaction fee in this block."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              {toTomo(data.totalFee, 18)} VIC
              <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy Total Fee value to clipboard'}
              >
                <CopyToClipboard
                  text={String(toTomo(data.totalFee, 18))}
                  onCopy={() => setCopied(true)}
                  //@ts-ignore
                  onMouseEnter={() => setCopied(false)}
                >
                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                </CopyToClipboard>
              </Tooltip>
            </TxtColStyle>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              Gas Used
              <Tooltip
                placement='top'
                title="Total gas unit used in this block."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              {formatNumber(data.gasUsed)}
              <TextOverlay style={{ marginLeft: '5px' }}>({formatNumber(data.gasUsed * 100 /data.gasLimit, 2)}%)</TextOverlay>
              <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy Gas Used to clipboard'}
              >
                <CopyToClipboard
                  text={String(data.gasUsed)}
                  onCopy={() => setCopied(true)}
                  //@ts-ignore
                  onMouseEnter={() => setCopied(false)}
                >
                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                </CopyToClipboard>
              </Tooltip>
            </TxtColStyle>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              Gas Limit
              <Tooltip
                placement='top'
                title="Block gas limit."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              {formatNumber(data.gasLimit)}
              <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy Gas Limit to clipboard'}
              >
                <CopyToClipboard
                  text={String(data.gasLimit)}
                  onCopy={() => setCopied(true)}
                  //@ts-ignore
                  onMouseEnter={() => setCopied(false)}
                >
                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                </CopyToClipboard>
              </Tooltip>
            </TxtColStyle>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              Finality
              <Tooltip
                placement='top'
                title="The percentage of masternodes in the network which agreeing this block."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              {data.finality}%
            </TxtColStyle>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              Total Difficulty
              <Tooltip
                placement='top'
                title="Total difficulty of the chain until this block."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              {formatNumber(data.totalDifficulty)}
              <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy Total Difficulty to clipboard'}
              >
                <CopyToClipboard
                  text={String(data.totalDifficulty)}
                  onCopy={() => setCopied(true)}
                  //@ts-ignore
                  onMouseEnter={() => setCopied(false)}
                >
                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                </CopyToClipboard>
              </Tooltip>
            </TxtColStyle>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              Size
              <Tooltip
                placement='top'
                title="The block size is actually determined by the block's gas limit."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              {formatNumber(data.size)} bytes
              <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy Size to clipboard'}
              >
                <CopyToClipboard
                  text={String(data.size)}
                  onCopy={() => setCopied(true)}
                  //@ts-ignore
                  onMouseEnter={() => setCopied(false)}
                >
                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                </CopyToClipboard>
              </Tooltip>
            </TxtColStyle>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24} md={5} lg={6}>
            <TxtColStyle className="col-txt-left">
              Extra Data
              <Tooltip
                placement='top'
                title="Any data that can be included by the block producer in this block."
              >
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </TxtColStyle>
          </Col>
          <Col span={24} md={19} lg={18}>
            <TxtColStyle className="col-txt-right">
              {data.extraData}
              <Tooltip
                placement='top'
                title={isCopied ? 'Copied' : 'Copy ExtraData to clipboard'}
              >
                <CopyToClipboard
                  text={data.extraData}
                  onCopy={() => setCopied(true)}
                  //@ts-ignore
                  onMouseEnter={() => setCopied(false)}
                >
                  <StyleCopyIcon><img width="20" src={(iconCopy)} alt="icon copy" /></StyleCopyIcon>
                </CopyToClipboard>
              </Tooltip>
            </TxtColStyle>
          </Col>
        </Row>

      </BoxTableBefore>

      <BoxTableAfter>
        <TitleHeaderBox style={{ marginLeft: '24px', marginRight: '24px' }}>Transactions</TitleHeaderBox>
        <BlockTransactionList blockNumber={blockNumberOrHash} />
      </BoxTableAfter>
    </MainDetails>
  )
}
