import { Space, Button } from 'antd'
import styled from "styled-components"
import { StepBackwardOutlined, LeftOutlined, RightOutlined, StepForwardOutlined } from '@ant-design/icons'

import { PaginationType } from '../../constants/types'
import { formatNumber } from '../../utils'

type DataTablePaginationProps = {
  changePage: Function
  pagination: PaginationType
  pages: null | number
}

export const ButtonStyled = styled(Button)`
  border-radius: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.bgBtn};
  border: 1px solid ${({ theme }) => theme.bdBtn};
  color: ${({ theme }) => theme.textBtn};
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    background-color: ${({ theme }) => theme.bgBtnDisable};
    border: 1px solid ${({ theme }) => theme.textBtnDisable};
    color: rgba(0, 0, 0, 0.25);
  }
  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active {
    background-color: ${({ theme }) => theme.bgBtnActive};
    border: 1px solid ${({ theme }) => theme.bdBtnActive};
    color: ${({ theme }) => theme.textBtnActive};
  }
`

export default function DataTablePagination({ changePage, pagination, pages }: DataTablePaginationProps) {
  if (pages === 1) return null
  
  return (
    <Space>
      <ButtonStyled disabled={pagination.currPage === 1} onClick={() => changePage('first')}><StepBackwardOutlined /></ButtonStyled>
      <ButtonStyled disabled={pagination.currPage === 1} onClick={() => changePage('prev')}><LeftOutlined /></ButtonStyled>
      <span>Page {formatNumber(pagination.currPage)} of {pages && formatNumber(pages)}</span>
      <ButtonStyled disabled={pagination.currPage === pages} onClick={() => changePage('next')}><RightOutlined /></ButtonStyled>
      <ButtonStyled disabled={pagination.currPage === pages} onClick={() => changePage('last')}><StepForwardOutlined /></ButtonStyled>
    </Space>
  )
}
