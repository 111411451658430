import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1270px;
  margin: 0 auto;
  padding: 0 15px;
`

export const Warning = styled.div`
  text-align: center;
  .img-visual {
    margin: 25px 0;
    @media only screen and (max-width: 1500px) {
      img {
          max-height: 250px;
      }
    }
  }
  .btn-blue-bd-blue {
    background: #00E8B5;
    border-radius: 8px;
    padding: 10px 24px;
    color: #FFFFFF;
    border: 1px solid #00E8B5;
    height: auto;
    margin: 0 10px;
    font-weight: 800;
    font-size: 15px;
  }
  .btn-white-bd-blue {
    background: #F2FEFB;
    border-radius: 8px;
    padding: 10px 24px;
    color: #00E8B5;
    border: 1px solid #00E8B5;
    display: inline-block;
    margin: 15px 10px;
    font-weight: 800;
    font-size: 15px;
  }
  .mt-3 {
    margin-top: 15px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 15px;
    }
  }
  .dark .ant-typography {
    color: #ffffff;
  }
`

export const SearchWrapper = styled.div`
  max-width: 720px;
  position: relative;
  margin: auto;
  .SuggestContentDropdown {
    bottom: 50px;
  }
`