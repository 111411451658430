import { Row, Col } from 'antd'

import {
  BoxContentPlaceHolder,
  TitleHeaderBox,
  InnerPlaceHolder,
  TxtColStyle,
} from './tokenDetailsPageStyles'

import { formatNumber } from '../../utils'

export default function Vrc721Overview({ data, tokenId = '' }: { data: any, tokenId: string }) {
  return (
    <BoxContentPlaceHolder>
      <TitleHeaderBox>Market Overview</TitleHeaderBox>
      <InnerPlaceHolder>
        {data.price &&
          <Row gutter={24}>
            <Col span={24} md={6} lg={8}>
              <TxtColStyle className="col-txt-left">
                Price
              </TxtColStyle>
            </Col>
            <Col span={24} md={18} lg={16}>
              <TxtColStyle className="col-txt-right">
                ${formatNumber(data.price)}
              </TxtColStyle>
            </Col>
          </Row>
        }

        {!!tokenId && (
          <Row gutter={24}>
            <Col span={24} md={6} lg={8}>
              <TxtColStyle className="col-txt-left">
                TokenID
              </TxtColStyle>
            </Col>
            <Col span={24} md={18} lg={16}>
              <TxtColStyle className="col-txt-right">
                {tokenId}
              </TxtColStyle>
            </Col>
          </Row>
        )}

        {!tokenId && (
          <>
            <Row gutter={24}>
              <Col span={24} md={6} lg={8}>
                <TxtColStyle className="col-txt-left">
                  Max Total Supply
                </TxtColStyle>
              </Col>
              <Col span={24} md={18} lg={16}>
                <TxtColStyle className="col-txt-right">
                  {formatNumber(data.totalSupplyNumber, 2)}
                </TxtColStyle>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} md={6} lg={8}>
                <TxtColStyle className="col-txt-left">
                  Holders
                </TxtColStyle>
              </Col>
              <Col span={24} md={18} lg={16}>
                <TxtColStyle className="col-txt-right">
                  {formatNumber(data.holder)}
                </TxtColStyle>
              </Col>
            </Row>
          </>
        )}
        
      </InnerPlaceHolder>
    </BoxContentPlaceHolder>
  )
}