import { Tooltip } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import { AddressLinkType } from '../constants/types'

function getLinkContent(title: string | undefined | null, address: string, truncate?: boolean) {
  if (title) return title
  // if (!title && !truncate) return address

  return address.toLowerCase()
}

function AddressLink({ title, address, type = 'address', truncate = false, tooltip = true }: AddressLinkType) {
  if (!address) return null

  if (!tooltip) {
    return (
      <Link className="txt-ellipsis link-hover" to={`/${type}/${address}`}>{getLinkContent(title, address, truncate)}</Link>
    )
  }

  return (
    <Tooltip title={address}>
      <div style={{ maxWidth: '100%' }}>
        <Link className="txt-ellipsis link-hover" to={`/${type}/${address}`}>{getLinkContent(title, address, truncate)}</Link>
      </div>
    </Tooltip>
  )
}

export default AddressLink