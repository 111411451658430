import styled from 'styled-components'
import { Button, Form, Input, List } from 'antd'

export const FormStyled = styled(Form)`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.border};
  position: relative;
  .ant-input{
    color: ${({ theme }) => theme.text};
  }
  .anticon-close-circle{
    color: ${({ theme }) => theme.textOverlay};
  }
`

export const FormItemStyled = styled.div`
  position: relative;
  width: 100%;
`

export const InputGroupStyled = styled(Input.Group)`
  display: flex;
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
`

export const InputStyled = styled(Input)`
  padding: 0;
  border: none;
  background: transparent;

  .ant-input {
    padding: 15px!important;
    height: 100% !important;
    background: transparent;
  }

  .ant-input-suffix {
    padding-right: 70px;
  }
  .ant-input-clear-icon{
    font-size: 20px;
  }
`

export const ButtonSearch = styled(Button)`
  background: ${({ theme }) => theme.primary};
  border: 0!important;
  color: #fff;
  width: 56px!important;
  height: 40px !important;
  margin-right: 4px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  z-index: 9;
  border-radius: 0;
  &:hover {
    background: ${({ theme }) => theme.primaryHover};
    color: #fff;
  }

  .anticon-search {
    line-height: 0;
  }
`

export const ListWrapper = styled.div`
  margin-top: 7px;
  position: absolute;
  z-index: 1000;
  width: 100%;
  max-height: 320px;
  overflow-y: auto;
  background: ${({ theme }) => theme.bg2};
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
`

export const ListStyled = styled(List)`
  border: none;

  .ant-list-items {
    margin: 0 !important;
  }

  .ant-list-header {
    border-bottom: 1px solid ${({ theme }) => theme.border} !important;
    padding: 0!important;
  }

  .ant-spin-nested-loading {
    border-radius: 0 !important;
    border: 0 !important;
  }
`

export const ListHeader = styled.div`
  text-align: left;
  font-weight bold;
  padding: 7px 15px;
  color: ${({ theme }) => theme.text};
`

export const ListItemStyled = styled(List.Item)`
  flex-direction: column;
  padding: 10px 15px !important;
  margin: 0 !important;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.border} !important;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.bgHover};
  }
`

export const Token = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
`

export const TokenHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.textOverlay};
`

export const TokenTitle = styled.div`
  margin-left: 14px;
  font-weight: 500;
  text-align: initial;
  color: ${({ theme }) => theme.text};
`

export const TokenExtension = styled.div`
  margin-left: 14px;
  font-size: 12px;
  text-align: left;
  width: 100%;
  color: ${({ theme }) => theme.textOverlay};
`

export const OneId = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
`

export const OneIdHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.textOverlay};
`

export const OneIdTitle = styled.div`
  margin-left: 14px;
  font-weight: 500;
  text-align: initial;
  color: ${({ theme }) => theme.text};
`

export const OneIdExtension = styled.div`
  margin-left: 14px;
  font-size: 12px;
  text-align: left;
  width: 100%;
  color: ${({ theme }) => theme.textOverlay};
`