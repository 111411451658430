// import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import Web3 from 'web3'
import LogoTomoscan from '../../assets/images/tomoscan-logo-dark.svg';
import LogoTomoscanLight from '../../assets/images/tomoscan-logo-light.svg';
import icDiscord from '../../assets/images/ic-discord.png';
import icTwitter from '../../assets/images/ic-twitter.png';
// import icFacebook from '../../assets/images/ic-facebook.png';
import icTelegram from '../../assets/images/ic-telegram.png';
import icGithub from '../../assets/images/ic-github.png';
import MetamaskLog from '../../assets/images/metamask.svg'
import { CHAINID } from '../../constants/env'
import { RootState } from '../../app/store';

import {
  Row,
  Col,
  Space,
  Descriptions,
  Typography
} from 'antd'

import {
  Wrapper,
  Content,
  ListMenuFooter,
  EndFooter,
  ButtonStyled,
  TextMain
} from './footerStyles'
import { supportedWalletOption } from '../../constants';

const { Text } = Typography;

export default function Footer() {
  const mode = useSelector((state: RootState) => state.general.mode)
  const ethEnabled = async () => {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum)
            const networkId = await window.ethereum.request({
                method: 'net_version',
            });
            try {
                if (Number(networkId) !== CHAINID) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: supportedWalletOption,
                    })
                } else {
                  alert('Already on Viction Network')
                }
            } catch (error) {
                console.error(error)
                return false
            }
        } else {
            alert('Please install Metamask')
        }
    }
  return (
    <Wrapper>
      <Content>
        <Row gutter={24}>
          <Col span={8}>
            <Descriptions

              title={
                <Link to="/">
                  <img
                    className="logo-size"
                    src={( mode === "dark" ? LogoTomoscanLight : LogoTomoscan )} alt="LOGO VICSCAN"
                  />
                </Link>}
            >
              <Descriptions.Item>
                <Space className="list-social">
                  <Text className="social-label" type="secondary">Join us:</Text>
                  <Link target="_blank" to={{pathname: "https://viction.link/discord"}}><img src={(icDiscord)} alt="" /></Link>
                  <Link target="_blank" to={{pathname: "https://viction.link/twitter"}}><img src={(icTwitter)} alt="" /></Link>
                  {/* <Link target="_blank" to={{pathname: "https://www.facebook.com/tomochainofficial"}}><img src={(icFacebook)} alt="" /></Link> */}
                  <Link target="_blank" to={{pathname: "https://viction.link/telegram"}}><img src={(icTelegram)} alt="" /></Link>
                  <Link target="_blank" to={{pathname: "https://github.com/BuildOnViction"}}><img src={(icGithub)} alt="" /></Link>
                </Space>
              </Descriptions.Item>              
            </Descriptions>
            <ButtonStyled onClick={ethEnabled}>
              <img width="20px" src={MetamaskLog} alt="MetaMask" />
              <TextMain>Add Viction Network</TextMain>
              </ButtonStyled>
          </Col>
          <Col span={8}>
            <ListMenuFooter>
              <Descriptions
                title="Company"
                column={1}
              >
                <Descriptions.Item><Link target="_blank" to={{pathname:"https://viction.xyz"}}>About us</Link></Descriptions.Item>
                <Descriptions.Item><a href="mailto:hi@viction.xyz">Contact us</a></Descriptions.Item>
                <Descriptions.Item><Link target="_blank" to={{pathname:"https://docs.viction.xyz/legal/terms-of-use"}}>Terms of service</Link></Descriptions.Item>
              </Descriptions>
            </ListMenuFooter>
          </Col>
          <Col span={8}>
            <ListMenuFooter>
              <Descriptions
                title="Other products"
                column={1}
              >
                <Descriptions.Item><Link target="_blank" to={{pathname:"https://chrome.google.com/webstore/detail/viction-wallet/nopnfnlbinpfoihclomelncopjiioain"}}>Viction Wallet (Extension)</Link></Descriptions.Item>
                <Descriptions.Item><Link target="_blank" to={{pathname:"https://apps.apple.com/vn/app/viction-wallet-by-coin98/id1436476145"}}>Viction Wallet (iOS)</Link></Descriptions.Item>
                <Descriptions.Item><Link target="_blank" to={{pathname:"https://vicmaster.xyz"}}>VicMaster</Link></Descriptions.Item>
              </Descriptions>
            </ListMenuFooter>
          </Col>
        </Row>
      </Content>
      <EndFooter>
        <Space className="list-social">
          <span>VIC Scan &copy; {(new Date()).getFullYear()}</span> | <span>Powered by Viction</span>
        </Space>
      </EndFooter>
    </Wrapper>
  )
}
