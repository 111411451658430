import BigNumber from 'bignumber.js'
import { useCallback } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { TokenParamsType } from '../../constants/types'
import { columnsBalance, Datatable, DataTableTotal, SkeletonLoading } from '../../common'
import { useAppSelector } from '../../app/hooks'
import { formatNumber } from '../../utils'
import { getTokensInAccount } from './accountsSlice'

export default function TotalTokenBalance() {
  const { address } = useParams<TokenParamsType>()

  const getTokensData = useCallback(
    (params: any) => getTokensInAccount({ ...params, address }),
    [address]
  )

  const { data, loading, tokensInAccount } = useAppSelector((state) => state['accounts']['details'])

  if (loading === 'pending') return <SkeletonLoading />
  if (loading === 'failed') return <Redirect to={`/search?q=${address}`} />
  if (!data) return null

  const tomoData = {
    address: data.address,
    token: '',
    quantity: new BigNumber(data.balance).toNumber(),
    quantityNumber: data.balanceNumber,
    tokenDecimals: 18,
    tokenName: "Viction",
    tokenSymbol: 'VIC',
    tokenType: 'native',
    priceUsd: data.tomoPrice,
    valueUsd: data.tomoInUsd
  }

  let newTokensInAccount = [tomoData, ...tokensInAccount.data]
  const totalBalance = data.tomoInUsd + tokensInAccount.usdValue
  newTokensInAccount = newTokensInAccount.map((v: any) => {
    const percentage = totalBalance && v.valueUsd ? formatNumber(new BigNumber(v.valueUsd).div(totalBalance).multipliedBy(100).toNumber(), 2) : ''
    return { ...v, percentage }
  })
  return (
    <Datatable
      loading={tokensInAccount.loading}
      total={tokensInAccount.total}
      columns={columnsBalance}
      dataSource={newTokensInAccount}
      header={<DataTableTotal total={tokensInAccount.total} type="token" />}
      getData={getTokensData}
    />
  )
}