import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'

import { ContractsState } from '../../constants/types'
import { fetchContractList, fetchAccountDetails, fetchTxListByAdddress, fetchContractEventLogs } from '../../api'
import { requestLimit } from '../../features/general/generalSlice'

// Create thunk
export const getContractList = createAsyncThunk(
  'contracts/getContractList',
  async (params: { offset: number, limit: number }, thunkAPI) => {
    try {
      const { offset, limit } = params

      const response = await fetchContractList(offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getContractDetailsByAddress = createAsyncThunk(
  'contracts/getContractDetailsByAddress',
  async (address: string, thunkAPI) => {
    try {
      const response = await fetchAccountDetails(address)

      // eslint-disable-next-line no-throw-literal
      if (!response.data.isContract) throw { response: { data: { msg: 'Address isn\'t  contract' } } }
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getTxListByAddress = createAsyncThunk(
  'contracts/getTxListByAddress',
  async (params: { address: string, offset: number, limit: number }, thunkAPI) => {
    try {
      const { address, offset, limit } = params
      const response = await fetchTxListByAdddress(address, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getEventLogs = createAsyncThunk(
  'contracts/getEventLogs',
  async (address: string, thunkAPI) => {
    try {
      const response = await fetchContractEventLogs(address)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// Declare slice
const initialState = {
  list: {
    data: [],
    loading: 'idle',
    total: 0,
  },
  details: {
    data: null,
    loading: 'idle',
    transactions: {
      data: [],
      loading: 'idle',
      total: 0,
    },
    eventLogs: {
      data: [],
      loading: 'idle',
    }
  }
} as ContractsState

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    // reset contract list
    resetContractList(state) {
      state.list.data = []
      state.list.loading = 'idle'
      state.list.total = 0
    }
  },
  extraReducers: (builder) => {
    // contract list
    builder.addCase(getContractList.pending, (state, action) => {
      state.list.loading = 'pending'
    })

    builder.addCase(getContractList.fulfilled, (state, action) => {
      state.list.loading = 'succeeded'
      state.list.data = action.payload.data
      state.list.total = action.payload.total
    })

    builder.addCase(getContractList.rejected, (state, action) => {
      state.list.loading = 'failed'
      state.list.data = []
      state.list.total = 0
    })

    // contract details
    builder.addCase(getContractDetailsByAddress.pending, (state) => {
      state.details.loading = 'pending'
    })

    builder.addCase(getContractDetailsByAddress.fulfilled, (state, action) => {
      let balanceInUsd = 0

      if (action.payload.balanceNumber && action.payload.tomoPrice) {
        balanceInUsd = new BigNumber(action.payload.balanceNumber).times(action.payload.tomoPrice).toNumber()
      }

      state.details.data = { ...action.payload, balanceInUsd }
      state.details.loading = 'succeeded'
    })

    builder.addCase(getContractDetailsByAddress.rejected, (state) => {
      state.details.loading = 'failed'
      state.details.data = null
    })

    // contract tx list
    builder.addCase(getTxListByAddress.pending, (state, action) => {
      state.details.transactions.loading = 'pending'
    })

    builder.addCase(getTxListByAddress.fulfilled, (state, action) => {
      state.details.transactions.loading = 'succeeded'
      state.details.transactions.data = action.payload.data
      state.details.transactions.total = action.payload.total
    })

    builder.addCase(getTxListByAddress.rejected, (state, action) => {
      state.details.transactions.loading = 'failed'
      state.details.transactions.data = []
      state.details.transactions.total = 0
    })
    // Contract Event Logs
    builder.addCase(getEventLogs.pending, (state) => {
      state.details.eventLogs.loading = 'pending'
    })

    builder.addCase(getEventLogs.fulfilled, (state, action) => {
      state.details.eventLogs.loading = 'succeeded'
      state.details.eventLogs.data = action.payload.data
    })

    builder.addCase(getEventLogs.rejected, (state) => {
      state.details.eventLogs.loading = 'failed'
      state.details.eventLogs.data = []
    })
  },
})

export const { resetContractList } = contractsSlice.actions
export default contractsSlice.reducer
