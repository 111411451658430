import BigNumber from 'bignumber.js'
import { formatDistance, format, addMinutes } from 'date-fns'
import Web3 from 'web3'

import { RPC_ENDPOINT } from '../constants/env'

export function getContract(ABI: any, contractAddress: string) {
  let web3 = new Web3(new Web3.providers.HttpProvider(RPC_ENDPOINT))
  return new web3.eth.Contract(ABI, contractAddress)
}

export function toTomo(amount: string | number | undefined, decimals = 9) {
  if (!amount) return amount

  return new BigNumber(amount).div(10 ** decimals).toNumber()
}

export function formatNumber(number: string | number | undefined | null, precision = 8) {
  if (!number) return number

  let formated = new BigNumber(number).toFormat(precision)

  if (formated.match(/\.[0]+$/g)) {
    formated = formated.replace(/\.[0]+$/g, '')
  }

  if (formated.match(/\.\d+[0]+$/g)) {
    formated = formated.replace(/[0]+$/g, '')
  }

  return formated
}

export function formatUtc(timestamp: number, pattern = "MMMM dd, yyyy HH:mm:ss a '+UTC'") {
  if (!timestamp) return null

  const dateLocal = new Date(timestamp * 1000)
  return format(addMinutes(dateLocal, dateLocal.getTimezoneOffset()), pattern)
}

export function timeFormatUtc(timestamp: number | undefined, pattern = "MMMM dd, yyyy HH:mm:ss a '+UTC'") {
  if (!timestamp) return timestamp

  const dateLocal = new Date(timestamp * 1000)
  return format(addMinutes(dateLocal, dateLocal.getTimezoneOffset()), pattern)
}

export function timeFormatDistance(time: number) {
  if (!time) return

  return formatDistance(time * 1000, new Date(), {
    addSuffix: true,
  })
}

export function truncateBetween(text: string, num = 8, symbol = '...') {
  const newText = text.toString()

  if (!text) return ''
  if (newText.length <= 2 * num) return newText

  const firtPart = newText.slice(0, num)
  const lastPart = newText.slice(-num)

  return firtPart + symbol + lastPart
}

export function getFirstAndLastDay() {
  const date = new Date(), y = date.getFullYear(), m = date.getMonth()
  const firstDay = new Date(Date.UTC(y, m, 1))
  const lastDay = new Date(Date.UTC(y, m + 1, 0))

  return { firstDay, lastDay }
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isAddress = (text: string) => {
  if (!text) return false;
  try {
    return Web3.utils.isAddress(text.toLowerCase());
  } catch (error) {
    
  }
  return false;
}