import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { BlocksState } from '../../constants/types'
import { requestLimit } from '../../features/general/generalSlice'
import { fetchBlockDetailsByNumberOrHash, fetchTxListByBlock, fetchBlockList } from '../../api'

// Create thunk
export const getBlockDetailsByNumberOrHash = createAsyncThunk(
  'blocks/getBlockDetailsByNumberOrHash',
  async (blockNumberOrHash: string, thunkAPI) => {
    try {
      const response = await fetchBlockDetailsByNumberOrHash(blockNumberOrHash)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getTxListByBlock = createAsyncThunk(
  'blocks/getTxListByBlock',
  async (params: {blockNumber: string, offset: number, limit: number}, thunkAPI) => {
    try {
      const { blockNumber, offset, limit } = params
      const response = await fetchTxListByBlock(blockNumber, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getBlockList = createAsyncThunk(
  'blocks/getBlockList',
  async (params: {author: string|undefined, offset: number, limit: number}, thunkAPI) => {
    try {
      const {author, offset, limit } = params

      const response = await fetchBlockList(author, offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// Declare slice
const initialState = {
  list: { 
    data: [],
    loading: 'idle',
    total: null,
  },
  details: {
    data: null,
    loading: 'idle',
    transactions: {
      data: [],
      total: null,
      loading: 'idle',
    }
  }
} as BlocksState

const blocksSlice = createSlice({
  name: 'blocks',
  initialState,
  reducers: {
    // reset block list
    resetBlockList(state) {
      state.list.data = []
      state.list.loading = 'idle'
      state.list.total = null
    }
  },
  extraReducers: (builder) => {
    // block details
    builder.addCase(getBlockDetailsByNumberOrHash.pending, (state, action) => {
      state.details.loading = 'pending'
      state.details.data = null
    })
    builder.addCase(getBlockDetailsByNumberOrHash.fulfilled, (state, action) => {
      state.details.loading = 'succeeded'
      state.details.data = action.payload
    })
    builder.addCase(getBlockDetailsByNumberOrHash.rejected, (state, action) => {
      state.details.loading = 'failed'
      state.details.data = null
    })

    // block transaction list
    builder.addCase(getTxListByBlock.pending, (state, action) => {
      state.details.transactions.loading = 'pending'
    })
    builder.addCase(getTxListByBlock.fulfilled, (state, action) => {
      state.details.transactions.loading = 'succeeded'
      state.details.transactions.data = action.payload.data
      state.details.transactions.total = action.payload.total
    })
    builder.addCase(getTxListByBlock.rejected, (state, action) => {
      state.details.transactions.loading = 'failed'
      state.details.transactions.data = []
      state.details.transactions.total = null
    })

    // block list
    builder.addCase(getBlockList.pending, (state, action) => {
      state.list.loading = 'pending'
    })

    builder.addCase(getBlockList.fulfilled, (state, action) => {
      state.list.loading = 'succeeded'
      state.list.data = action.payload.data
      state.list.total = action.payload.total
    })

    builder.addCase(getBlockList.rejected, (state, action) => {
      state.list.loading = 'failed'
      state.list.data = []
      state.list.total = null
    })
  },
})

export const { resetBlockList } = blocksSlice.actions
export default blocksSlice.reducer
