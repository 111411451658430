import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Typography, Row, Col } from 'antd'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
    getEventLogs
} from './contractsSlice'
import { TxtColStyle, BoxItemLog, ButtonType, DataType } from './contractDetailsPageStyles'
import { DataTableLoading } from '../../common'

const { Paragraph } = Typography

const TopicRender = ({ log }: any) => {

    const [topicType, setTopicType] = useState('hex')

    const onClickType = (e: any) => {
        const value = e.target.value
        setTopicType(value)
    }
    return (
        <TxtColStyle className="col-txt-right bg-box">
            {log.decodedTopics &&
                <DataType>
                    <ButtonType value="hex" onClick={onClickType} className={topicType === 'hex' ? 'active' : ''}>Hex</ButtonType>
                    <ButtonType value="dec" onClick={onClickType} className={topicType === 'dec' ? 'active' : ''}>Dec</ButtonType>
                </DataType>
            }
            <div className="box-hex-dex">
                {log.decodedTopics && topicType === 'dec' ?
                    log.decodedTopics.map((t: any, i: any) => (
                        <div className="items-topics" key={i}>
                            {i + 1}. {t}
                        </div>
                    ))
                    :
                    log.topics.map((topic: any, topicIdx: number) => (
                        <div className="items-topics" key={topicIdx}>
                            {topicIdx + 1}. {topic}
                        </div>
                    ))
                }
            </div>
        </TxtColStyle>
    )
}

const DataRender = ({ log }: any) => {
    const [topicType, setTopicType] = useState('hex')

    const onClickType = (e: any) => {
        const value = e.target.value
        setTopicType(value)
    }
    let newParam: Array<any> = []
    if (log.params && log.params.length > 0) {
        log.params.forEach((p: any) => {
            if (!p.indexed) {
                newParam.push(p.name)
            }
        })
    }
    return (
        <TxtColStyle className="col-txt-right bg-box" style={{ wordBreak: "break-word" }}>
            {log.decodedData &&
                <DataType>
                    <ButtonType value="hex" onClick={onClickType} className={topicType === 'hex' ? 'active' : ''}>Hex</ButtonType>
                    <ButtonType value="dec" onClick={onClickType} className={topicType === 'dec' ? 'active' : ''}>Dec</ButtonType>
                </DataType>
            }
            {log.decodedData && topicType === 'dec' ?
                newParam.map((p: any, i: any) => (
                    <Paragraph key={i}>
                        {p}: {log.decodedData[p]}
                    </Paragraph>
                ))
                :
                log.data
            }
        </TxtColStyle>
    )
}
const ContractEventLogs = ({ address }: any) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (address) {
            dispatch(getEventLogs(address))
        }
    }, [address, dispatch])
    const { eventLogs } = useAppSelector((state) => state['contracts']['details'])
    if (eventLogs.loading === 'pending') return <DataTableLoading />
    return (
        <>
            {eventLogs.data.length > 0 && eventLogs.data.map((log, logIdx) => {
                const getParams = log.params && log.params.reduce((acc: string, current: any, index: number, logArr: any) => {
                    const indexed = current.indexed ? `index_topic ${current.type} ${current.name}` : ` ${current.type} ${current.name}`
                    return index === logArr.length - 1 ? acc + ' ' + indexed : acc + ' ' + indexed + ','
                }, '')
                return (
                    <BoxItemLog key={logIdx}>
                        <Row gutter={24}>
                            <Col span={24} md={5} lg={6}>
                                <TxtColStyle className="col-txt-left">Address</TxtColStyle>
                            </Col>
                            <Col span={24} md={19} lg={18}>
                                <TxtColStyle className="col-txt-right d-flex align-items-center">
                                    <Link to={`/address/${log.address}`}>{log.address}</Link>
                                </TxtColStyle>
                            </Col>
                        </Row>
                        {log.functionName &&
                            <Row gutter={24}>
                                <Col span={24} md={5} lg={6}>
                                    <TxtColStyle className="col-txt-left">Name</TxtColStyle>
                                </Col>
                                <Col span={24} md={19} lg={18}>
                                    <TxtColStyle className="col-txt-right">
                                        {`${log.functionName} (${getParams})`} &nbsp;
                                        <Link to={`/address/${log.address}#code`}>View code</Link>
                                    </TxtColStyle>
                                </Col>
                            </Row>
                        }

                        <Row gutter={24}>
                            <Col span={24} md={5} lg={6}>
                                <TxtColStyle className="col-txt-left">Topics</TxtColStyle>
                            </Col>
                            <Col span={24} md={19} lg={18}>
                                {log.topics.length > 0 &&
                                    <TopicRender log={log} />
                                }
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24} md={5} lg={6}>
                                <TxtColStyle className="col-txt-left">Data</TxtColStyle>
                            </Col>
                            <Col span={24} md={19} lg={18}>
                                {log.data.length > 0 && <DataRender log={log} />}
                            </Col>
                        </Row>
                    </BoxItemLog>
                );
            })}
        </>
    );
}

export default ContractEventLogs
