import { Tag, Typography } from "antd"
import styled from 'styled-components'

const { Text, Title } = Typography

interface TextStyledProps {
  transform?: string
  color?: string
}

export const TextStyled = styled(Text) <TextStyledProps>`
  text-transform: ${props => props.transform ? props.transform : 'inherit'};
  // color: ${props => props.color ? props.color : 'inherit'};
`
export const TitleStyled = styled(Title)`
  color: ${({ theme }) => theme.text}!important;  
  margin-bottom: 0!important;
  @media(max-width: 767px){
    font-size: 25px!important;
  }
`
export const SubTitleStyled = styled.span`
  color: ${({ theme }) => theme.text}!important;  
  margin-bottom: 0!important;
  font-size: 30px;
  font-weight: 600;
  @media(max-width: 767px){
    font-size: 25px!important;
  }
`
export const TokenVerifiedIcon = styled(Tag)`
color: ${({ theme }) => theme.success};
vertical-align:top;
border: 0;
border-radius: 12px;
width: 25px;
background: transparent;
`

export const PageTitle = styled(Title)`
`