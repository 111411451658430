import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Tabs } from 'antd'

interface TabsWithHashProps {
  tabsComp?: any
  children?: any
  type?: string
  activeTab?: string
  tabKeys: Array<string>
}

export default function TabsWithHash({ tabsComp: TabsComp = Tabs, children, activeTab, tabKeys, ...rest }: TabsWithHashProps) {
  const history = useHistory()
  let { hash, search } = useLocation()
  hash = hash?.replace('#', '') || ''
  search = search?.slice(1) || ''
  const [activeKey, setActiveKey] = useState('default')

  useEffect(() => {
    if (!!hash && tabKeys.includes(hash)) {
      setActiveKey(hash)
    } else {
      setActiveKey('default')
    }
  }, [hash])

  useEffect(() => {
    if (!activeTab) return

    handleOnTabClick(activeTab)
  }, [activeTab])

  function handleOnTabClick(key: string) {
    setActiveKey(key)

    if (key !== 'default') history.replace({ hash: key, search })
    else history.replace({ hash: '', search })
  }

  return (
    <TabsComp activeKey={activeKey} onTabClick={handleOnTabClick} {...rest}>
      {children}
    </TabsComp>
  )
}