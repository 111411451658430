import styled from "styled-components"

export const ContentHomePage = styled.div`
  margin-top: 24px;
  .hide-mobile {
    @media(max-width: 991px){
      display: none;
    }
  }
  .hide-desktop {
    @media(min-width: 992px){
      display: none;
    }
  }
`

export const InnerBox = styled.div`
  height: 100%;
`
export const ContentMainTop = styled.div`
  margin-top: 24px;
`

export const ContentMainTable = styled.div`
  margin-top: 24px;
`

export const SearchBoxWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  padding: 44px 32px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  @media(max-width: 991px){
    padding: 24px;
    min-height: auto;
  }
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    // background-image: url(${({ theme }) => theme.bgImageSearch});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 115% auto;
    border-radius: 16px;
    z-index: -1;
  }
`
export const TitleSearch = styled.div`
  color: ${({ theme }) => theme.text};
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 15px;
  letter-spacing: -1px;
  @media(max-width: 767px){
    font-size: 28px;
    line-height: 36px;
  }
`

export const MainDetailToken = styled.div`
  margin-top: 24px;
  .ant-row {
    row-gap: 1px!important;
  }
  .hr-space-lg {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #e7eaf3;
  }
`

export const TitleBoxStart = styled.div`
  color: #8B8B8B;
  font-size: 12px;
  margin-bottom: 2px;
  text-transform: uppercase;
`

export const TitleHeaderBox = styled.div`
  color: ${({ theme }) => theme.text};
  font-weight: 900;
  font-size: 15px;
`

export const InfoDetails = styled.a`
  background-color: ${({ theme }) => theme.bg2};
  display: flex;
  align-items: center;
  padding: 24px 32px;
  overflow: hidden;
  border-left: 1px solid;
  border-color: ${({ theme }) => theme.bg};
  height: 98px;
  
  @media(max-width: 767px){
    padding: 10px;
    height: 100%;
  }
  .media-image {
    margin-right: 12px;
    width: 44px;
    @media(max-width: 767px){
      margin-right: 5px;
      width: 40px;
    }
  }
  .media-info {
    .txt-sap {
      font-size: 14px;
      font-weight: normal;
      color: #8B8B8B;
      @media(max-width: 767px){
        font-size: 10px;
      }
    }
  }
  .ant-typography {
    color: ${({ theme }) => theme.text};
    font-size: 28px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -1px;
    @media(max-width: 767px){
      font-size: 13px;
    }
  }
`

export const CardBoxTable = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  padding: 24px;
  height: 100%;
  .ant-tabs-tab {
    padding: 10px 1.25rem!important;
    margin-left: 0px!important;
  }
  .list-group-item {
    padding: 10px 0;
    border-bottom: 1px solid ${({ theme }) => theme.border};
    color: ${({ theme }) => theme.text};
    &:last-child {
      border-bottom: 0;
    }
    .ant-typography {
      color: ${({ theme }) => theme.text};
      &.ant-typography-secondary {
        color: ${({ theme }) => theme.textOverlay};
      }
    }
    .txt-icon {
      background: ${({ theme }) => theme.border};
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      margin-right: 12px;
    }
    .txt-body {
      overflow: hidden;
      width: calc(100% - 50px);
      * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
      }
    }
  }
  .btn-view-all {
    a {
      background: ${({ theme }) => theme.primary};
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: bold;
      padding: 8px 24px;
      transition: .3s;
      &:hover {
        background: ${({ theme }) => theme.primaryHover};
      }
    }
  }
`

export const CardTableContent = styled.div`
  overflow: hidden;
  overflow-y: auto;
  margin: 14px 0;
`


