import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { TokenParamsType } from '../../constants/types'
import { accountVrcTokenTxnsColumns, Datatable, DataTableTotal } from '../../common'
import { useAppSelector } from '../../app/hooks'
import { getVrcTokenTransactions } from './accountsSlice'

export default function VrcTokenTxns() {
  const { address } = useParams<TokenParamsType>()

  const getData= useCallback(
    (params: any) => getVrcTokenTransactions({ ...params, address }),
    [address]
  )

  const { vrcTokenTxns } = useAppSelector((state) => state['accounts']['details'])

  return (
    <Datatable
      loading={vrcTokenTxns.loading}
      total={vrcTokenTxns.total}
      columns={accountVrcTokenTxnsColumns}
      dataSource={vrcTokenTxns.data}
      header={<DataTableTotal total={vrcTokenTxns.total} type="vrc2021" />}
      getData={getData}
    />
  )
}

