import styled from "styled-components"
import { Tag } from "antd"
import { TagStatusType } from '../constants/types'


const TagStyled = styled(Tag)`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primaryText};
  border: 0;
  padding: 3px 15px;
  margin: 0;
  text-transform: capitalize;
  text-overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 0!important;
`
//
const TagStatusStyled = styled(Tag) <{ variant: TagStatusType }>`
  background-color: ${({ theme, variant }) => variant === 'in' ? theme.bgin : theme.bgout}};
  color: ${({ theme, variant }) => variant ? theme[variant] : theme.transfer};
  border: 0;
  padding: 3px 10px;
  font-weight: 500;
  margin: 0;
  min-width: 50px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0!important;
`

// Change Up, Down
const TagUpDownStyled = styled.span`
  background: transparent;
  border: 0;
  position: relative;
  display: flex;
  align-items: center;

  &.down {
    &:before {
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      margin-right: 5px;
      border-top: 7px solid ${({ theme }) => theme.down};
    }
  }
  &.up {
    &:before {
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      margin-right: 5px;
      border-bottom: 7px solid ${({ theme }) => theme.up};
    }
  }
  &.default {
    &:before {
      content: '';
      border-bottom: 6px solid transparent;
      border-top: 6px solid transparent;
      border-left: 6px solid ${({ theme }) => theme.default};
      margin-right: 5px;
      margin-left: 5px;
    }
  }
`

export { TagStyled, TagStatusStyled, TagUpDownStyled }