import { useCallback } from 'react'

import { useAppSelector } from '../../app/hooks'
import { Datatable, DataTableTotal, token721InventoryColumns } from '../../common'
import { TokenDetailsType } from '../../constants/types'
import { getVrc721InventoryList } from './tokensSlice'

interface InventoryListProps {
  token: TokenDetailsType
  ownerAddress?: string
  tokenId?: string
}

export default function InventoryList({ token, ownerAddress = '', tokenId = '' }: InventoryListProps) {
  const { inventory } = useAppSelector((state) => state.tokens.details)

  const getData = useCallback(
    (params: any) => getVrc721InventoryList({ ...params, tokenAddress: token.address, ownerAddress, tokenId }),
    [ownerAddress, tokenId, token]
  )

  const { data, total, loading } = inventory

  return (
    <Datatable
      loading={loading}
      total={total}
      columns={token721InventoryColumns}
      dataSource={data}
      header={<DataTableTotal total={total} type="token" />}
      getData={getData}
    />
  )
}