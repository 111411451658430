import { Space, Tooltip, Typography } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import icRightFilled from '../../assets/images/ic-RightFilled.svg';


import styled from 'styled-components'

import {
  InfoCircleOutlined,
} from '@ant-design/icons'
import {
  TagStyled,
  AddressLink,
  TagUpDownStyled,
  TagStatusStyled,
  TokenIcon,
  TextStyled,
  TokenTypeTag,
} from '../index'

import { formatNumber, timeFormatDistance, toTomo, truncateBetween } from '../../utils'
import {
  TxDetailsType,
  TagVariantType,
  TokenType,
} from '../../constants/types'
import ContractIconTooltip from '../ContractIconTooltip'
import CheckStatus from '../CheckStatus';

const { Paragraph } = Typography

const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
`

const MethodTag = styled(TagStyled)`
  width: 100%;
  text-align: center;
`

export const txColumns: ColumnsType<TxDetailsType> = [
  {
    title: 'Transaction ID',
    dataIndex: 'hash',
    render: (hash: string, record: any) =>
      <Tooltip placement="top" title={hash}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {record.status && record.status === 'fail' &&
            <span style={{ color: "#FD4040", fontSize: "12px" }}><InfoCircleOutlined /></span>
          }
          &nbsp;
          <Link className="txt-ellipsis" to={`/tx/${hash}`} >{hash.toLowerCase()}</Link>
        </div>
      </Tooltip>
  },
  {
    title: 'Method',
    dataIndex: 'method',
    width: '120px',
    render: (method: TagVariantType) =>
      <Tooltip placement="top" title={method || 'Unknow'} >
        <TagStyled style={{ padding: '3px 10px', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>{method || 'Unknow'}</TagStyled>
      </Tooltip>,
  },
  {
    title: 'Block',
    dataIndex: 'blockNumber',
    ellipsis: true,
    width: '110px',
    render: (block: string) => <Link to={`/block/${block}`}>#{block}</Link>
  },
  {
    title: 'Time',
    dataIndex: 'timestamp',
    ellipsis: true,
    width: '110px',
    render: (timestamp: number) => <>{timeFormatDistance(timestamp)}</>
  },
  {
    title: 'From',
    dataIndex: 'from',
    render: (from: string, record: any) =>
      <AddressWrapper>
        {record.fromContract && <ContractIconTooltip style={{ marginRight: "5px" }} />}
        <Tooltip placement="top" title={from}>
          <Link className="txt-ellipsis" to={`/address/${from}`} >{record.fromName ? record.fromName : from.toLowerCase()}</Link>
        </Tooltip>
      </AddressWrapper>
  },
  {
    title: '',
    dataIndex: 'icon',
    ellipsis: true,
    width: '40px',
    align: 'center',
    render: (icon: string) => <img src={(icRightFilled)} alt="" />
  },
  {
    title: 'To',
    dataIndex: 'to',
    render: (to: string, record: any) =>
      <AddressWrapper>
        {record.toContract && <ContractIconTooltip style={{ marginRight: "5px" }} />}
        <Tooltip placement="top" title={to}>
          <Paragraph ellipsis className="m-0"><Link className="txt-ellipsis" to={`/address/${to}`} >{record.toName ? record.toName : to.toLowerCase()}</Link></Paragraph>
        </Tooltip>
      </AddressWrapper>
  },
  {
    title: 'Value(VIC)',
    dataIndex: 'value',
    ellipsis: true,
    width: '120px',
    render: (value: number) => <>{formatNumber(toTomo(value, 18), 7)}</>,
  },
  {
    title: 'Fee(VIC)',
    dataIndex: 'fee',
    ellipsis: true,
    width: '100px',
    render: (fee: number) => <>{formatNumber(toTomo(fee, 18), 7)}</>,
  },
]

export const blockColumns = [
  {
    title: 'Block Height',
    key: 'number',
    dataIndex: 'number',
    render: (blockNumber: number) => <Link to={"block/" + blockNumber}>#{blockNumber}</Link>
  },
  {
    title: 'Time',
    key: 'time',
    dataIndex: 'timestamp',
    render: (timestamp: any) => <>{timeFormatDistance(timestamp)}</>
  },
  {
    title: 'Transactions',
    key: 'transactions',
    dataIndex: 'transactions',
    render: (transactions: number) => <>{formatNumber(transactions)}</>
  },
  {
    title: 'Creator',
    key: 'm1Name',
    dataIndex: 'm1Name',
    render: (m1Name: string, record: any) => <AddressLink title={m1Name} address={record.m1} />
  },
  {
    title: 'Gas Used ',
    key: 'gasUsed',
    dataIndex: 'gasUsed',
    render: (gasUsed: number) => <>{formatNumber(gasUsed)}</>
  },
  {
    title: 'Fee (VIC)',
    key: 'totalFee',
    dataIndex: 'totalFee',
    render: (totalFee: number) => <>{formatNumber(toTomo(totalFee, 18))}</>
  },
  {
    title: 'Finality (%)',
    key: 'finality',
    dataIndex: 'finality',
  },
]

export const epochColumns = [
  {
    title: 'Epoch',
    key: 'epoch',
    dataIndex: 'epoch',
    width: '14%',
    render: (epoch: string) => <Link to={"/epoch/" + epoch}>{epoch}</Link>
  },
  {
    title: 'Start block',
    key: 'startBlock',
    dataIndex: 'startBlock',
    width: '16%',
    render: (startBlock: string) => <Link to={`/block/${startBlock}`}>{startBlock}</Link>
  },
  {
    title: 'End block',
    key: 'endBlock',
    dataIndex: 'endBlock',
    width: '16%',
    render: (endBlock: string) => <Link to={`/block/${endBlock}`}>{endBlock}</Link>
  },
  {
    title: 'Duration (s)',
    key: 'duration',
    dataIndex: 'duration',
    width: '14%',
    render: (duration: number) => <>{formatNumber(duration)}</>
  },
  {
    title: 'Masternode',
    key: 'masternode',
    dataIndex: 'masternode',
    width: '14%',
    render: (masternode: any) => <>{masternode.length}</>
  },
  {
    title: 'Voter',
    key: 'voter',
    dataIndex: 'voterNumber',
    width: '12%',
    render: (voterNumber: number) => <>{formatNumber(voterNumber)}</>
  },
  {
    title: 'Slashed Node',
    key: 'slashednode',
    dataIndex: 'slashednode',
    width: '12%',
    render: (slashednode: any) => <>{slashednode.length}</>
  },
]

export const epochRewardColumns = [
  {
    title: 'Address',
    key: 'address',
    dataIndex: 'address',
    width: 280,
    render: (address: string) => <AddressLink address={address} />
  },
  {
    title: 'Masternode',
    key: 'validator',
    dataIndex: 'validator',
    width: 280,
    render: (validator: string) => <AddressLink address={validator} />
  },
  {
    title: 'Masternode Name',
    key: 'validatorName',
    dataIndex: 'validatorName',
    ellipsis: true,
  },
  {
    title: 'Signing number',
    key: 'signNumber',
    dataIndex: 'signNumber',
  },
  {
    title: 'Reward',
    key: 'reward',
    dataIndex: 'reward',
    render: (reward: number) => <>{formatNumber(toTomo(reward, 18))}</>
  },
  {
    title: 'Age',
    key: 'age',
    dataIndex: 'age'
  },
]

export const tokenColumns = [
  {
    title: 'No',
    dataIndex: 'rank',
    width: '60px',
    render: (rank: number) => <>{rank}</>
  },
  {
    title: 'Token',
    dataIndex: 'address',
    render: (token: string, record: any) =>
      <div className="d-flex align-items-center" style={{ width: '200px' }}>
        <TokenIcon address={record.address} />
        {(record.name && record.symbol) && <><AddressLink address={record.address} title={`${record.name} (${record.symbol?.toUpperCase()})`} type="token" /></>}
        {(!record.name || !record.symbol) && <AddressLink address={record.address} type="token" />}

        {/* <TokenVerifiedIcon><CheckCircleFilled/></TokenVerifiedIcon> */}
      </div>
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (type: TokenType) => <TokenTypeTag type={type} />
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (token: string, record: any) => {
      if (!record?.projectVerified && !record?.contractVerified) return
      return (
        <div style={{ justifyContent: 'center' }} className='d-flex align-items-center justify-content-center'>
          <CheckStatus tokenStatus={record} isOnlyIcon />
        </div>
      )
    }
  },
  {
    title: 'Price (USD)',
    dataIndex: 'price',
    render: (price: number) => <>{!!price && `$${formatNumber(price)}`}</>
  },
  {
    title: 'Change (%)',
    dataIndex: 'priceChangePercentage',
    render: (changePercentage: number) => {

      return (
        <>
          {(changePercentage > 0) && <TagUpDownStyled className="up">{`${formatNumber(Math.abs(changePercentage), 2)}%`}</TagUpDownStyled>}
          {(changePercentage < 0) && <TagUpDownStyled className="down">{`${formatNumber(Math.abs(changePercentage), 2)}%`}</TagUpDownStyled>}
          {(changePercentage === 0) && <TagUpDownStyled className="default">{`${formatNumber(Math.abs(changePercentage), 2)}%`}</TagUpDownStyled>}
        </>
      )

    }
  },
  {
    title: 'Volume 24h (USD)',
    dataIndex: 'volume',
    render: (volume: number) => <>{!!volume && `$${formatNumber(volume, 2)}`}</>
  },
  {
    title: 'Marketcap (USD)',
    dataIndex: 'marketcap',
    render: (marketcap: number) => <>{!!marketcap && `$${formatNumber(marketcap, 2)}`}</>
  },
  {
    title: 'Holders',
    dataIndex: 'holder',
    render: (holder: number) => <>{formatNumber(holder)}</>
  },
]

export const token721Columns = [
  {
    title: 'No',
    dataIndex: 'rank',
    width: '60px',
    render: (rank: number) => <>{rank}</>
  },
  {
    title: 'Token',
    dataIndex: 'address',
    width: '320px',
    render: (token: string, record: any) =>
      <div className="d-flex align-items-center" style={{ width: '200px' }}>
        <TokenIcon address={record.address} />
        {(record.name && record.symbol) && <AddressLink address={record.address} title={`${record.name} (${record.symbol})`} type="token" />}
        {(!record.name || !record.symbol) && <AddressLink address={record.address} type="token" />}
      </div>
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (type: TokenType) => <TokenTypeTag type={type} />
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (token: string, record: any) => {
      if (!record?.projectVerified && !record?.contractVerified) return
      return (
        <div style={{ justifyContent: 'center' }} className='d-flex align-items-center justify-content-center'>
          <CheckStatus tokenStatus={record} isOnlyIcon />
        </div>
      )
    }
  },

  {
    title: 'Total Transfer',
    dataIndex: 'transferCount',
    render: (transferCount: number) => <>{formatNumber(transferCount)}</>
  },
  {
    title: 'Transfer (24h)',
    dataIndex: 'transferCountShortTerm',
    render: (transferCount: number) => <>{formatNumber(transferCount)}</>
  },
  {
    title: 'Holders',
    dataIndex: 'holder',
    render: (holder: number) => <>{formatNumber(holder)}</>
  },
  {
    title: 'Total Supply',
    dataIndex: 'totalSupplyNumber',
    render: (totalSupplyNumber: number) => <>{formatNumber(totalSupplyNumber)}</>
  },
]

export const tokenHolderColumns = [
  {
    title: 'Rank',
    dataIndex: 'rank',
    width: 80,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    ellipsis: true,
    width: 400,
    render: (address: string, record: any) =>
      <Paragraph ellipsis className="m-0">
        {record.isContract && <ContractIconTooltip style={{ marginRight: '15px', position: "absolute", left: "8px", top: "15px" }} />}
        {/* <Link to={`/token/${record.token}?account=${address}`}> */}
        <a className="txt-ellipsis" href={`/token/${record.token}?account=${address}`} style={{ paddingLeft: record.isContract ? "20px" : "0px" }}>
          {record.accountName ?
            <Tooltip placement="top" title={address}>
              <TextStyled transform="capitalize">{record.accountName}</TextStyled>
            </Tooltip>
            :
            <TextStyled transform="lowercase">{address}</TextStyled>
          }
        </a>
        {/* </Link> */}
      </Paragraph>
  },
  {
    title: 'Quantity',
    dataIndex: 'quantityNumber',
    ellipsis: true,
    width: 200,
    render: (quantity: number) => <>{formatNumber(quantity)}</>
  },
  {
    title: 'Value (USD)',
    dataIndex: 'valueUsd',
    ellipsis: true,
    width: 150,
    render: (valueUsd: number) => <>{formatNumber(valueUsd, 2)}</>
  },
  {
    title: 'Percentage',
    dataIndex: 'percentage',
    ellipsis: true,
    width: 130,
    render: (percentage: number) => <>{percentage && `${formatNumber(percentage, 4)}%`}</>
  },
]

export const token721InventoryColumns = [
  {
    title: 'ID',
    dataIndex: 'tokenId',
    width: 80,
    render: (id: string, record: any) =>
      <Paragraph ellipsis className="m-0">
        <Link to={`/token/${record.token}?tokenId=${record.tokenIdString ?? record.tokenId}`}><TextStyled transform="lowercase">{record.tokenIdString ?? record.tokenId}</TextStyled></Link>
      </Paragraph>
  },
  {
    title: 'Owner',
    dataIndex: 'address',
    render: (address: string, record: any) =>
      <Paragraph ellipsis className="m-0">
        <Link to={`/token/${record.token}?account=${address}`}><TextStyled transform="lowercase">{address}</TextStyled></Link>
      </Paragraph>
  },
]

export const tokenTransferColumns = [
  {
    title: 'Transaction ID',
    key: 'hash',
    dataIndex: 'transactionHash',
    render: (hash: string) => <AddressLink address={hash} type="tx" />
  },
  {
    title: 'Block',
    key: 'block',
    dataIndex: 'blockNumber',
    width: 110,
    render: (block: string) => <Link to={`/block/${block}`}>#{block}</Link>
  },
  {
    title: 'Time',
    key: 'timestamp',
    dataIndex: 'timestamp',
    ellipsis: true,
    width: 140,
    render: (time: number) => <>{timeFormatDistance(time)}</>
  },
  {
    title: 'From',
    key: 'from',
    dataIndex: 'from',
    render: (from: string, record: any) => {
      const query = new URLSearchParams(window.location.search)
      const getAccount = query.get('account')?.trim() || ''
      return (
        <AddressWrapper>
          {record.fromContract && <ContractIconTooltip style={{ marginRight: "5px" }} />}
          <Tooltip title={from}>
            {/* <AddressLink title={record.fromName ? record.fromName : ''} address={`${record.address}?account=${from}`} /> */}
            {getAccount.toLowerCase() === from.toLowerCase() ?
              <TextStyled className="txt-ellipsis">{record.fromName ? record.fromName : from}</TextStyled>
              :
              <a className="txt-ellipsis" href={`/token/${record.address}?account=${from}`}>{record.fromName ? record.fromName : from}</a>
            }
          </Tooltip>
        </AddressWrapper>
      )
    }
  },
  {
    title: 'To',
    key: 'to',
    dataIndex: 'to',
    render: (to: string, record: any) => {
      const query = new URLSearchParams(window.location.search)
      const getAccount = query.get('account')?.trim() || ''
      return (
        <AddressWrapper>
          {record.toContract && <ContractIconTooltip style={{ marginRight: '5px' }} />}
          {/* <AddressLink title={record.toName ? record.toName : ''} address={to} /> */}
          <Tooltip title={to}>
            {getAccount.toLowerCase() === to.toLowerCase() ?
              <TextStyled className="txt-ellipsis">{record.toName ? record.toName : to}</TextStyled>
              :
              <a className="txt-ellipsis" href={`/token/${record.address}?account=${to}`}>{record.toName ? record.toName : to}</a>
            }
          </Tooltip>
        </AddressWrapper>
      )
    }
  },
  {
    title: 'Quantity',
    key: 'quantity',
    dataIndex: 'valueNumber',
    ellipsis: true,
    width: 100,
    render: (value: number) => <>{formatNumber(value)}</>
  },
]

export const tokenTransferVrc721Columns = [
  {
    title: 'Transaction ID',
    key: 'hash',
    dataIndex: 'transactionHash',
    render: (hash: string) => <AddressLink address={hash} type="tx" />
  },

  {
    title: 'Block',
    key: 'block',
    dataIndex: 'blockNumber',
    width: '120px',
    render: (block: string) => <Link to={`/block/${block}`}>#{block}</Link>
  },
  {
    title: 'Time',
    key: 'timestamp',
    dataIndex: 'timestamp',
    ellipsis: true,
    width: '130px',
    render: (time: number) => <>{timeFormatDistance(time)}</>
  },
  {
    title: 'From',
    key: 'from',
    dataIndex: 'from',
    render: (from: string, record: any) => {
      const query = new URLSearchParams(window.location.search)
      const getAccount = query.get('account')?.trim() || ''
      return (
        <AddressWrapper>
          {record.fromContract && <ContractIconTooltip style={{ marginRight: "5px" }} />}
          <Tooltip title={from}>
            {getAccount.toLowerCase() === from.toLowerCase() ?
              <TextStyled className="txt-ellipsis">{record.fromName ? record.fromName : from}</TextStyled>
              :
              <a className="txt-ellipsis" href={`/token/${record.address}?account=${from}`}>{record.fromName ? record.fromName : from}</a>
            }
          </Tooltip>
        </AddressWrapper>
      )
    }
  },
  {
    title: 'To',
    key: 'to',
    dataIndex: 'to',
    render: (to: string, record: any) => {
      const query = new URLSearchParams(window.location.search)
      const getAccount = query.get('account')?.trim() || ''
      return (
        <AddressWrapper>
          {record.toContract && <ContractIconTooltip style={{ marginRight: "5px" }} />}
          <Tooltip title={to}>
            {getAccount.toLowerCase() === to.toLowerCase() ?
              <TextStyled className="txt-ellipsis">{record.toName ? record.toName : to}</TextStyled>
              :
              <a className="txt-ellipsis" href={`/token/${record.address}?account=${to}`}>{record.toName ? record.toName : to}</a>
            }
          </Tooltip>
        </AddressWrapper>
      )
    }
  },
  {
    title: 'Token ID',
    key: 'tokenId',
    dataIndex: 'tokenId',
    ellipsis: true,
    width: '100px',
    render: (id: string, record: any) =>
      <Link to={`/token/${record.address}?tokenId=${id}`}>{id}</Link>
  },
]

export const masternodeColumns = [
  {
    title: 'Rank',
    dataIndex: 'rank',
    width: 80,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    ellipsis: true,
    render: (address: string) =>
      <Tooltip placement="top" title={address}>
        <Link className="txt-ellipsis" to={`/address/${address}`} >{address.toLowerCase()}</Link>
      </Tooltip>
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: string) => <TagStatusStyled variant={status.toLowerCase() === 'masternode' ? 'in' : 'default'}>{status}</TagStatusStyled>
  },
  {
    title: 'Capacity',
    dataIndex: 'capacity',
    render: (capacity: number) => <>{formatNumber(toTomo(capacity, 18), 2)}</>
  },
  {
    title: 'Owner',
    dataIndex: 'owner',
    ellipsis: true,
    render: (owner: string) =>
      <Tooltip placement="top" title={owner}>
        <Link className="txt-ellipsis" to={`/address/${owner}`} >{owner.toLowerCase()}</Link>
      </Tooltip>
  },
  {
    title: 'Latest signed block',
    dataIndex: 'latestSignedBlock',
    render: (block: number) =>
      <Link to={`/block/${block}`} >{block}</Link>
  },
]

export const slashedColumns = [
  {
    title: 'Address',
    dataIndex: 'address',
    render: (address: string) => <Link to={`/address/${address}`}>{address.toLowerCase()}</Link>
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
]

export const accountColumns = [
  {
    title: 'Rank',
    dataIndex: 'rank',
    ellipsis: true,
    width: '80px',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    width: '250px',
    render: (address: string, record: any) => (
      <AddressWrapper>
        {record.isContract && <ContractIconTooltip style={{ marginRight: "5px" }} />}
        <Tooltip className="d-flex align-items-center" placement="top" title={address}>
          <Paragraph ellipsis className="m-0"><Link className="txt-ellipsis" to={`/address/${address}`} >{truncateBetween(address.toLowerCase(), 12)}</Link></Paragraph>
        </Tooltip>
      </AddressWrapper>
    )
  },
  {
    title: 'Public tag',
    dataIndex: 'accountName',
    render: (accountName: any, record: any) => <>{accountName && <Link to={`/address/${record.address}`}>{accountName}</Link>}</>
  },
  {
    title: 'Balance (VIC)',
    dataIndex: 'balanceNumber',
    render: (balanceNumber: number) => <>{formatNumber(balanceNumber)}</>
  },
  {
    title: 'Percentage',
    dataIndex: 'percentage',
    render: (percentage: any) => <>{percentage && `${formatNumber(percentage)}%`}</>
  },
  {
    title: 'Txn Count',
    dataIndex: 'txCount',
    width: '120px',
    render: (txCount: number) => <>{formatNumber(txCount)}</>
  },
]
// tab Total Balance - Account Detail
export const columnsBalance = [
  {
    title: 'Token',
    key: 'token',
    dataIndex: 'token',
    ellipsis: true,
    render: (token: string, record: any) =>
      <Space><TokenIcon address={!token ? 'tomo' : token} size={20} /><Link to={!token ? '#' : `/token/${token}`}>{record.tokenName}</Link></Space>
  },
  {
    title: 'Symbol',
    key: 'tokenSymbol',
    dataIndex: 'tokenSymbol',
    ellipsis: true,
    render: (tokenSymbol: string) => <>{tokenSymbol}</>
  },
  {
    title: 'Type',
    key: 'tokenType',
    dataIndex: 'tokenType',
    ellipsis: true,
    render: (tokenType: TokenType) => <TokenTypeTag type={tokenType} />
  },
  {
    title: 'Amount',
    key: 'amount',
    dataIndex: 'quantityNumber',
    ellipsis: true,
    render: (amount: number) => <>{formatNumber(amount, 6)}</>
  },
  {
    title: 'Price (USD)',
    key: 'price',
    dataIndex: 'priceUsd',
    ellipsis: true,
  },
  {
    title: 'Percentage (%)',
    key: 'percentage',
    dataIndex: 'percentage',
  },
  {
    title: 'Value (USD)',
    key: 'value',
    dataIndex: 'valueUsd',
    ellipsis: true,
    render: (valueUsd: number) => <>{formatNumber(valueUsd, 6)}</>
  },
];
// Tab Transactions - Account Detail
export const columnsTransactions = [
  {
    title: 'Transaction ID',
    key: 'hash',
    dataIndex: 'hash',
    width: '200px',
    render: (hash: string, record: any) =>
      <Tooltip placement="top" title={hash}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {record.status && record.status === 'fail' &&
            <span style={{ color: "#FD4040", fontSize: "12px" }}><InfoCircleOutlined /></span>
          }
          &nbsp;
          <Link className="txt-ellipsis" to={`/tx/${hash}`} >{hash.toLowerCase()}</Link>
        </div>
      </Tooltip>
  },
  {
    title: 'Method',
    key: 'method',
    dataIndex: 'method',
    ellipsis: true,
    width: '120px',
    render: (method: string) => <div style={{ display: "flex" }}><MethodTag>{method}</MethodTag></div>
  },
  {
    title: 'Block',
    key: 'blockNumber',
    dataIndex: 'blockNumber',
    ellipsis: true,
    width: '110px',
    render: (blockNumber: string) =>
      <Link to={`/block/${blockNumber}`}>#{blockNumber}</Link>
  },
  {
    title: 'Time',
    key: 'timestamp',
    dataIndex: 'timestamp',
    ellipsis: true,
    width: '120px',
    render: (timestamp: number) => <>{timeFormatDistance(timestamp)}</>
  },
  {
    title: 'From',
    key: 'from',
    dataIndex: 'from',
    render: (from: string, record: any) =>
      <AddressWrapper>
        {record.fromContract && <ContractIconTooltip style={{ marginRight: "5px" }} />}
        <AddressLink title={from ? record.fromName : ''} address={from} tooltip />
      </AddressWrapper>
  },
  // {
  //   title: '',
  //   key: 'transactionTag',
  //   dataIndex: 'transactionTag',
  //   width: '100px',
  //   align: 'center',
  //   render: (transactionTag: TagStatusType) => <TagStatusStyled variant={transactionTag || 'default'}>{transactionTag || 'Unknow'}</TagStatusStyled>,
  // },
  {
    title: 'To',
    key: 'to',
    dataIndex: 'to',
    width: '160px',
    render: (to: string, record: any) =>
      <AddressWrapper>
        {record.toContract && <ContractIconTooltip style={{ marginRight: "5px" }} />}
        <AddressLink title={to ? record.toName : ''} address={to} tooltip />
      </AddressWrapper>
  },
  {
    title: 'Value (VIC)',
    key: 'value',
    dataIndex: 'value',
    ellipsis: true,
    width: '110px',
    render: (fee: number) => <>{formatNumber(toTomo(fee, 18))}</>
  },
  {
    title: 'Fee (VIC)',
    key: 'fee',
    dataIndex: 'fee',
    ellipsis: true,
    width: '100px',
    render: (fee: number) => <>{formatNumber(toTomo(fee, 18))}</>
  }
];
// tab Txns - Account Detail
export const columnsTxns = [
  {
    title: 'Transaction ID',
    key: 'hash',
    dataIndex: 'hash',
    ellipsis: true,
    render: (hash: string) =>
      <Link to={`/tx/${hash}`}>{hash}</Link>
  },
  {
    title: 'Block',
    key: 'blockNumber',
    dataIndex: 'blockNumber',
    ellipsis: true,
    render: (blockNumber: string) =>
      <Link to={`/block/${blockNumber}`}>#{blockNumber}</Link>
  },
  {
    title: 'Time',
    key: 'timestamp',
    dataIndex: 'timestamp',
    ellipsis: true,
    render: (timestamp: number) => <>{timeFormatDistance(timestamp)}</>
  },
  {
    title: 'From',
    key: 'from',
    dataIndex: 'from',
    render: (from: string, record: any) =>
      <AddressWrapper>
        {record.fromContract && <ContractIconTooltip style={{ marginRight: "5px" }} />}
          <AddressLink title={record.fromName ? record.fromName : ''} address={from} tooltip />
      </AddressWrapper>
  },
  {
    title: '',
    key: 'arrowIcon',
    dataIndex: 'arrowIcon',
    width: '70px',
    render: (arrowIcon: any) =>
      <ArrowRightOutlined />
  },
  {
    title: 'To',
    key: 'to',
    dataIndex: 'to',
    render: (to: string, record: any) =>
      <AddressWrapper>
        {record.toContract && <ContractIconTooltip style={{ marginRight: "5px" }} />}
          <AddressLink title={record.toName ? record.toName : ''} address={to} tooltip />
      </AddressWrapper>
  },
  {
    title: 'Value (VIC)',
    key: 'value',
    dataIndex: 'value',
    ellipsis: true,
    render: (value: number) => <>{formatNumber(toTomo(value, 18), 2)}</>
  }
]

// tab Reward - Account Detail
export const columnsRewards = [
  {
    title: 'Epoch',
    key: 'epoch',
    dataIndex: 'epoch',
    render: (ep: string) =>
      <Link to={`/epoch/${ep}`}>{ep}</Link>
  },
  {
    title: 'Masternode',
    key: 'validator',
    dataIndex: 'validator',
    ellipsis: true,
    render: (validator: string) =>
      <Link to={`/address/${validator}`}>{validator}</Link>
  },
  {
    title: 'Masternode Name',
    key: 'validatorName',
    dataIndex: 'validatorName',
  },
  {
    title: 'Sign Number',
    key: 'signNumber',
    dataIndex: 'signNumber',
  },
  {
    title: 'Reward (VIC)',
    key: 'reward',
    dataIndex: 'reward',
    render: (value: number) => <>{formatNumber(toTomo(value, 18), 8)}</>
  },
  {
    title: 'Reason',
    key: 'reason',
    dataIndex: 'reason',
  },
  {
    title: 'Time',
    key: 'timestamp',
    dataIndex: 'timestamp',
    render: (timestamp: number) => <>{timeFormatDistance(timestamp)}</>
  },
]

// vrc token txns - Account/Contract detail pages
export const accountVrcTokenTxnsColumns = [
  {
    title: 'Transaction ID',
    key: 'hash',
    dataIndex: 'transactionHash',
    render: (hash: string) => <AddressLink address={hash} type="tx" />,
    width: 150,
  },
  {
    title: 'Time',
    key: 'timestamp',
    dataIndex: 'timestamp',
    ellipsis: true,
    width: 140,
    render: (time: number) => <>{timeFormatDistance(time)}</>
  },
  {
    title: 'From',
    key: 'from',
    dataIndex: 'from',
    width: 150,
    render: (from: string, record: any) =>
      <AddressWrapper>
        {record.fromContract && <ContractIconTooltip style={{ marginRight: "5px" }} />}
          {record.thisAccount === from ? (
            <TextStyled strong={true} italic={true} >This account</TextStyled>
          ) : (
            <AddressLink title={record.fromName} address={from} tooltip />
          )}
      </AddressWrapper>
  },
  {
    title: 'To',
    key: 'to',
    dataIndex: 'to',
    render: (to: string, record: any) => {
      return (
        <AddressWrapper>
          {record.toContract && <ContractIconTooltip style={{ marginRight: '5px' }} />}
          {record.thisAccount === to ? (
            <TextStyled strong={true} italic={true}>This account</TextStyled>
          ) : (
              <AddressLink title={record.toName} address={to} tooltip />

          )}
        </AddressWrapper>
      )
    },
    width: 150,
  },
  {
    title: 'Quantity',
    key: 'quantity',
    dataIndex: 'valueNumber',
    ellipsis: true,
    width: 100,
    render: (value: number) => <>{formatNumber(value)}</>
  },
  {
    title: 'Token',
    key: 'token',
    dataIndex: 'tokenSymbol',
    width: 150,
    render: (symbol: string, record: any) => {
      return (
        <div className="d-flex align-items-center">
          <TokenIcon address={record.address} />
          <Link to={`/token/${record.address}`}>
            {record.tokenSymbol && <>{record.tokenName} ({record.tokenSymbol})</>}
            {!record.tokenSymbol && <>{record.address}</>}
          </Link>
        </div>
      )
    }
  },
]

export const contractColumns = [
  {
    title: '#',
    key: 'rank',
    dataIndex: 'rank',
    ellipsis: true,
    width: '70px',
  },
  {
    title: 'Address',
    key: 'address',
    dataIndex: 'address',
    render: (address: string) =>
      <Tooltip placement="top" title={address}>
        <Link className="txt-ellipsis" to={`/address/${address}`} >{truncateBetween(address.toLowerCase())}</Link>
      </Tooltip>
  },
  {
    title: 'Contract Name',
    key: 'contractName',
    dataIndex: 'contractName',
    render: (name: string, record: any) => <>{!!name && <AddressLink address={record.address} type="address" title={name} />}</>
  },
  {
    title: 'Public tag',
    key: 'accountName',
    dataIndex: 'accountName',
    render: (name: string, record: any) => <>{!!name && <AddressLink address={record.address} type="address" title={name} />}</>
  },
  {
    title: 'Balance (VIC)',
    key: 'balance',
    dataIndex: 'balanceNumber',
    render: (balance: number) => <>{formatNumber(balance)}</>
  },
  {
    title: 'Txn Count',
    key: 'txCount',
    dataIndex: 'txCount',
    render: (txCount: number) => <>{formatNumber(txCount)}</>
  },
  {
    title: 'Date Verified',
    key: 'timestamp',
    dataIndex: 'timestamp',
    ellipsis: true,
    render: (timestamp: number) => <>{timeFormatDistance(timestamp)}</>
  },
  {
    title: 'Compiler',
    key: 'compiler',
    dataIndex: 'compiler',
    ellipsis: true,
    render: (compiler: string) =>
      <Tooltip placement="top" title={compiler}>{compiler}</Tooltip>
  },
]