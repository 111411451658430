type OneIdIconType = {
  size?: number | undefined;
  src?: string;
}

function DomainNameIcon({ size, src }: OneIdIconType) {
  return (
    <img
      src={src || `/oneid.svg`}
      width={size || '20px'}
      height={size || '20px'}
      style={{marginRight: '5px'}}
      alt=""
    />
  )
}
DomainNameIcon.OneID = ({...props}) => <DomainNameIcon {...props} src='/oneid.svg'/>
DomainNameIcon.D3 = ({...props}) => <DomainNameIcon {...props} src='/D3-logo.jpg'/>
export default DomainNameIcon