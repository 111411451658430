import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { persistStore, persistReducer, PERSIST } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import generalReducer from '../features/general/generalSlice'
import transactionsReducer from '../features/transactions/transactionsSlice'
import blocksReducer from '../features/blocks/blocksSlice'
import epochsReducer from '../features/epochs/epochsSlice'
import tokensReducer from '../features/tokens/tokensSlice'
import masternodesReducer from '../features/masternodes/masternodesSlice'
import accountsReducer from '../features/accounts/accountsSlice'
import contractsReducer from '../features/contracts/contractsSlice'

export const history = createBrowserHistory()

const reducer = combineReducers({
  router: connectRouter(history),
  general: generalReducer,
  transactions: transactionsReducer,
  blocks: blocksReducer,
  epochs: epochsReducer,
  tokens: tokensReducer,
  masternodes: masternodesReducer,
  accounts: accountsReducer,
  contracts: contractsReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [PERSIST],
    }
  }).concat(routerMiddleware(history)),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
