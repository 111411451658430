import styled from 'styled-components'
import { Typography } from 'antd'
const { Paragraph, Title } = Typography

export const Wrapper = styled.div`
    text-align: center;
`
export const CenterStyle = styled.div`
    padding-top: 20%;
`
export const TitleStyle = styled(Title)`
    color: ${({theme})=>theme.text}!important;
`
export const ParagraphStyle = styled(Paragraph)`
    color: ${({theme})=>theme.text};
`