import BigNumber from 'bignumber.js'
import { useCallback } from 'react'

import { useAppSelector } from '../../app/hooks'
import { Datatable, DataTableTotal, tokenHolderColumns } from '../../common'
import { TokenDetailsType } from '../../constants/types'
import { getVrc2021HolderList, getVrc721HolderList } from './tokensSlice'
import { fungibleTokenStandard, nonFungibleTokenStandard } from '../../constants'

interface TokenDetailsHolderListProps {
  token: TokenDetailsType
  price: number
  totalSupply: number
}

// return get holder list function depend token's type
function getHolderListFunction(type: string) {
  if (nonFungibleTokenStandard.includes(type)) {
    return getVrc721HolderList
  }

  return getVrc2021HolderList
}

export default function TokenDetailsHolderList({ token, price, totalSupply }: TokenDetailsHolderListProps) {
  const { holders } = useAppSelector((state) => state.tokens.details)

  const getData = useCallback(
    (params: any) => getHolderListFunction(token.type)({ ...params, address: token.address }),
    []
  )

  const stateType = fungibleTokenStandard.includes(token.type) ? 'vrc2021' : 'vrc721'
  const { data, total, loading } = holders[stateType]
  const newData = data.map((v) => {
    const percentage = v.quantityNumber ? new BigNumber(v.quantityNumber).div(totalSupply).multipliedBy(100) : ''
    const valueUsd = v.quantityNumber && price ? new BigNumber(v.quantityNumber).multipliedBy(price).toNumber() : ''
    return { ...v, percentage, valueUsd }
  })
  return (
    <Datatable
      loading={loading}
      total={total}
      columns={tokenHolderColumns}
      dataSource={newData}
      header={<DataTableTotal total={total} type="token-holder" />}
      getData={getData}
    />
  )
}