import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { MasternodesState } from '../../constants/types'
import { fetchMasternodeList } from '../../api'
import { requestLimit } from '../../features/general/generalSlice'

// Create thunk
export const getMasternodeList = createAsyncThunk(
  'masternodes/getMasternodeList',
  async (params: {offset: number, limit: number}, thunkAPI) => {
    try {
      const { offset, limit } = params

      const response = await fetchMasternodeList(offset, limit)
      thunkAPI.dispatch(requestLimit(false))
      return response.data
    } catch (error: any) {
      if (error && error.response && error.response.status === 429) {
        thunkAPI.dispatch(requestLimit(true))
      }
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// Declare slice
const initialState = {
  list: { 
    data: [],
    loading: 'idle',
    total: null,
  },
} as MasternodesState

const masternodesSlice = createSlice({
  name: 'masternodes',
  initialState,
  reducers: {
    // reset masternode list
    resetMasternodeList(state) {
      state.list.data = []
      state.list.loading = 'idle'
      state.list.total = null
    }
  },
  extraReducers: (builder) => {
    // masternode list
    builder.addCase(getMasternodeList.pending, (state) => {
      state.list.loading = 'pending'
    })

    builder.addCase(getMasternodeList.fulfilled, (state, action) => {
      const data = action.payload.candidates.map((item: any, index: number) => {
        item.rank = index + 1
        return item
      })
      
      state.list.loading = 'succeeded'
      state.list.data = data
      state.list.total = data.length
    })

    builder.addCase(getMasternodeList.rejected, (state) => {
      state.list.loading = 'failed'
      state.list.data = []
      state.list.total = null
    })
  },
})

export const { resetMasternodeList } = masternodesSlice.actions
export default masternodesSlice.reducer
