import styled from 'styled-components'
import { Typography } from 'antd'

export const PaginationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  color: ${({ theme }) => theme.text};

  // @media(max-width: 767px){
  //   justify-content: center;
  // }

  .ant-typography {
    color: ${({ theme }) => theme.text};
    &.ant-typography-secondary {
      color: ${({ theme }) => theme.textOverlay};
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .d-ms-down-none {
    @media(max-width: 767px){
      display: none;
    }
  }
`

export const ContentTable = styled.div`
  background: ${({ theme }) => theme.bg2};
  padding: 24px;
  @media(max-width: 767px){
    padding: 15px;
  }
  .style-table-bordered {
    padding: 24px 0;
    .ant-table {
      background: ${({ theme }) => theme.bg2};
      table {
        border-radius: 0!important;
        border-bottom: 1px solid ${({ theme }) => theme.border};
       
        color: ${({ theme }) => theme.text};
        overflow: hidden;
        
        .ant-table-expanded-row-fixed {
          background: ${({ theme }) => theme.bg1};
        }
        .ant-table-tbody > tr > td {
          border-bottom: 1px solid ${({ theme }) => theme.border1};
        }
        .ant-table-thead > tr > th {
          border-bottom: 1px solid ${({ theme }) => theme.border1};
          color: ${({ theme }) => theme.text};
          background: ${({ theme }) => theme.bg1};
        }
        .ant-table-tbody > tr.ant-table-row:hover > td {
          background: ${({ theme }) => theme.trHover}!important;
        }
      }
    }
  }
  .ant-empty-description{
    color: ${({ theme }) => theme.textOverlay};
  }
`

export const TextSmall = styled(Typography.Text)`
  font-size: 12px;
`
export const TextLink = styled.span`
  cursor: pointer;
  font-size: 14px;
  gap: 5px;
  align-items: center;
  &:hover {
    color: #40a9ff;
  }
  .anticon-clock-circle{
    font-size: 12px;
  }
`