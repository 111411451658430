import { createContext, useCallback, useContext, useMemo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { HelmetProvider } from 'react-helmet-async'

import { fetchTomoTokenList } from '../api'
import { RootState } from '../app/store'
import Colors from '../constants/themeConfig'

interface GeneralProviderProps {
  children: any
}

const GeneralContext = createContext({
  mode: 'light',
  tomoTokenList: [],
  requestLimit: false
})

export function useGeneralContext() {
  const general = useContext(GeneralContext)

  return { ...general }
}

export default function GeneralProvider({ children }: GeneralProviderProps) {
  const mode = useSelector((state: RootState) => state.general.mode)
  const requestLimit = useSelector((state: RootState) => state.general.requestLimit)
  const theme = useMemo(() => Colors(mode), [mode])
  const [tomoTokenList, setTomoTokenList] = useState([])

  const getTomoTokenList = useCallback(async () => {
    try {
      const response = await fetchTomoTokenList()
      
      setTomoTokenList(response.data.data)
    } catch (error) {
      console.log(error)
      setTomoTokenList([])
    }
  }, [])

  useEffect(() => {
    getTomoTokenList()
  }, [])

  return (
    <GeneralContext.Provider value={{ mode, tomoTokenList,requestLimit }}>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          {children}
        </HelmetProvider>
      </ThemeProvider>
    </GeneralContext.Provider>
  )
}